<template>
    <div>
        <div class="tabAnalize-Listen d-flex">
            <v-card>
                <v-tabs vertical>
                    <div>
                        <div class="containerTitleTab">
                            <p class="titleTab mb-0">Contenido</p>
                        </div>
                        <v-tab class="d-flex justifyFlexStart" >
                            <div class="titleTab titleTabNum titleTabNum2">1</div>
                            <div class="titleTab">Perfil de Audiencia: Buyer Persona</div>
                        </v-tab>
                        <v-tab class="d-flex justifyFlexStart">
                            <div class="titleTab titleTabNum">2</div>
                            <div class="titleTab">Contexto</div>
                        </v-tab>
                        <v-tab class="d-flex justifyFlexStart">
                            <div class="titleTab titleTabNum">3</div>
                            <div class="titleTab">Drivers</div>
                        </v-tab>
                        <v-tab class="d-flex justifyFlexStart">
                            <div class="titleTab titleTabNum">4</div>
                            <div class="titleTab">Influencers</div>
                        </v-tab>
                        <v-tab class="d-flex justifyFlexStart">
                            <div class="titleTab titleTabNum">5</div>
                            <div class="titleTab">Trending Topics</div>
                        </v-tab>
                        <v-tab class="d-flex justifyFlexStart">
                            <div class="titleTab titleTabNum titleTabNum2">6</div>
                            <div class="titleTab">CSA (Semántica + Influencers</div>
                        </v-tab>
                        <v-tab class="d-flex justifyFlexStart">
                            <div class="titleTab titleTabNum">7</div>
                            <div class="titleTab">Observatorio del País</div>
                        </v-tab>
                    </div>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">1.	Buyer Persona - Definición del Perfil de tu Audiencia:</p>
                                <p class="paragraph-listen">Es una representación semi-ficticia de nuestro <strong>consumidor final (o potencial)</strong> construida a partir de su información demográfica, comportamiento, necesidades y motivaciones. Se trata de ponernos en los zapatos de nuestro público objetivo para entender cuáles son sus necesidades o motivaciones. En esta versión de ISC podemos observar las siguientes características  en la audiencia:</p>
                                <ul class="paragraph-listen">
                                    <li><strong>Género:</strong> Masculino, Femenino o Sin definir.</li>
                                    <li><strong>Sentimiento:</strong> Positivo, Negativo o Neutral. Cuando sale 'neutral' significa que no hay inclinación de sentimiento en el diagnóstico.</li>
                                    <li><strong>Emociones:</strong> Se segmentan las emociones en feliz, neutral, molesto, asustado y triste.</li>
                                </ul>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urls3Advise}/listen_overview_resume.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">2. Contexto</p>
                                <p class="paragraph-listen">Para generar un Tablero (Con todos sus indicadores de diagnóstico) se necesita <strong>configurar un 'Contexto'.</strong> El Contexto está compuesto por 'Drivers' que se escogen estratégicamente con el fin de recolectar información acerca de una industria o sector.</p>
                                <p class="paragraph-listen"><strong>Los DRIVERS</strong> responden a preguntas de negocio que se desean resolver escuchando las redes sociales (Hoy Twitter y Facebook).</p>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urls3Advise}/settings_update_create_context.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">3. Drivers</p>
                                <p class="paragraph-listen">Son un <strong>conjunto de 'palabras clave'</strong> que agrupadas orientan la escucha hacia un 'Contexto' específico.</p>
                                <p class="paragraph-listen">Los Drivers pueden ser: Palabras, conjunto de palabras, hashtags, mentions (cuentas de twitter) o páginas de Facebook empresariales (públicas).</p>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urls3Advise}/settings_update_create_context.webp`"
                                />
                            </div>
                            <v-card-text>
                                <p class="paragraph-listen">Hay 2 tipos de Drivers:</p>
                                <p class="paragraph-listen"><strong>Stand Alone Drivers:</strong> Son los Drivers que Tu escoges escuchar dentro de un Contexto, los configuras en la plantilla para crear Contextos. Tu plan te permite una cantidad especifica de ellos.</p>
                                <p class="paragraph-listen"><strong>ISC Boards Drivers:</strong>  En el submodulo ‘Dashboards/ ISC Boards’ de LISTEN se escuchan diferentes Industrias del pais seleccionado. Cada una de estas Industrias tiene un grupo de Drivers que generan gran parte de las interacciones dentro de ellas. Estos Drivers son de escucha permanente y generan data historica relevante que puedes usar para:</p>
                                <ul class="paragraph-listen">
                                    <li>
                                        Complementar tus Contextos: Puedes seleccionar Drivers individuales de ISC Boards y usarlos en tu Contexto.
                                    </li>
                                    <li>
                                        Analizar tendencias de la Industria: El conjunto de Drivers genera un diagnostico, puedes activarlos y/o desactivarlos a voluntad para ver la incidencia de los mismos en el diagnostico general de la industria.
                                    </li>
                                </ul>

 
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">4. Influencers (Influenciadores)</p>
                                <p class="paragraph-listen">
                                    El 'Influencer' se define como una persona que cuenta con <strong>cierta credibilidad sobre un tema concreto,</strong> y por su <strong>presencia e influencia en redes sociales</strong> puede llegar a convertirse en un prescriptor interesante para una empresa. ISC 'filtra' influencers por contexto usando diversos criterios, el principal es la cantidad de 'Interacciones' que el influencer genera bajo el contexto solicitado. 
                                </p>
                                <p class="paragraph-listen">
                                    Ej.: La importancia de un influencer se mide por el <strong>alcance que su mensaje puede tener,</strong> para ello debe tener muchos seguidores. Sin embargo,en un contexto específico, puede que un influencer con 1M de followers genere muy poca interacción en el contexto que se escucha restándole importancia a su utilidad.
                                </p>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urls3Advise}/listen_clustering_influencers.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">5. Trending Topics (Temas de actualidad) </p>
                                <p class="paragraph-listen">                            
                                    Son los hashtags (#) más utilizados en un plazo de tiempo concreto en Twitter.
                                </p>    
                                <p class="paragraph-listen">                                
                                    Se trata de hashtags de moda, aquello que es tendencia y de lo que más se habla en ese momento en esta red social. 
                                </p>
                                <p class="paragraph-listen">                              
                                    Cuando definimos un Contexto en ISC el resultado es todos los Trending Topics asociados al Contexto.
                                </p>  
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urls3Advise}/listen_hashtag_timeline.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">6. CSA (Cluster Social Analytics) - Definición</p>
                                <p class="paragraph-listen">
                                    CSA significa <strong>'Cluster Social Analytics'.</strong> Muestra 'clusters' (Conjunto de nodos relacionados entre sí a través de conexiones) armados a partir del Contexto que el Usuario defina escuchar. Hay 2 tipos de CSA en ISC:
                                </p>
                                <p class="paragraph-listen">
                                    <strong>CSA Semántico:</strong> Identifica relaciones entre las palabras generadas a partir del Contexto y las agrupa en grupos semánticos (Clusters). Nos sirve para interpretar ‘que dice' nuestra audiencia alrededor del Contexto y como se 'siente' nuestra audiencia en relación a las palabras que usa dentro del Contexto.
                                </p>
                                <p class="paragraph-listen">
                                    <strong>CSA de Influencers:</strong> Identifica relaciones entre los influencers generados a partir del Contexto y los agrupa en grupos de influencia/ comunicación (Clusters). Nos sirve para identificar 'quienes' son los influencers dentro del Contexto, ‘que hablan' en relación al Contexto, como se 'direccionan' las comunicaciones dentro de los clusters generados, que 'sentimiento' generan con las comunicaciones de los influencers, entre otras cosas (Interpretación del contenido generado, relaciones inter-usuarios, otros).
                                </p>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urls3Advise}/listen_clustering_analysis.webp`"
                                />
                            </div>
                            <v-card-text>
                                <ol class="paragraph-listen">
                                    <li>
                                        <p><strong>CSA (Cluster Social Analytics) - Composición</strong></p>
                                        <p>Un CSA está compuesto por:</p>
                                        <div class="container-center">
                                            <dt class="container-data">
                                                <dd>
                                                    <strong>Nodo Central:</strong> Es el centro del Cluster. Se les identifica porque son más grandes que los nodos relacionados y están marcados en negrita.
                                                    <ul>
                                                        <li type="disc">Para CSA Semánticos el Nodo Central es el Driver del Contexto.</li>
                                                        <li type="disc">Para CSA Influencers el Nodo Central es uno de los 10 Twitteros que generó mas interacciones en el Contexto.</li>
                                                    </ul>
                                                    <strong>Nodo Relacionado:</strong> Esta en la periferia del Cluster. Se 'conecta' al Nodo Central debido a que tiene una relación lógica con el mismo.
                                                    <ul>
                                                        <li type="disc">Para CSA Semántico los Nodos Relacionados son las palabras que más utilizan con el Driver asociado (Del Nodo Central).</li>
                                                        <li type="disc">Para CSA Influencers los Nodos Relacionados son uno de los 10 Twitteros más mencionados (@mentions) por el @usuario del Nodo Central).</li>
                                                    </ul>
                                                    <p><strong>Enlace:</strong> Es la conexión entre un Nodo Central y un Nodo Relacionado. El espesor del enlace indica mayor cantidad de interacciones entre nodos. (En el caso semántico es cuantas veces se asocia la palabra con el nodo central). Su color representa el sentimiento generado por la relación entre los Nodos dentro del Contexto.</p>
                                                    <p><strong>Muestras:</strong> Son el resultado de lo que arroja el Contexto. Pueden ser: Tweets (De Twitter) o Comentarios (De Facebook).</p>
                                                    <p><strong>@Usuario:</strong> Es el Twittero que 'escribe' un Tweet.</p>
                                                    <p><strong>@Mention:</strong> Es el Twittero que ha sido 'mencionado' en un Tweet.</p>
                                                
                                                </dd>
                                            </dt>
                                        </div>
                                    </li>
                                    <li>
                                        <p><strong>CSA (Cluster Social Analytics) - Lógica y Generación CSA Semántico</strong></p>
                                        <p>Cada tipo de CSA tiene una lógica para su generación o interpretación:</p>
                                        <div class="container-center">
                                            <dt class="container-data">
                                                <dd>
                                                    <p><strong>Lógica del CSA Semántico:</strong> Muestra la forma en que se relacionan las palabras generadas a partir del Contexto escogido. </p>
                                                    <p><strong>Forma 'Clusters' de grupos semánticos organizados de la siguiente manera:</strong></p>
                                                    <ul>
                                                        <li type="disc">Filtra las muestras (De la(s) Red(es) social(es) que se escucha) generadas por los Drivers del Contexto.</li>
                                                        <li type="disc">Los Drivers se convertirán en los Nodos Centrales. (Máximo 10 Drivers).</li>
                                                        <li type="disc">Hace un ranking de palabras usadas alrededor del Nodo Central (Driver) y filtra los 10 más utilizadas alrededor del mismo. Éstas palabras se convierten en Nodos Relacionadas y se conectan con los Nodos Centrales (y entre sí) a través de Enlaces.</li>
                                                        <li type="disc">Se puede armar un árbol de máximo 100 nodos (Habrá casos en que serán menos porque no necesariamente se llega al máximo número de palabras citado en la lógica).</li>
                                                        <li type="disc">El color del Nodo representa el sentimiento que genera esa palabra dentro del Contexto (Verde = Positivo, Rojo = Negativo, Gris = Neutral).</li>
                                                        <li type="disc">En el centro de cada Nodo Central esta el ícono de la Red Social que genera la data. Su tamaño dependerá de la cantidad e interacciones que genera el Driver.</li>
                                                    </ul>
                                                </dd>
                                            </dt>
                                        </div>
                                        
                                    </li>
                                    <li>
                                        <p><strong>CSA (Cluster Social Analytics) - Lógica y Generación CSA de Influencers</strong></p>
                                        <p>Cada tipo de CSA tiene una lógica para su generación o interpretación:</p>
                                        <ul>
                                            <li type="disc">Esta disponible solo para las muestras de Twitter.</li>
                                            <li type="disc">Filtra las muestras generadas por el Contexto.</li>
                                            <li type="disc">Hace un ranking de @usuarios considerando la cantidad de interacciones generadas. (Interacciones = Retweet +Likes).</li>
                                            <li type="disc">Los 10 primeros @usuarios del ranking se convierten en los 'Nodos Centrales'.</li>
                                            <li type="disc">Los Nodos Centrales son mas grandes que los Nodos Relacionados. Además, el nombre del @usuario aparece en negritas y con letras de color blanco. En el caso de los Nodos Relacionados el nombre del Twittero mencionado por el @usuario aparece en color celeste.</li>
                                            <li type="disc">Por cada <strong>'Nodo Central'</strong> se hace una lista de todos los twitteros a los que menciona en sus Tweets (@mentions). De esa lista se hace un ranking por 'ínteracciones' y se muestran los 10 Top Twitteros (Interacciones = Retweet + Likes).</li>
                                            <li type="disc">
                                                Estos 10 Top twitteros 'mencionados' del ranking se convierten en los 'Nodos Relacionados' y se conectan con los Nodos Centrales (y entre sí) a través de Enlaces. La conexión se interpreta de la siguiente manera:
                                                <div class="container-right">
                                                    <div class="container-data2">
                                                        <ul>
                                                            <li type="disc">El enlace se conecta a 2 Nodos.</li>
                                                            <li type="disc">La dirección del enlace (Flechita) indica 'que' nodo mencionó a 'quien': El origen de la flecha es el Nodo que 'menciona' al otro.</li>
                                                            <li type="disc">El color del Enlace representa el sentimiento generado por la comunicacíón entre los Nodos dentro del Contexto. ((Verde=Positivo, Rojo=Negativo, Gris=Neutral). Es decir: Si el que 'menciona' lo hace de manera negativa, positiva o neutral.</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                            <li type="disc">Se puede armar un árbol de máximo 100 nodos (Habrá casos en que serán menos porque no necesariamente se llega al máximo número de @ususarios o @mentions citado en la lógica).</li>
                                            <li type="disc">
                                                Al dar click en un nodo se muestra la siguiente información:
                                                <div class="container-right">
                                                    <div class="container-data2">
                                                        <ul>
                                                            <li type="disc"><strong>Nodo Central:</strong> Muestra el Tweet del @usuario que genero mayor interacción.</li>
                                                            <li type="disc"><strong>Nodo Relacionado:</strong> Muestra 3 Tweets en donde el @ususario del Nodo Central lo menciona (@mentions). Estos se filtran teniendo en cuenta la cantidad de interacciones de las personas 'mencionadas' (@mentions).</li>
                                                            <li type="disc">Si al hacer click en el Nodo Relacionado no se muestra ninguna mención del Nodo Central, el vínculo es un Retweet (que hace el Nodo Central del Nodo Relacionado).</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urls3Advise}/listen_clustering_influencers.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">7. Observatorio del País</p>
                                <p class="paragraph-listen"></p>
                                <p class="paragraph-listen">
                                    Nuestros clientes pueden escuchar cualquier Driver, ademas de ello <strong>LISTEN</strong> escucha permanentemente diversas industrias agrupadas en un 'Observatorio' que escucha permanentemente más de 2,000 Drivers por cada país (Perú, Chile y próximamente Colombia). Esta escucha se agrupa en 18 Categorías. 
                                </p>
                                <p class="paragraph-listen">
                                    El objetivo de este 'Observatorio' es complementar la escucha de los Drivers individuales del cliente (Stand Alone) con uno (o varios) Contextos de Industria o Drivers dentro de ellos para crear una escucha más asertiva. 
                                </p>
                                <p class="paragraph-listen">
                                    <strong>Divide el diagnóstico en 2 submódulos:</strong>
                                </p>
                                <ul class="paragraph-listen">
                                    <li>
                                        <strong>Geo-escucha:</strong> Como lo dice su nombre 'Geo-referencia' los Tweets. Para ello el Tweet debe tener activado el campo de Geo-referenciación (No son muchos los que lo tienen activado). Geo-listening georeferencia en el mapa los siguientes tipos de Driver relacionados a la Categoría seleccionada:
                                        <ul>
                                            <li type="disc">
                                                Palabra Hashtag Twittero (@Mention).Califica el sentimiento de cada Driver en la barra izquierda del Driver (Verde = Positivo, Rojo = Negativo, Gris = Neutral).
                                            </li>
                                            <li type="disc">
                                                Presenta los 5 Tweets que generan más interacciones por cada Driver.
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <strong>ISC Boards:</strong> Para generar un Tablero (Con todos sus indicadores de diagnóstico) se necesita configurar un 'Contexto' que se describe en 'Drivers'. Estos Tableros (Y el diagnóstico consecuente) se generar a partir de los 4,000 Drivers que escucha el Observatorio y se divide en las 18 Categorías.
                                    </li>
                                </ul>



                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urls3Advise}/listen_geolistening.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            model: 'tab-3',
            urlS3:"https://isc-store.s3.amazonaws.com/advise/Listen",
            urls3Advise: "https://image-store.isocialcube.com/advise"
        }
    }
}
</script>
<style>
.justifyFlexStart{
    justify-content: flex-start;
}

.mb-0{
    margin-bottom: 0rem !important;
}

.container-imgGraphic{
    display: flex;
    justify-content: center;
    padding: 2rem 0rem;
}
.tabAnalize-Listen .imgGraphic{
    width: 100%;
}

.container-data{
    width: 95%;
}

.container-data2{
    width: 90%;
}

.container-center{
    display: flex;
    justify-content: center;
}

.container-right{
    display: flex;
    justify-content: right;
}

.paragraphTitle-listen{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    color: #00A5FF;
}
.paragraph-listen{
    font-family: 'Oxygen';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    color: #5D6B88;
}

.tabAnalize-Listen .v-sheet{
    box-shadow: none !important;
}

.tabAnalize-Listen .v-window-item .v-card{
    padding: 2rem 5rem;
}

.tabAnalize-Listen .v-slide-group__content .v-tab--active{
    background: #EBF8FF;
}

.tabAnalize-Listen .v-tabs-slider-wrapper{
    color: transparent !important;
    width: 0rem;
}

.tabAnalize-Listen .v-card .v-tabs {
    width: 100% !important;
}

.tabsAnalize .v-card__text{
    padding: 0% !important;
}

/**
.tabAnalize-Listen .theme--light.v-tabs>.v-tabs-bar{
    width: 15% !important;
}
*/

.tabAnalize-Listen .titleTab{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #2C3852;
    text-transform: none;
    text-align: start;
    letter-spacing: normal;
}

.tabAnalize-Listen .v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text{
    width: 14rem;
}

.tabAnalize-Listen .v-tabs--vertical>.v-tabs-bar .v-tabs-bar__content {
    white-space: unset !important;
    flex: auto !important;
    box-shadow: 3px 4px 5px rgb(0 0 0 / 10%);
    margin-right: 1rem;
}

.tabAnalize-Listen .titleTabNum2{
    height: 65%
}

.tabAnalize-Listen .titleTabNum {
    padding-right: 1rem;
}

.containerTitleTab{
    padding: 0 16px;
    height: 48px;
    margin-top: 2rem;
}

.tabAnalize-Listen .v-tab{
    padding: 0rem 3rem 0rem 16px;
}
/* 
@media (max-width: 1180px) {
    .tabAnalize-Listen .v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text{
        width: 25%;
    }
    .tabAnalize-Listen .v-window-item .v-card{
        padding: 2rem 3rem;
    }
}

@media (max-width: 1030px) {
    .tabAnalize-Listen .v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text{
        width: 30%;
    } 
}*/

</style> 