<template>
  <div class="w-100 white positionRelative ca">
    <div class="d-flex positionAbsolute fDirectionColumn" style="right: 2%">
      <div class="indicativeText2">
        <p class="mb-0"><strong>ACCIONES PREVIAS</strong></p>
        <p class="mb-0">MONITOR + LISTEN</p>
      </div>
      <div class="d-flex fDirectionReverse">
        <img :src="`${urlS3}/Flecha-1.webp`" style="width: 4rem" />
      </div>
    </div>
    <div
      class="d-flex positionAbsolute fDirectionColumn inf2"
      style="right: 2%"
    >
      <div class="indicativeText2">
        <p class="mb-0"><strong>SUPERVISIÓN Y RETROALIMENTACIÓN</strong></p>
        <p class="mb-0">LISTEN + MONITOR</p>
      </div>
      <div class="d-flex justifyCenter inf3">
        <img :src="`${urlS3}/Flecha-3.webp`" style="width: 4rem" />
      </div>
    </div>
    <div class="d-flex positionAbsolute fDirectionColumn inf3">
      <div class="d-flex justifyCenter">
        <img :src="`${urlS3}/Flecha-2.webp`" style="width: 4rem" />
      </div>
      <div class="indicativeText2">
        <p class="mb-0"><strong>IMPLEMENTACIÓN</strong></p>
        <p class="mb-0">ESTRATEGIA + DESIGN</p>
      </div>
    </div>
    <img class="positionAbsolute w-10" :src="`${urlS3}/VectorDPM.webp`" />
    <img class="positionAbsolute imgGirl" :src="`${urlS3}/Chica.webp`" />
    <img class="positionAbsolute imgClock" :src="`${urlS3}/Reloj.webp`" />
    <img
      class="positionAbsolute zIndex2 imgGraphicCyan"
      :src="`${urlS3}/grafico-cyan.webp`"
    />
    <img
      class="positionAbsolute zIndex2 imgGraphicYellow"
      :src="`${urlS3}/grafico-amarillo.webp`"
    />
    <img class="positionAbsolute zIndex2 imgChat" :src="`${urlS3}/chat.webp`" />
    <img
      class="positionAbsolute zIndex2 imgVideoRating"
      :src="`${urlS3}/Video-rating.webp`"
    />
    <img
      class="positionAbsolute zIndex2 imgPhoto"
      :src="`${urlS3}/foto-vistas.webp`"
    />
    <img
      class="positionAbsolute zIndex3 imgPlayCell"
      :src="`${urlS3}/celu.webp`"
    />
    <img
      class="positionAbsolute zIndex3 imgIPad"
      :src="`${urlS3}/ipad+listen.webp`"
    />
    <img
      class="positionAbsolute zIndex3 imgImages"
      :src="`${urlS3}/imagenes.webp`"
    />
    <div>
      <div class="w-100 h-100"></div>
      <div class="d-flex positionAbsolute w-100" style="height: 23%">
        <div class="w-20 container-title-CA">
          <p class="p-title-dpm p-titleMap mb-0 c-letter" style="padding: 3rem">
            DISEÑO DE PIEZAS DE MARKETING
          </p>
        </div>
        <div
          class="w-25 positionRelative containerText"
          style="padding-top: 2rem; padding-right: 1rem"
        >
          <a
            class="w-100 textDecorationNone c-title-sub"
            @click="stepsClick(0)"
          >
            <p class="p-subMap1 mb-0"><strong>ANTES</strong></p>
            <p class="p-subMap2 mb-0">
              <strong>DE EMPEZAR...</strong>
            </p>
            <p class="p-subMap2 mb-0 lh-16">
              Es necesario definir los siguientes puntos antes de realizar un
              análisis y plantear una estrategia.
            </p>
          </a>
        </div>
      </div>
      <div class="containerMap" style="padding: 2.5rem 3rem !important">
        <div class="d-flex w-100">
          <!-- COL 1 -->
          <v-col class="colSides p-0">
            <v-row class="row1">
              <div class="rectangle"></div>
              <div class="space"></div>
              <div class="rectangleGreatLeftTotal">
                <div class="h-100">
                  <a
                    class="w-100 textDecorationNone c-letter"
                    @click="stepsClick(5)"
                  >
                    <div
                      :class="step5Color"
                      class="h-50 rectangleGreatLeftTopMedium positionRelative"
                      v-on:mouseleave="step5 = true"
                      v-on:mouseover="step5 = false"
                    >
                      <div
                        class="rectangleLittleLeftTopMedium white positionAbsolute"
                      ></div>
                      <div class="h-100 w-70 p-sides1">
                        <div
                          class="h-30 w-100 d-flex alignItemsFEnd justifyCenter"
                        >
                          <div class="numberCustom BgBlue c-white">5</div>
                        </div>
                        <div class="h-70 ptp-1">
                          <div v-show="step5" class="textCenter">
                            <p class="p-title lh-18 mb-0">
                              <strong>DEFINIR EL CONTENIDO</strong>
                            </p>
                            <p class="p-caption lh-14">
                              DE LAS PUBLICAC. PARA EJECUTAR LA ESTRATEGIA
                            </p>
                            <div class="d-flex justifyCenter">
                              <img
                                class="imgLikePhone"
                                :src="`${urlS3}/Like-telefono.webp`"
                              />
                            </div>
                          </div>
                          <div v-show="!step5">
                            <ul class="p-medium p-medium-step5-DOMP">
                              <li class="lh-14">Conexiones directas.</li>
                              <li class="lh-14">
                                Plan de Piezas de Marketing.
                              </li>
                              <li class="lh-14">
                                Las publicaciones deben estar compuestas por:
                                Temas y Formatos.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a class="w-100 textDecorationNone" @click="stepsClick(6)">
                    <div
                      :class="step6Color"
                      class="h-50 rectangleGreatLeftBottomMedium positionRelative"
                      v-on:mouseleave="step6 = true"
                      v-on:mouseover="step6 = false"
                    >
                      <img
                        class="positionAbsolute imgSearchUser"
                        :src="`${urlS3}/Buscar-user.webp`"
                      />
                      <div
                        class="rectangleLittleLeftBottomMedium white positionAbsolute"
                      ></div>
                      <div class="h-100 w-70 p-sides1">
                        <div
                          class="h-30 w-100 d-flex alignItemsFEnd justifyCenter"
                        >
                          <div class="numberCustom BgLightBlue c-letter">6</div>
                        </div>
                        <div class="h-70 ptp-1" :class="colorParagraph">
                          <div v-show="step6">
                            <p class="p-title lh-18 mb-0 textCenter">
                              <strong
                                >¿CÓMO ELABORAR EL CONTENIDO CORRECTO?</strong
                              >
                            </p>
                          </div>
                          <div v-show="!step6">
                            <div
                              class="d-flex alignItemsFEnd justifyCenter p-sides07"
                            >
                              <ul class="p-medium">
                                <li class="lh-14">Imagen.</li>
                                <li class="lh-14">Fuente.</li>
                                <li class="lh-14">Colores.</li>
                                <li class="lh-14">Estructura de contenido.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </v-row>
          </v-col>
          <!-- COL 2 -->
          <v-col class="w-70 p-0">
            <v-row>
              <div class="w-100 d-flex fDirectionRReverse">
                <div class="w-55">
                  <div class="rectangle positionRelative">
                    <img
                      class="positionAbsolute zIndex2 imgMegaphone2"
                      :src="`${urlS3}/Megafono.webp`"
                    />
                    <img
                      class="positionAbsolute zIndex2 imgHeadphones2"
                      :src="`${urlS3}/audifonos.webp`"
                    />
                    <div class="number BgBlue c-white">1</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(1)">
                      <div
                        :class="step1Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step1 = true"
                        v-on:mouseover="step1 = false"
                      >
                        <div class="dataContent c-letter">
                          <div class="textCenter lh-18" v-show="step1">
                            <p class="p-title mb-0">
                              <strong
                                >IDENTIFICAR EL TIPO DE EVALUACIONES</strong
                              >
                            </p>
                            <p class="p-caption c-letter">
                              QUE SE OBTIENEN DESDE LISTEN EN TWITTER Y FACEBOOK
                            </p>
                          </div>
                          <div v-show="!step1" class="p-sides3">
                            <p class="p-medium lh-14">
                              Es necesario conocer el
                              <strong>panorama en el sector o industria</strong>
                              desde las RR.SS. A partir de este análisis se
                              pueden conocer <strong>insights</strong> y crear
                              <strong>contextos personalizados.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="w-100 d-flex">
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">4</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(4)">
                      <div
                        :class="step4Color"
                        class="rectangleMedium b-r b-l"
                        v-on:mouseleave="step4 = true"
                        v-on:mouseover="step4 = false"
                      >
                        <div class="dataContent c-letter">
                          <div v-show="step4">
                            <p class="lh-14 mb-0 p-title textCenter">
                              <strong
                                >PLANTEAR UNA ESTRATEGIA DE
                                POSICIONAMIENTO</strong
                              >
                            </p>
                          </div>
                          <div v-show="!step4">
                            <ul class="p-medium">
                              <li class="lh-14">
                                Reconocer y
                                <strong>definir el diferencial</strong> de la
                                marca o empresa.
                              </li>
                              <li class="lh-14">
                                Definir la
                                <strong>estrategia de posicionamiento</strong>
                                en base al diferencial.
                              </li>
                              <li class="lh-14">
                                <strong>Definir las RRSS</strong> que
                                utilizarás.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">3</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(3)">
                      <div
                        :class="step3Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step3 = true"
                        v-on:mouseover="step3 = false"
                      >
                        <div class="dataContent c-letter textCenter">
                          <div v-show="step3" class="lh-18">
                            <p class="p-title mb-0">
                              <strong
                                >ANALIZAR LOS DIAGNÓSTICOS RECOGIDOS EN LISTEN Y
                                MONITOR</strong
                              >
                            </p>
                            <p class="p-caption lh-14">
                              PARA CONVERTIRLOS EN INSIGHTS E ÍTEMS DE ACCIÓN
                            </p>
                          </div>
                          <div v-show="!step3">
                            <p class="p-medium lh-14">
                              Los insights surgen luego de una
                              <strong>indagación a profundidad</strong> de los
                              aspectos del consumidor que se ven reflejados en
                              LISTEN y MONITOR.
                              <strong>¡El insight quiere ser revelado!</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space d-flex justifyCenter"></div>
              <div class="w-100 d-flex">
                <div class="w-10"></div>

                <div class="w-35">
                  <div class="rectangle">
                    <div class="number BgBlue c-white number10">10</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(10)">
                      <div
                        :class="step10Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step10 = true"
                        v-on:mouseover="step10 = false"
                      >
                        <div class="dataContent textCenter lh-18 c-letter">
                          <div v-show="step10">
                            <p class="p-title mb-0">
                              <strong>¡EJECUTAR, MONITOREAR Y ESCUCHAR!</strong>
                            </p>
                          </div>
                          <div v-show="!step10">
                            <p class="p-medium lh-14 mb-0">
                              <strong>iEl ciclo se vuelve a repetir!</strong>
                              Para lograr un verdadero éxito en las redes
                              sociales es clave
                              <strong>ser constante.</strong> Se recomienda
                              realizar
                              <strong>evaluaciones periódicamente.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-55">
                  <div class="rectangle">
                    <img
                      class="positionAbsolute zIndex2 imgPieChart"
                      :src="`${urlS3}/grafico-pie.webp`"
                    />
                    <div class="number BgBlue c-white">9</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(9)">
                      <div
                        :class="step9Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step9 = true"
                        v-on:mouseover="step9 = false"
                      >
                        <div class="dataContent textCenter c-letter">
                          <div v-show="step9">
                            <p class="p-title mb-0">
                              <strong>ESCUCHAR LAS REDES SOCIALES</strong>
                            </p>
                            <p class="p-caption lh-14">
                              A TRAVÉS DE LISTEN Y MONITOR PARA GENERAR
                              RETROALIMENTACIÓN
                            </p>
                          </div>
                          <div v-show="!step9">
                            <p class="p-medium lh-14">
                              Revisa constantemente los
                              <strong
                                >indicadores de las campañas en curso</strong
                              >, para evaluar si se están cumpliendo
                              <strong
                                >los objetivos planteados o realizar
                                modificaciones.</strong
                              >
                              Además, es esencial conocer las
                              <strong>novedades de la industria</strong> o
                              sector.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="w-100 d-flex">
                <div class="w-10">
                  <div class="rectangle">
                    <a class="w-100 textDecorationNone" @click="stepsClick(6)">
                      <div
                        :class="step6Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step6 = true"
                        v-on:mouseover="step6 = false"
                      ></div>
                    </a>
                  </div>
                </div>
                <div class="w-90">
                  <div class="rectangle">
                    <div
                      class="number BgLightBlue c-letter positionAbsolute zIndex3"
                    >
                      7
                    </div>
                    <a
                      class="w-100 textDecorationNone"
                      :class="colorParagraph2"
                      @click="stepsClick(7)"
                    >
                      <div
                        :class="step7Color"
                        class="rectangleMedium b-r b-l positionAbsolute zIndex2"
                        v-on:mouseleave="step7 = true"
                        v-on:mouseover="step7 = false"
                      >
                        <div class="dataContent" :class="colorParagrap2">
                          <div class="textCenter" v-show="step7">
                            <p class="p-title lh-18 mb-0">
                              <strong>DEFINIR LAS PIEZAS DE MARKETING</strong>
                            </p>
                            <p class="p-caption lh-14">
                              Y PLANIFICAR SU DESPLIEGUE
                            </p>
                          </div>
                          <div v-show="!step7">
                            <div class="d-flex justifyCenter">
                              <ul class="p-medium">
                                <li class="lh-14">
                                  Comunicado de Rebranding “NOS RENOVAMOS”.
                                </li>
                                <li class="lh-14">Hora.</li>
                                <li class="lh-14">
                                  Tipo de post (Imágenes, videos, tips, reviews,
                                  citas o frases, infografías, descuentos,
                                  ofertas, concursos, live, gif, tutoriales).
                                </li>
                                <li class="lh-14">Calendarización.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </v-row>
          </v-col>
          <!-- COL 3 -->
          <v-col class="colSides p-0">
            <v-row>
              <a
                class="w-100 textDecorationNone positionRelative"
                @click="stepsClick(2)"
              >
                <div
                  :class="step2Color"
                  class="d-flex rectangleGreatRight"
                  v-on:mouseleave="step2 = true"
                  v-on:mouseover="step2 = false"
                >
                  <div
                    class="white rectangleLittleRight"
                    style="width: 20%"
                  ></div>
                  <div
                    class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter c-letter textCenter"
                    style="margin: 0rem 1rem 0rem 0.5rem"
                  >
                    <div class="h-30 w-100 d-flex alignItemsFEnd justifyCenter">
                      <div class="numberCustom BgBlue c-white">2</div>
                    </div>
                    <div class="h-70 ptp-1">
                      <div v-show="step2">
                        <p class="p-title lh-18 mb-0">
                          <strong>IDENTIFICAR EL TIPO DE EVALUACIONES</strong>
                        </p>
                        <p class="p-caption lh-14">
                          QUE SE OBTIENEN DESDE MONITOR A TRAVÉS DEL MAPEO DE
                          COMPETIDORES
                        </p>
                      </div>
                      <div v-show="!step2">
                        <p class="p-medium lh-14">
                          <strong>El benchmarking</strong> permite elevar
                          estándares de
                          <strong>competitividad y liderazgo,</strong> y fomenta
                          la creación de nuevas
                          <strong>ideas y estrategias.</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div class="space"></div>
              <a class="w-100 textDecorationNone" @click="stepsClick(8)">
                <div
                  :class="step8Color"
                  class="d-flex rectangleGreatRight positionRelative"
                  v-on:mouseleave="step8 = true"
                  v-on:mouseover="step8 = false"
                >
                  <div
                    class="white rectangleLittleRight"
                    style="width: 20%"
                  ></div>
                  <div
                    class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter c-letter"
                    style="width: 80%"
                  >
                    <div
                      class="h-30 w-100 d-flex alignItemsFEnd justifyCenter m-sideRight"
                    >
                      <div class="numberCustom BgBlue c-white">8</div>
                    </div>
                    <div class="ptp-1 h-70 w-100">
                      <div class="m-sideRight3 textCenter" v-show="step8">
                        <p class="p-title lh-18 mb-0">
                          <strong>ESTABLECER OFERTAS Y/O</strong>
                        </p>
                        <p class="p-caption lh-14">
                          PROMOCIONES DIFERENTES AL CANAL DE VENTAS TRADICIONAL
                        </p>
                      </div>
                      <div v-show="!step8">
                        <p class="p-medium lh-14 textEnd m-sideRight p-sides">
                          Realizar
                          <strong>promociones u ofertas</strong> dinamiza las
                          <strong>RR.SS.</strong> de las marcas. Deben ser
                          <strong>diferentes</strong> a las que existen en las
                          tiendas físicas o canales tradicionales.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlS3: "https://image-store.isocialcube.com/advise",
      step1: true,
      step2: true,
      step3: true,
      step4: true,
      step5: true,
      step6: true,
      step7: true,
      step8: true,
      step9: true,
      step10: true,
      step11: true,
      step1Color: "gray",
      step2Color: "gray",
      step3Color: "gray",
      step4Color: "gray",
      step5Color: "gray",
      step6Color: "purpleGray",
      step7Color: "purpleGray",
      step8Color: "lightBlue",
      step9Color: "lightBlue",
      step10Color: "lightBlue",
      colorParagraph: "c-white",
      colorParagraph2: "c-white",
    };
  },
  methods: {
    stepsClick(id) {
      switch (id) {
        case 1:
          this.step1Color = "colorYellow";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "lightBlue";
          this.step9Color = "lightBlue";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 2:
          this.step1Color = "gray";
          this.step2Color = "colorYellow";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "lightBlue";
          this.step9Color = "lightBlue";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 3:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "colorYellow";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "lightBlue";
          this.step9Color = "lightBlue";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 4:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "colorYellow";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "lightBlue";
          this.step9Color = "lightBlue";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 5:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "colorYellow";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "lightBlue";
          this.step9Color = "lightBlue";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 6:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "colorYellow";
          this.step7Color = "purpleGray";
          this.step8Color = "lightBlue";
          this.step9Color = "lightBlue";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-letter";
          this.colorParagraph2 = "c-white";
          break;
        case 7:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "colorYellow";
          this.step8Color = "lightBlue";
          this.step9Color = "lightBlue";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-letter";
          break;
        case 8:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "colorYellow";
          this.step9Color = "lightBlue";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 9:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "lightBlue";
          this.step9Color = "colorYellow";
          this.step10Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 10:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "lightBlue";
          this.step9Color = "lightBlue";
          this.step10Color = "colorYellow";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        default:
          break;
      }
      this.$emit("stepsClick", id);
    },
  },
};
</script>

<style>
.w-90 {
  width: 90%;
}
.p-sides07 {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

.fDirectionReverse {
  flex-direction: row-reverse;
}

.imgGirl {
  width: 10%;
  top: 20%;
}

.imgClock {
  left: 30%;
  width: 2%;
}

.imgMegaphone2 {
  width: 9%;
  left: 4%;
  bottom: 6%;
}

.imgHeadphones2 {
  width: 9%;
  right: 5%;
  top: 18%;
}

.imgGraphicCyan {
  width: 3%;
  left: 17%;
  top: 27%;
}

.imgGraphicYellow {
  width: 2%;
  top: 41%;
  left: 49%;
}

.imgChat {
  width: 4%;
  right: 16%;
  top: 37%;
}

.imgLikePhone {
  width: 40%;
}

.imgVideoRating {
  width: 4%;
  top: 51%;
  left: 25%;
}

.imgPhoto {
  width: 4%;
  top: 62%;
  left: 42%;
}

.imgPieChart {
  top: 0;
  left: 83%;
  width: 7%;
}

.imgSearchUser {
  right: 6%;
  bottom: 5%;
  width: 25%;
}

.imgPlayCell {
  bottom: 7%;
  left: 29%;
  width: 4%;
}

.imgIPad {
  width: 20%;
  bottom: 0%;
  right: 7%;
}

.imgImages {
  bottom: 23%;
  right: 24%;
  width: 4%;
}

.inf2 {
  top: 42%;
  width: 7%;
}

.inf3 {
  bottom: 0%;
  left: 1%;
}

@media (max-width: 2900px) {
  .p-medium-step5-DOMP{
    font-size: 9px !important;
    padding-left: 10px;
  }
}

@media (max-width: 2500px) {
  .p-medium-step5-DOMP{
    font-size: 8px !important;
  }
}

@media (min-width: 2600px) {
  .imgGirl {
    width: 8.5%;
  }
}

@media ((max-width: 2396px) and (min-width: 2201px)) {
  .container-title-CA {
    width: 23%;
  }

  .containerText {
    width: 23%;
  }
}

@media (max-width: 2355px) {
  .p-medium-step5-DOMP{
    font-size: 7px !important;
  }
}

@media (max-width: 2220px) {
  .p-medium-step5-DOMP{
    padding-left: 14px !important;
  }
}

@media (max-width: 2200px) {
  .imgGirl {
    width: 8%;
  }

  .p-medium-step5-DOMP{
    font-size: 10px !important;
  }
}

@media (max-width: 2000px) {
  .imgGirl {
    width: 9%;
  }
}

@media ((max-width: 1732px) and (min-width: 1701px)) {
  .container-title-CA {
    width: 25%;
  }

  .containerText {
    width: 20%;
  }

  .imgGirl {
    width: 11%;
  }
}

@media (max-width: 1700px) {
  .imgGirl {
    top: 17%;
    width: 9.5%;
  }
}

@media (max-width: 1521px) {
  .imgGirl {
    top: 19%;
  }
}

@media (max-width: 1440px) {
  .inf2 {
    right: 4% !important;
  }
}

@media (max-width: 1400px) {
  .imgPieChart {
    width: 9% !important;
  }

  .imgVideoRating {
    left: 29% !important;
  }

  .imgChat {
    right: 20% !important;
  }

  .imgIPad {
    width: 26% !important;
    right: 8% !important;
  }

  .imgImages {
    right: 32% !important;
  }

  .imgPlayCell {
    left: 36% !important;
  }

  .ca .p-titleMap {
    padding: 3rem 0rem 3rem 2rem !important;
  }
}

@media ((max-width: 1400px) and (min-width: 1135px)) {
  .containerText {
    padding-left: 2rem !important;
  }
}

@media ((max-width: 1215px) and (min-width: 1176px)) {
  /* .ca .p-titleMap {
    font-size: 25px !important;
  } */
  
  .imgGirl{
    top: 20%;
    width: 11.5%;
  }
}

@media (max-width: 1200px) {
  .imgAnalysis {
    left: 43% !important;
  }

  .p-medium-step5-DOMP {
    font-size: 9px !important;
  }
}

@media (max-width: 1800px) {
  .p-title-dpm{
    font-size: 22px !important;
    line-height: 24px;

  }
}

@media (max-width: 1150px) {
  .p-title-dpm{
    font-size: 20px !important;
    line-height: 24px;

  }
}

@media ((max-width: 1175px) and (min-width: 1137px)) {
  /* .ca .p-titleMap {
    font-size: 28px !important;
    line-height: 25px;
  } */

  .imgGirl{
    width: 12.5%;
  }
}

@media (max-width: 1140px) {
  .imgAnalysis {
    top: 60% !important;
  }
}

@media (max-width: 1137px){
  .imgGirl {
    top: 19%;
    width: 13%;
  }

  /* .ca .p-titleMap {
    font-size: 24px !important;
    line-height: 26px;
  } */
}

@media (max-width: 1090px) {
  .container-title-CA{
    width: 22%;
  }

  .p-medium-step5-DOMP {
    font-size: 7px !important;
  }
}
</style>
