import { render, staticRenderFns } from "./DoItYourself.vue?vue&type=template&id=36cb2266&scoped=true"
import script from "./DoItYourself.vue?vue&type=script&lang=js"
export * from "./DoItYourself.vue?vue&type=script&lang=js"
import style0 from "./DoItYourself.vue?vue&type=style&index=0&id=36cb2266&prod&lang=css"
import style1 from "./DoItYourself.vue?vue&type=style&index=1&id=36cb2266&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36cb2266",
  null
  
)

export default component.exports