<template>
  <div style="height: 100%;">
    <div>
      <v-toolbar tile absolute color="white" short class="toolbar_diy">
        <div>
          <v-toolbar-title><span>Do It Yourself</span></v-toolbar-title>
        </div>
      </v-toolbar>
      <v-container
        fluid
        style="
          margin-top: 6rem;
          padding-left: 10%;
          padding-right: 10%;
          background-color: #f3f4f8;
        "
        class="container-roadmap"
      >
        <v-row class="main_container" justify="center" no-gutters>
          <v-col cols="12">
            <v-tabs
              v-model="tab"
              grow
              height="4rem"
              color="#2C3852"
              background-color="transparent"
            >
              <v-tabs-slider hidden></v-tabs-slider>
              <v-tab
                class="main-tab mr-3 mb-1 mt-1 ml-1"
                active-class="a-main-tab"
              >
                <svg
                  class="mr-4"
                  width="18"
                  height="28"
                  viewBox="0 0 18 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.5593 9.84498L17.8828 5.77629C18.0098 5.62235 18.0359 5.40873 17.9498 5.22872C17.8624 5.0496 17.6789 4.93761 17.4796 4.94177H4.37136V4.34976C5.30314 4.0587 5.82255 3.06739 5.53154 2.13562C5.24048 1.20384 4.24917 0.684427 3.3174 0.975435C2.38562 1.2665 1.86621 2.25781 2.15722 3.18958C2.3316 3.74787 2.77092 4.18376 3.33056 4.35382V23.8323H1.56703C0.705767 23.8287 0.0044234 24.5236 0 25.3848V26.4777C0.00202956 26.7679 0.236002 27.0031 0.526229 27.0068H7.18736C7.47529 27.0022 7.70536 26.7656 7.70193 26.4777V25.3848C7.70286 24.5283 7.00933 23.8332 6.15285 23.8323C6.15077 23.8323 6.14864 23.8323 6.14655 23.8323H4.37136V14.7773H17.4796C17.7705 14.7737 18.0034 14.5349 17.9997 14.244C17.9982 14.1259 17.957 14.0116 17.8828 13.9197L14.5593 9.84498ZM6.14655 24.8731C6.42747 24.8698 6.65785 25.095 6.66113 25.3759C6.66118 25.3788 6.66118 25.3819 6.66113 25.3848V25.966H1.0408V25.3848C1.0446 25.0984 1.27992 24.8693 1.56635 24.8731C1.56656 24.8731 1.56682 24.8731 1.56703 24.8731H6.14655ZM3.84982 3.38926C3.4476 3.38884 3.12167 3.06292 3.12126 2.6607C3.12126 2.25833 3.44745 1.93214 3.84982 1.93214C4.25219 1.93214 4.57838 2.25833 4.57838 2.6607C4.57838 3.06307 4.25219 3.38926 3.84982 3.38926ZM4.37136 13.7365V5.98257H16.3834L13.4845 9.52509C13.3282 9.71671 13.3282 9.99179 13.4845 10.1834L16.3834 13.7365H4.37136Z"
                    fill="#2C3852"
                  />
                </svg>
                Goals Definition
              </v-tab>
              <v-tab class="main-tab mr-3 mb-1 mt-1" active-class="a-main-tab"
                ><svg
                  class="mr-4"
                  width="15"
                  height="23"
                  viewBox="0 0 15 23"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.6786 7.07143C10.6786 5.46094 8.7779 4.75 7.42857 4.75C7.18192 4.75 6.96429 4.96763 6.96429 5.21429C6.96429 5.46094 7.18192 5.67857 7.42857 5.67857C8.19754 5.67857 9.75 6.07031 9.75 7.07143C9.75 7.31808 9.96763 7.53571 10.2143 7.53571C10.4609 7.53571 10.6786 7.31808 10.6786 7.07143ZM13 7.07143C13 8 12.6228 8.9721 12.0134 9.68304C11.7377 10.0022 11.4185 10.3069 11.1283 10.6406C10.0982 11.8739 9.22768 13.3248 9.08259 14.9643H5.77455C5.62946 13.3248 4.75893 11.8739 3.72879 10.6406C3.43862 10.3069 3.11942 10.0022 2.84375 9.68304C2.23438 8.9721 1.85714 8 1.85714 7.07143C1.85714 4.16964 4.80246 2.42857 7.42857 2.42857C10.0547 2.42857 13 4.16964 13 7.07143ZM14.8571 7.07143C14.8571 3.125 11.0993 0.571427 7.42857 0.571427C3.75781 0.571427 0 3.125 0 7.07143C0 8.56585 0.493304 9.85714 1.49442 10.9598C2.49554 12.0625 3.81585 13.615 3.93192 15.1674C3.51116 15.4141 3.25 15.8783 3.25 16.3571C3.25 16.7054 3.38058 17.0391 3.61272 17.2857C3.38058 17.5324 3.25 17.8661 3.25 18.2143C3.25 18.6931 3.49665 19.1283 3.9029 19.3895C3.78683 19.5926 3.71429 19.8393 3.71429 20.0714C3.71429 21.0145 4.45424 21.4643 5.29576 21.4643C5.67299 22.3058 6.51451 22.8571 7.42857 22.8571C8.34263 22.8571 9.18415 22.3058 9.56138 21.4643C10.4029 21.4643 11.1429 21.0145 11.1429 20.0714C11.1429 19.8393 11.0703 19.5926 10.9542 19.3895C11.3605 19.1283 11.6071 18.6931 11.6071 18.2143C11.6071 17.8661 11.4766 17.5324 11.2444 17.2857C11.4766 17.0391 11.6071 16.7054 11.6071 16.3571C11.6071 15.8783 11.346 15.4141 10.9252 15.1674C11.0413 13.615 12.3616 12.0625 13.3627 10.9598C14.3638 9.85714 14.8571 8.56585 14.8571 7.07143Z"
                    fill="#2C3852"
                  />
                </svg>
                Analize: ¡Get Insights!
              </v-tab>
              <v-tab
                class="main-tab mb-1 mt-1"
                active-class="a-main-tab"
                disabled
              >
                <img
                  class="mr-5"
                  src="../assets/nube.png"
                  alt="bandera"
                  height="30px"
                />
                FAQ
              </v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="12" class="mt-2 mb-4 pl-1">
            <v-tabs-items v-model="tab" style="background-color: #f3f4f8">
              <v-tab-item>
                <v-row justify="start" style="background-color: #f3f4f8">
                  <v-col cols="4" class="select_st"
                    ><span>Meta:</span
                    ><v-select
                      dense
                      v-model="metaSeleccionada"
                      hide-details="true"
                      :items="metas"
                      label="[Select Meta]"
                      style="width: 90%; font-size: 14px; margin-left: 2px"
                      item-text="meta"
                      item-value="id"
                      return-object
                      solo
                      @change="cambiarMeta"
                      class="select_title"
                    >
                      <template #item="{ item }">
                        <span class="select_option"> {{ item.meta }} </span>
                      </template>
                    </v-select></v-col
                  >
                  <v-col cols="4" class="select_st"
                    ><span>Objetivo:</span
                    ><v-select
                      dense
                      v-model="objetivoSeleccionado"
                      hide-details="true"
                      :items="objetivos"
                      label="[Select Meta]"
                      style="width: 90%; font-size: 14px; margin-left: 2px"
                      item-text="meta"
                      item-value="id"
                      return-object
                      solo
                      @change="swapComponent(objetivoSeleccionado)"
                      class="select_title"
                    >
                      <template #item="{ item }">
                        <span class="select_option"> {{ item }} </span>
                      </template>
                    </v-select></v-col
                  >
                </v-row>

                <v-row
                  style="margin-top: 2rem !important"
                  v-show="roadmapComponent == 'SocialProfileLaunch'"
                >
                  <SocialProfileLaunch
                    @stepsClick="swapStep"
                  ></SocialProfileLaunch>
                </v-row>
                <v-row
                  style="margin-top: 2rem !important"
                  v-show="roadmapComponent == 'AudienceCampaign'"
                >
                  <AudienceCampaign @stepsClick="swapStep"></AudienceCampaign>
                </v-row>
                <v-row
                  style="margin-top: 2rem !important"
                  v-show="roadmapComponent == 'DesignOfMarketingPieces'"
                >
                  <DesignOfMarketingPieces
                    @stepsClick="swapStep"
                  ></DesignOfMarketingPieces>
                </v-row>
                <v-row
                  style="margin-top: 2rem !important"
                  v-show="roadmapComponent == 'Análisis de Mercado'"
                >
                  <AnalysisInfographics
                    :title="'ANÁLISIS DE MERCADO'"
                    @stepsClick="swapStep"
                  ></AnalysisInfographics>
                </v-row>
                <v-row
                  style="margin-top: 2rem !important"
                  v-show="roadmapComponent == 'Análisis de Marca'"
                >
                  <AnalysisInfographics
                    :title="'ANÁLISIS DE MARCA'"
                    @stepsClick="swapStep"
                  ></AnalysisInfographics>
                </v-row>
                <v-row
                  style="margin-top: 2rem !important"
                  v-show="roadmapComponent == 'Análisis de Producto | Servicio'"
                >
                  <AnalysisInfographics
                    :title="'ANÁLISIS DE PRODUCTO/SERVICIO'"
                    @stepsClick="swapStep"
                  ></AnalysisInfographics>
                </v-row>

                <!-- 

                  Steps

                <div class="mt-5 mb-5">
                  <span
                    v-for="(n, index) in 12"
                    :key="index"
                    @click="swapStep(n - 1)"
                    class="px-3"
                    >{{ n - 1 }}</span
                  >
                </div>

                -->

                <v-col
                  style="
                    background-color: #f3f4f8;
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                    padding-top: 2rem;
                  "
                  class="container_info_steps"
                >
                  <div
                    class="info_steps"
                    :is="currentComponent"
                    :step="step"
                    :tab_title="tab_title"
                  ></div>
                </v-col>
                <v-col
                  cols="12"
                  style="
                    margin-top: 20px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #c4c4c4;
                  "
                  v-if="!objetivoSeleccionado"
                >
                  <span
                    class="d-flex align-center justify-center"
                    style="height: 40vh"
                  >
                    Por favor, selecciona una meta y un objetivo para poder
                    iniciar
                  </span>
                </v-col>
              </v-tab-item>
              <v-tab-item>
                <div
                  class="d-flex alignItemsCenter justifyCenter"
                  style="margin-top: 2rem"
                >
                  <div class="w-70 d-flex">
                    <div class="w-30 d-flex alignItemsCenter justifyCenter">
                      <img :src="`${urlS3}/idea.webp`" />
                    </div>
                    <div
                      class="w-70 d-flex alignItemsCenter justifyCenter fDirectionColumn"
                    >
                      <p class="titleParagraph-Analize textCenter">
                        Aprender, Identificar, Analizar y Definir Estrategias
                      </p>
                      <p class="subParagraph-Analize">
                        Te llevaremos de la mano para que aprendas a hacerlo solo.
                        Para ello, en esta sección encontrarás información de
                        mucha utilidad que te ayudará a
                        <strong>Identificar</strong> información en redes
                        sociales, <strong>Medir</strong> los resultados de las
                        estrategias de marketing que se establezcan y
                        <strong>Establecer</strong> el tipo de contenido para el
                        desarrollo de estrategias.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tabsAnalize" style="margin-top: 2rem">
                  <v-tabs centered slider-color="#00A5FF" v-model="model">
                    <v-tab :key="1" :href="`#tab-${1}`" class="tab-analize">
                      <p class="subTitleParagraph-Analize">
                        LISTEN:
                        <span class="spanSubTitle-Analize"
                          >Definiciones e ‘Insights’</span
                        >
                      </p>
                    </v-tab>
                    <v-tab :key="2" :href="`#tab-${2}`" class="tab-analize">
                      <p class="subTitleParagraph-Analize">
                        MONITOR:
                        <span class="spanSubTitle-Analize"
                          >Definiciones e ‘Insights’</span
                        >
                      </p>
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="model">
                    <v-tab-item
                      transition="fade-transition"
                      reverse-transition="fade-transition"
                      :key="1"
                      :id="`tab-${1}`"
                    >
                      <v-card flat>
                        <v-card-text>
                          <AnalizeListen></AnalizeListen>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item
                      transition="fade-transition"
                      reverse-transition="fade-transition"
                      :key="2"
                      :id="`tab-${2}`"
                    >
                      <v-card flat>
                        <v-card-text>
                          <AnalizeMonitor></AnalizeMonitor>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
//import { mapActions } from "vuex";
import LanzamientoPerfilSocial from "../components/goalsDefinition/meta1/LanzamientoPerfilSocial.vue";
import CampaniaAudiencia from "../components/goalsDefinition/meta1/CampaniaAudiencia.vue";
import DisenioPiezasMarketing from "../components/goalsDefinition/meta1/DisenioPiezasMarketing.vue";
import AnalisisMercado from "../components/goalsDefinition/meta2/AnalisisMercado.vue";

import SocialProfileLaunch from "../components/roadmap/socialProfileLaunch.vue";
import AudienceCampaign from "../components/roadmap/audienceCampaign.vue";
import DesignOfMarketingPieces from "../components/roadmap/designOfMarketingPieces.vue";
import AnalysisInfographics from "../components/roadmap/AnalysisInfographics.vue";

import AnalizeListen from "../components/analize/listen.vue";
import AnalizeMonitor from "../components/analize/monitor.vue";

export default {
  name: "DoIyYourself",
  data() {
    return {
      urlS3: "https://image-store.isocialcube.com/advise",
      tab: null,
      tab_title: "",
      tab_selected: "",
      metas: [
        {
          id: "meta1",
          meta: "Campañas de Marketing en Redes",
          objetivos: [
            "Lanzamiento de Perfil Social",
            "Campaña para Audiencia | Branding | Leads",
            "Diseño de Piezas de Marketing",
          ],
        },
        {
          id: "meta2",
          meta: "Análisis de Entornos",
          objetivos: [
            "Análisis de Mercado",
            "Análisis de Marca",
            "Análisis de Producto | Servicio",
          ],
        },
      ],
      metaSeleccionada: {},
      objetivoSeleccionado: null,
      currentComponent: null,
      step: null,
      roadmapComponent: null,
      model: "tab-2",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      isScreensizeLess: false,
    };
  },
  components: {
    LanzamientoDePerfilSocial: LanzamientoPerfilSocial,
    CampaniaAudiencia: CampaniaAudiencia,
    DisenioPiezasMarketing: DisenioPiezasMarketing,
    AnalisisMercado: AnalisisMercado,
    SocialProfileLaunch: SocialProfileLaunch,
    AudienceCampaign: AudienceCampaign,
    DesignOfMarketingPieces: DesignOfMarketingPieces,
    AnalysisInfographics: AnalysisInfographics,
    AnalizeListen: AnalizeListen,
    AnalizeMonitor: AnalizeMonitor,
  },
  created() {
  },
  computed: {
    objetivos() {
      return this.metaSeleccionada.objetivos || [];
    },
  },
  methods: {
    //...mapActions(["setActiveWindow"]),
    getComponent(objetivo) {
      let info_steps;
      let roadmap;
      switch (objetivo) {
        case "Lanzamiento de Perfil Social":
          info_steps = "LanzamientoDePerfilSocial";
          roadmap = "SocialProfileLaunch";
          break;
        case "Campaña para Audiencia | Branding | Leads":
          info_steps = "CampaniaAudiencia";
          roadmap = "AudienceCampaign";
          break;
        case "Diseño de Piezas de Marketing":
          info_steps = "DisenioPiezasMarketing";
          roadmap = "DesignOfMarketingPieces";
          break;
        case "Análisis de Mercado":
          info_steps = "AnalisisMercado";
          roadmap = "Análisis de Mercado";
          break;
        case "Análisis de Marca":
          info_steps = "AnalisisMercado";
          roadmap = "Análisis de Marca";
          break;
        case "Análisis de Producto | Servicio":
          info_steps = "AnalisisMercado";
          roadmap = "Análisis de Producto | Servicio";
          break;
        default:
          break;
      }
      return { info_steps, roadmap };
    },
    cambiarObjetivo(value) {
      this.objetivoSeleccionado = value;
    },
    cambiarMeta() {
      this.currentComponent = null;
      this.roadmapComponent = null;
      this.cambiarObjetivo(null);
    },
    swapStep(step) {
      console.log("step -> ", step);
      //  Scroll top v-card
      const obj = document.getElementsByClassName("step_main_content")[0];
      obj.scrollTop = 0;
      //  Scroll bottom info_steps
      const el = this.$el.getElementsByClassName("info_steps")[0];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
        this.step = step;
      }
    },
    swapComponent(component) {
      this.currentComponent = this.getComponent(component).info_steps;
      this.roadmapComponent = this.getComponent(component).roadmap;
      this.step = 0;
    }
  },
  watch: {
    objetivoSeleccionado: {
      handler() {
        if (this.metaSeleccionada) {
          if (this.metaSeleccionada.meta != "Análisis de Entornos") {
            this.tab_title = `${this.metaSeleccionada.meta}: ${this.objetivoSeleccionado}`;
          } else {
            this.tab_title = this.objetivoSeleccionado;
          }
        }
      },
    },
  },
};
</script>

<style>
/* tOOLBAR */
@import url("https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap");
.titleParagraph-Analize {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #2c3852;
}

.subParagraph-Analize {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: justify;
  color: #5d6b88;
}

.toolbar_diy {
  padding: 0px !important;
  margin: 0px !important;
  left: 0px;
  width: 100%;
}

.toolbar_diy span {
  font-size: 20px;
  font-family: "Oxygen", sans-serif;
  font-weight: 900;
  color: #2c3852;
  padding-left: 1rem;
}

.main_container {
  font-family: "Oxygen", sans-serif;
}

.main_container .row {
  margin: 0px !important;
}

.main-tab {
  justify-content: left;
  text-transform: none;
  border-radius: 5px;
  background-color: white;
  color: #2c3852;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0px;
  height: 85%;
}
.main-tab:not(.v-tab--active) {
  color: #2c3852 !important;
}

.a-main-tab {
  color: white !important;
  background-color: #00a5ff;
}

.a-main-tab svg path {
  color: white !important;
  background-color: #00a5ff;
  fill: white;
}

/* SELECT */
.select_st {
  font-size: 14px;
  padding: 0% !important;
}

.select_st span {
  color: #2c3852;
  font-weight: bold;
}

.select_st >>> .v-input__slot {
  background: #fff;
  height: 40px;
  padding-left: 12px;
  border: solid 1px #c7cfe2 !important;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px 1px rgba(72, 72, 72, 0.18);
}

.select_st >>> .ip_sl:last-child {
  margin-right: 0;
}

.select_option {
  font-family: "Oxygen", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #5d6b88;
}

.v-list-item__title {
  font-family: "Oxygen", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: #5d6b88;
}

.v-select.v-input--dense .v-select__selection--comma {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #5d6b88;
}

.select_st >>> .v-input__slot {
  background: #fff;
  height: 40px;
  padding-left: 12px;
  border: solid 1px #c7cfe2 !important;
  border-radius: 3px;
  box-shadow: inset 0px 1px 3px 1px rgba(72, 72, 72, 0.18);
}

.select_st >>> .v-text-field {
  padding: 0px !important;
}

.select_st >>> .v-input__slot::before {
  border: none !important;
}
.select_st >>> .v-input__slot::after {
  border: none !important;
}

.select_st >>> .v-label {
  color: #5d6b88;
  font-size: 14px;
  font-weight: normal;
}

.select_st >>> .v-label--active {
  display: none;
}

/* Info steps */

.info_steps {
  height: auto;
}

.info_steps > div {
  height: auto;
}

.v-window {
  overflow: visible !important;
}

.tab-analize {
  width: 50% !important;
  max-width: 50% !important;
}

.subTitleParagraph-Analize {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  text-transform: none;
  margin: 1rem 0rem;
  color: #2c3852;
}

.spanSubTitle-Analize {
  font-weight: 400;
}

.tabsAnalize
  .v-slide-group__wrapper
  .v-slide-group__content
  .v-tab--active
  .subTitleParagraph-Analize {
  color: #00a5ff !important;
}

.tabsAnalize .tab-analize {
  background: #f3f4f7;
  border: solid 3px #ffffff;
}

.tabsAnalize .v-tab--active {
  background: #ffffff;
}

.tabsAnalize .v-slide-group__content .v-tabs-slider-wrapper {
  bottom: auto;
  width: 50% !important;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined){
  box-shadow: 0px 2px 4px rgba(39, 52, 68, 0.1);
}

/* .v-slide-group__wrapper .v-slide-group__content .v-tabs-slider-wrapper {
  width: 564px !important;
} */

@media (max-width: 1700px) {
  .container--fluid {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
}

@media (max-width: 1400px) {
  .container--fluid {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}

@media (max-width: 1300px) {
  .p-titleMap {
    padding: 0rem;
  }
}

.select_title .theme--light.v-label {
  font-family: "Oxygen";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #5d6b88;
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot{
  box-shadow: inset 0px 2px 5px rgba(197, 208, 228, 0.62);
  border: 1px solid #C5D0E4;
}

.container_info_steps .v-sheet.v-card:not(.v-sheet--outlined){
  box-shadow: none;
}
</style>

<style scoped>
@media (min-width: 3000px) {
  ::v-deep.container-roadmap {
    padding: 0% 30% !important;
  }
}

@media (max-width: 3000px) {
  ::v-deep.container-roadmap {
    padding: 0% 25% !important;
  }
}


@media (max-width: 2200px) {
  ::v-deep.container-roadmap {
    padding: 0% 15% !important;
  }
}

@media (max-width: 1700px) {
  ::v-deep.container-roadmap {
    padding: 0% 10% !important;
  }
}
</style>
