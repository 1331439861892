<template>
  <div class="w-100">
    <div class="white positionRelative">
      <img
        class="positionAbsolute imgObjective"
        :src="`${urlS3}/objetivo.webp`"
      />
      <!--Title-->
      <div class="d-flex c-title-sub positionAbsolute w-100">
        <div class="d-flex w-50 positionRelative">
          <img
            class="positionAbsolute"
            style="width: 20%"
            :src="`${urlS3}/Vector.webp`"
          />
          <img
            style="height: 20%; right: 35%"
            class="positionAbsolute imgReloj"
            :src="`${urlS3}/reloj.webp`"
          />
          <div class="p-2 positionRelative">
            <div class="d-flex w-100 c-title-sub">
              <div class="w-35 d-flex alignItemsCenter fDirectionColumn mt-05">
                <p class="p-titleMap mb-0 p-title-lps">
                  LANZAMIENTO DE PERFIL SOCIAL
                </p>
                <div class="w-100">
                  <div class="w-50 indicativeText">
                    <p class="mb-0"><strong>IMPLEMENTACIÓN</strong></p>
                    <p class="mb-05">ESTRATEGIA + MONITOR</p>
                  </div>
                </div>
                <div class="w-100">
                  <div
                    class="w-50 d-flex justifyCenter"
                    style="padding: 0rem 1rem"
                  >
                    <img
                      :src="`${urlS3}/Flecha-2SPL.webp`"
                      style="width: 4rem"
                    />
                  </div>
                </div>
              </div>
              <div class="w-65 p-sides2">
                <div class="h-100 positionRelative zIndex2">
                  <div class="h-40">
                    <a
                      class="w-100 textDecorationNone c-title-sub"
                      @click="stepsClick(0)"
                    >
                      <p class="p-subMap1 mb-0"><strong>ANTES</strong></p>
                      <p class="p-subMap2 mb-0">
                        <strong>DE EMPEZAR...</strong>
                      </p>
                      <p class="p-subMap2 mb-0 lh-16">
                        Es necesario definir los siguientes puntos antes de
                        realizar un análisis y plantear una estrategia.
                      </p>
                    </a>
                  </div>
                  <div
                    class="h-60 d-flex justifyEnd"
                    style="padding: 1rem 0rem"
                  >
                    <img
                      style="width: 1.5rem; height: 1.5rem"
                      :src="`${urlS3}/nodos-cyan.webp`"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-50 d-flex fDirectionColumn alignItemsFEnd p-right2">
          <div class="indicativeText2">
            <p class="mb-0"><strong>ACCIONES PREVIAS</strong></p>
            <p class="mb-0">MONITOR + LISTEN</p>
          </div>
          <div>
            <img :src="`${urlS3}/Flecha-1.webp`" style="width: 4rem" />
          </div>
        </div>
      </div>
      <div class="d-flex containerMap zIndex2">
        <div class="d-flex w-100 positionRelative">
          <img
            class="positionAbsolute imgBarras"
            :src="`${urlS3}/barras.webp`"
          />
          <img
            class="positionAbsolute imgStar"
            :src="`${urlS3}/estrella.webp`"
          />
          <img
            class="positionAbsolute imgGraphic"
            :src="`${urlS3}/grafico-1.webp`"
          />
          <img
            class="positionAbsolute imgArrow4"
            :src="`${urlS3}/Flecha-4SPL.webp`"
          />
          <img
            class="positionAbsolute imgPhone"
            :src="`${urlS3}/celular.webp`"
          />
          <img
            class="positionAbsolute imgMegaphone"
            :src="`${urlS3}/megafono.webp`"
          />
          <img
            class="positionAbsolute imgAssessment"
            :src="`${urlS3}/valoracion.webp`"
          />
          <img
            class="positionAbsolute imgOffers2"
            :src="`${urlS3}/ofertas-2.webp`"
          />
          <img
            class="positionAbsolute imgYellowGraph"
            :src="`${urlS3}/grafico-pie.webp`"
          />
          <img
            class="positionAbsolute imgNodoYellowLPS"
            :src="`${urlS3}/nodos-amarillo.webp`"
          />
          <!-- COL 1 -->
          <v-col class="colSides p-0">
            <v-row class="row1">
              <div class="rectangle"></div>
              <div class="space"></div>
              <a class="w-100 textDecorationNone" @click="stepsClick(5)">
                <div
                  :class="step5Color"
                  class="d-flex rectangleGreatLeft positionRelative"
                  v-on:mouseleave="step5 = true"
                  v-on:mouseover="step5 = false"
                >
                  <div
                    class="h-100 dataContent m-sides c-letter"
                    style="width: 70%"
                  >
                    <div
                      class="h-25 w-100 d-flex alignItemsFEnd justifyCenter numberStep5"
                    >
                      <div class="numberCustom BgBlue c-white">5</div>
                    </div>
                    <div class="h-75 ptp-1 listStep5">
                      <div class="textCenter" v-show="step5">
                        <p class="p-title lh-18 mb-0">
                          <strong>DEFINIR EL CONTENIDO</strong>
                        </p>
                        <p class="p-caption lh-14">
                          DE LAS PUBLICACIONES PARA EJECUTAR LA ESTRATEGIA
                        </p>
                      </div>
                      <div v-show="!step5">
                        <ul class="p-medium p-medium-list">
                          <li class="lh-14">
                            Viralizar el contenido
                            <strong>orgánicamente.</strong>
                          </li>
                          <li class="lh-14">
                            Recibir <strong>feedback.</strong>
                          </li>
                          <li class="lh-14">
                            Ejecutar <strong>Plan de Piezas de Mkt.</strong>
                          </li>
                          <li class="lh-14">
                            Las publicaciones deben componerse de
                            <strong>temas y formatos.</strong>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="white rectangleLittleLeft"
                    style="width: 30%"
                  ></div>
                </div>
              </a>
            </v-row>
          </v-col>
          <!-- COL 2 -->
          <v-col class="w-70 p-0">
            <v-row>
              <div class="w-100 d-flex fDirectionRReverse">
                <div class="w-55">
                  <div class="rectangle">
                    <img class="imgPencil" :src="`${urlS3}/lapiz.webp`" />
                    <div class="number BgBlue c-white">1</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(1)">
                      <div
                        :class="step1Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step1 = true"
                        v-on:mouseover="step1 = false"
                      >
                        <div class="dataContent c-letter">
                          <div class="textCenter lh-18 p-sides3" v-show="step1">
                            <p class="p-title lh-18 mb-0">
                              <strong
                                >IDENTIFICAR QUÉ TIPO DE EVALUACIÓN</strong
                              >
                            </p>
                            <p class="p-caption c-letter mb-0">
                              SE OBTIENE DESDE LISTEN EN TWITTER Y FACEBOOK
                            </p>
                          </div>
                          <div v-show="!step1">
                            <ul class="p-medium">
                              <li class="lh-14">
                                Aprovechar oportunidades y evitar amenazas
                                identificandolas en <strong>LISTEN.</strong>
                              </li>
                              <li class="lh-14">
                                Conocer Insights y realizar una
                                <strong>campaña exitosa.</strong>
                              </li>
                              <li class="lh-14">
                                Crear
                                <strong>contextos personalizados</strong> con
                                drivers que complementen el análisis para
                                obtener insights.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="w-100 d-flex">
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">4</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(4)">
                      <div
                        :class="step4Color"
                        class="rectangleMedium b-r b-l"
                        v-on:mouseleave="step4 = true"
                        v-on:mouseover="step4 = false"
                      >
                        <div class="dataContent c-letter">
                          <div class="textCenter" v-show="step4">
                            <p class="lh-14 mb-0 p-title">
                              <strong
                                >PLANTEAR UNA ESTRATEGIA DE
                                POSICIONAMIENTO</strong
                              >
                            </p>
                          </div>
                          <div v-show="!step4">
                            <ul class="p-medium">
                              <li class="lh-14">
                                Reconocer el
                                <strong
                                  >diferencial de la marca o empresa.</strong
                                >
                              </li>
                              <li class="lh-14">
                                Definir la
                                <strong>estrategia de posicionamiento.</strong>
                              </li>
                              <li class="lh-14">
                                <strong>Definir las RRSS</strong> que
                                utilizarás.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">3</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(3)">
                      <div
                        :class="step3Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step3 = true"
                        v-on:mouseover="step3 = false"
                      >
                        <div class="dataContent c-letter textCenter">
                          <div v-show="step3" class="lh-18">
                            <strong class="p-title mb-0"
                              >ANALIZAR LOS DIAGNÓSTICOS</strong
                            >
                            <p class="p-caption lh-14">
                              RECOGIDOS EN LISTEN Y MONITOR PARA CONVERTIRLOS EN
                              INSIGHTS E ÍTEMS DE ACCIÓN
                            </p>
                          </div>
                          <div v-show="!step3">
                            <p class="p-medium lh-14">
                              Los insights surgen luego de una indagación sobre
                              el consumidor. ¡
                              <strong>El insight quiere ser revelado</strong>
                              en LISTEN y MONITOR!
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space d-flex justifyCenter">
                <img
                  style="width: 4rem; height: 2.5rem"
                  class="imgArrow3-socialP"
                  :src="`${urlS3}/Flecha-3SPL.webp`"
                />
                <div class="indicativeText2 p-05">
                  <p class="mb-0">
                    <strong>SUPERVISIÓN Y RETROALIMENTACIÓN</strong>
                  </p>
                  <p class="mb-0">LISTEN + MONITOR</p>
                </div>
              </div>
              <div class="w-100 d-flex">
                <div class="w-10"></div>

                <div class="w-45">
                  <div class="rectangle">
                    <div class="number BgLightBlue c-letter">10</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(10)">
                      <div
                        :class="step10Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step10 = true"
                        v-on:mouseover="step10 = false"
                      >
                        <div
                          :class="colorParagraph2"
                          class="dataContent textCenter lh-18"
                        >
                          <div v-show="step10">
                            <p class="lh-18 p-title">
                              <strong>¡EJECUTAR, MONITORIAR Y ESCUCHAR!</strong>
                            </p>
                          </div>
                          <div v-show="!step10">
                            <p class="lh-14 p-medium">
                              Para ser exitoso en rrss debes
                              <strong>ser constante.</strong><br />
                              Se recomienda realizar
                              <strong>evaluaciones periódicamente</strong>
                              a las campañas que se realicen con el fin de
                              mejorar continuamente.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-45">
                  <div class="rectangle">
                    <div class="number BgLightBlue c-letter">9</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(9)">
                      <div
                        :class="step9Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step9 = true"
                        v-on:mouseover="step9 = false"
                      >
                        <div
                          :class="colorParagraph"
                          class="dataContent textCenter"
                        >
                          <div v-show="step9">
                            <p class="p-title mb-0">
                              <strong>ESCUCHAR A LAS RR.SS.</strong>
                            </p>
                            <p class="p-caption lh-14">
                              A TRAVÉS DE LISTEN Y MONITOR PARA GENERAR
                              RETROALIMENTACIÓN.
                            </p>
                          </div>
                          <div v-show="!step9">
                            <p class="p-medium lh-14">
                              <strong>Revisar los indicadores </strong>
                              de las campañas en curso, y evaluar si se cumplen
                              los objetivos planteados o si se deben modificar
                              para lograr
                              <strong>mejores resultados.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="w-100 d-flex">
                <div class="w-10">
                  <div class="rectangle">
                    <a class="w-100 textDecorationNone" @click="stepsClick(5)">
                      <div
                        :class="step5Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step5 = true"
                        v-on:mouseover="step5 = false"
                      ></div>
                    </a>
                  </div>
                </div>
                <div class="w-45">
                  <div class="rectangle">
                    <div class="number BgBlue c-white positionAbsolute zIndex3">
                      6
                    </div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(6)">
                      <div
                        :class="step6Color"
                        class="rectangleMedium b-r b-l positionAbsolute zIndex2 d-flex justifyCenter alignItemsCenter"
                        v-on:mouseleave="step6 = true"
                        v-on:mouseover="step6 = false"
                      >
                        <div class="dataContent c-letter">
                          <div class="textCenter" v-show="step6">
                            <p class="p-title lh-18 mb-0">
                              <strong>DEFINIR LAS PIEZAS DE MARKETING</strong>
                            </p>
                            <p class="p-caption lh-14">
                              Y PLANIFICAR SU DESPLIEGUE
                            </p>
                          </div>
                          <div v-show="!step6">
                            <div class="d-flex justifyCenter">
                              <ul class="p-medium">
                                <li class="lh-14">Semana de intriga.</li>
                                <li class="lh-14">Día de Lanzamiento.</li>
                                <li class="lh-14">Hora.</li>
                                <li class="lh-14">Calendarización.</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-45">
                  <div class="rectangle">
                    <img
                      class="positionAbsolute imgOffers"
                      :src="`${urlS3}/ofertas.webp`"
                    />
                    <div class="number BgBlue c-white">7</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(7)">
                      <div
                        :class="step7Color"
                        class="rectangleMedium b-r d-flex justifyCenter alignItemsCenter"
                        v-on:mouseleave="step7 = true"
                        v-on:mouseover="step7 = false"
                      >
                        <div class="dataContent c-letter textCenter">
                          <div v-show="step7">
                            <p class="p-title lh-18 mb-0">
                              <strong
                                >ESTABLECER OFERTAS Y/O PROMOCIONES</strong
                              >
                            </p>
                            <p class="p-caption lh-14">
                              DIFERENTES AL CANAL DE VENTAS TRADICIONAL
                            </p>
                          </div>
                          <div v-show="!step7">
                            <p class="p-medium lh-14">
                              Realizar promociones u ofertas puede
                              <strong>dinamizar las rrss de la marca. </strong>
                              Sin embargo, deben ser
                              <strong>diferentes</strong> a las que existen en
                              las <strong>tiendas</strong> físicas o canales
                              tradicionales para lograr el objetivo.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </v-row>
          </v-col>
          <!-- COL 3 -->
          <v-col class="colSides p-0">
            <v-row>
              <a
                class="w-100 textDecorationNone positionRelative"
                @click="stepsClick(2)"
              >
                <div
                  :class="step2Color"
                  class="d-flex rectangleGreatRight"
                  v-on:mouseleave="step2 = true"
                  v-on:mouseover="step2 = false"
                >
                  <div
                    class="white rectangleLittleRight"
                    style="width: 25%"
                  ></div>
                  <div
                    class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter c-letter textCenter m-sideRight1"
                    style="width: 75%"
                  >
                    <div class="h-30 w-100 d-flex alignItemsFEnd justifyCenter">
                      <div class="numberCustom BgBlue c-white">2</div>
                    </div>
                    <div class="h-70 ptp-1">
                      <div v-show="step2">
                        <p class="p-title lh-18 mb-0">
                          <strong>INDENTIFICAR QUÉ TIPO DE EVALUACIÓN</strong>
                        </p>
                        <p class="p-caption lh-14">
                          SE OBTIENE EN MONITOR (MAPEO DE COMPETIDORES)
                        </p>
                      </div>
                      <div v-show="!step2">
                        <p class="p-medium lh-14">
                          El <strong>benchmarking</strong> fomenta
                          <strong>ideas y estrategias</strong>
                          que impulsan el crecimiento de una marca o empresa
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div class="space"></div>
              <a class="w-100 textDecorationNone" @click="stepsClick(8)">
                <div
                  :class="step8Color"
                  class="d-flex rectangleGreatRight positionRelative"
                  v-on:mouseleave="step8 = true"
                  v-on:mouseover="step8 = false"
                >
                  <div
                    class="white rectangleLittleRight"
                    style="width: 25%"
                  ></div>
                  <div
                    class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter c-letter textCenter"
                    style="width: 75%"
                  >
                    <div
                      class="h-30 w-100 d-flex alignItemsFEnd justifyCenter m-sideRight"
                    >
                      <div class="numberCustom BgBlue c-white">8</div>
                    </div>
                    <img
                      class="positionAbsolute imgGraph2"
                      :src="`${urlS3}/grafico-2.webp`"
                    />
                    <div class="ptp-1 h-70">
                      <div class="textCenter m-sideRight1" v-show="step8">
                        <p class="p-title lh-18 mb-0">
                          <strong>PAUTEAR POSTS</strong>
                        </p>
                        <p class="p-caption">(PUBLICIDAD)</p>
                      </div>
                      <div class="m-sideRight1" v-show="!step8">
                        <ul class="p-medium list">
                          <li class="lh-14">Segmentación</li>
                          <li class="lh-14">Presupuesto</li>
                          <li class="lh-14">Programación</li>
                          <li class="lh-14">Meta y Objetivos</li>
                          <li class="lh-14">Contenido/Diseño</li>
                          <li class="lh-14">CM y Tono comunicacional</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FirstGoal",

  data() {
    return {
      urlS3: "https://image-store.isocialcube.com/advise",
      step1: true,
      step2: true,
      step3: true,
      step4: true,
      step5: true,
      step6: true,
      step7: true,
      step8: true,
      step9: true,
      step10: true,
      step1Color: "gray",
      step2Color: "gray",
      step3Color: "gray",
      step4Color: "gray",
      step5Color: "lightBlue",
      step6Color: "lightBlue",
      step7Color: "lightBlue",
      step8Color: "lightBlue",
      step9Color: "purpleGray",
      step10Color: "purpleGray",
      colorParagraph: "c-white",
      colorParagraph2: "c-white",
    };
  },
  methods: {
    stepsClick(id) {
      switch (id) {
        case 1:
          this.step1Color = "colorYellow";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "lightBlue";
          this.step6Color = "lightBlue";
          this.step7Color = "lightBlue";
          this.step8Color = "lightBlue";
          this.step9Color = "purpleGray";
          this.step10Color = "purpleGray";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 2:
          this.step1Color = "gray";
          this.step2Color = "colorYellow";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "lightBlue";
          this.step6Color = "lightBlue";
          this.step7Color = "lightBlue";
          this.step8Color = "lightBlue";
          this.step9Color = "purpleGray";
          this.step10Color = "purpleGray";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 3:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "colorYellow";
          this.step4Color = "gray";
          this.step5Color = "lightBlue";
          this.step6Color = "lightBlue";
          this.step7Color = "lightBlue";
          this.step8Color = "lightBlue";
          this.step9Color = "purpleGray";
          this.step10Color = "purpleGray";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 4:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "colorYellow";
          this.step5Color = "lightBlue";
          this.step6Color = "lightBlue";
          this.step7Color = "lightBlue";
          this.step8Color = "lightBlue";
          this.step9Color = "purpleGray";
          this.step10Color = "purpleGray";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 5:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "colorYellow";
          this.step6Color = "lightBlue";
          this.step7Color = "lightBlue";
          this.step8Color = "lightBlue";
          this.step9Color = "purpleGray";
          this.step10Color = "purpleGray";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 6:
          (this.step1Color = "gray"),
            (this.step2Color = "gray"),
            (this.step3Color = "gray"),
            (this.step4Color = "gray"),
            (this.step5Color = "lightBlue"),
            (this.step6Color = "colorYellow"),
            (this.step7Color = "lightBlue"),
            (this.step8Color = "lightBlue"),
            (this.step9Color = "purpleGray"),
            (this.step10Color = "purpleGray");
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 7:
          (this.step1Color = "gray"),
            (this.step2Color = "gray"),
            (this.step3Color = "gray"),
            (this.step4Color = "gray"),
            (this.step5Color = "lightBlue"),
            (this.step6Color = "lightBlue"),
            (this.step7Color = "colorYellow"),
            (this.step8Color = "lightBlue"),
            (this.step9Color = "purpleGray"),
            (this.step10Color = "purpleGray");
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 8:
          (this.step1Color = "gray"),
            (this.step2Color = "gray"),
            (this.step3Color = "gray"),
            (this.step4Color = "gray"),
            (this.step5Color = "lightBlue"),
            (this.step6Color = "lightBlue"),
            (this.step7Color = "lightBlue"),
            (this.step8Color = "colorYellow"),
            (this.step9Color = "purpleGray"),
            (this.step10Color = "purpleGray");
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          break;
        case 9:
          (this.step1Color = "gray"),
            (this.step2Color = "gray"),
            (this.step3Color = "gray"),
            (this.step4Color = "gray"),
            (this.step5Color = "lightBlue"),
            (this.step6Color = "lightBlue"),
            (this.step7Color = "lightBlue"),
            (this.step8Color = "lightBlue"),
            (this.step9Color = "colorYellow"),
            (this.step10Color = "purpleGray");
          this.colorParagraph = "c-black";
          this.colorParagraph2 = "c-white";
          break;
        case 10:
          (this.step1Color = "gray"),
            (this.step2Color = "gray"),
            (this.step3Color = "gray"),
            (this.step4Color = "gray"),
            (this.step5Color = "lightBlue"),
            (this.step6Color = "lightBlue"),
            (this.step7Color = "lightBlue"),
            (this.step8Color = "lightBlue"),
            (this.step9Color = "purpleGray"),
            (this.step10Color = "colorYellow");
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-black";
          break;
        default:
          break;
      }
      this.$emit("stepsClick", id);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

/*GLOBAL*/
.colorYellow {
  background: #f8bf29;
}

.gray {
  background: #e9eef5;
}

.white {
  background: white;
}

.lightBlue {
  background: #4ec0fc;
}

.purpleGray {
  background: #4d5c77;
}

.BgBlue {
  background: #2c3852;
}

.BgLightBlue {
  background: #d2e8ff;
}

.b-border-t {
  border: transparent !important;
}

.b-backg-t {
  background: white;
  border-width: 3px;
}

.justifyCenter {
  justify-content: center;
}

.justifyEnd {
  justify-content: end;
}

.fDirectionColumn {
  flex-direction: column;
}

.fDirectionRReverse {
  flex-direction: row-reverse;
}

.alignItemsFEnd {
  align-items: flex-end;
}

.alignItemsCenter {
  align-items: center;
}

.p-0 {
  padding: 0rem !important;
}

.p-05 {
  padding: 0.5rem !important;
}

.ptp-1 {
  padding-top: 1rem;
}

.pt-1 {
  padding-top: 1rem !important;
}
.ptb-05 {
  padding: 1.5rem 0rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.p-sides2 {
  padding: 0rem 2rem;
}

.p-sides3 {
  padding-right: 3rem;
  padding-left: 3rem;
}

.p-right2 {
  padding-right: 2rem;
}

.p-right5 {
  padding-right: 5rem;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.positionAbsolute {
  position: absolute;
}

.positionRelative {
  position: relative;
}

.zIndex1 {
  z-index: 1;
}

.zIndex2 {
  z-index: 2 !important;
}

.zIndex3 {
  z-index: 3 !important;
}

.colSides {
  max-width: 16%;
}

.colCenter {
  max-width: 38%;
}

.w-100 {
  width: 100%;
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-45 {
  width: 45%;
}

.w-35 {
  width: 35%;
}

.w-10 {
  width: 10%;
}

.h-25 {
  height: 25%;
}

.h-30 {
  height: 30%;
}

.h-40 {
  height: 40%;
}

.h-50 {
  height: 50%;
}

.h-60 {
  height: 60%;
}

.h-70 {
  height: 70%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.lh-14 {
  line-height: 14px;
}

.lh-16 {
  line-height: 16px;
}

.lh-18 {
  line-height: 18px;
}

.mt-05 {
  margin-top: 0.5rem;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-05 {
  margin-bottom: 0.5px !important;
}

.rectangle {
  height: 8rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.rectangleMedium {
  height: 7rem;
  width: 100%;
  padding: 0rem 2rem;
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 0;
  bottom: 0;
}

.b-r {
  border-right: solid 2px white;
}

.b-l {
  border-left: solid 2px white;
}

.number {
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  position: absolute;
  z-index: 1;
  font-size: 1rem;
  font-weight: bold;
  padding: 0rem 0.5rem;
}

.numberCustom {
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: bold;
  padding: 0rem 0.5rem;
}

.space {
  height: 2rem;
  width: 100%;
}

.containerMap {
  height: 45rem;
  padding: 2rem 3rem;
  justify-content: right;
}

.p-title {
  font-family: "Lato";
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
}

.p-caption {
  font-family: "Lato";
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
}

.p-medium {
  font-family: "Lato";
  font-size: 12px;
  line-height: 14px;
}

.textCenter {
  text-align: center;
}

.textDecorationNone {
  text-decoration: none;
}

.indicativeText {
  font-size: 0.5rem;
  text-align: center;
}

.indicativeText2 {
  font-family: "Lato";
  font-size: 10px;
  line-height: 11px;
  text-align: center;
  padding: 1rem 0rem 0.5rem;
}

.c-black {
  color: black !important;
}

.c-white {
  color: white !important;
}

.c-letter {
  color: #2c3852 !important;
}

.c-title-sub {
  color: #2c3952 !important;
}

.m-sidesCustom1 {
  margin: 0rem 1.5rem 0rem 1rem;
}

.m-sidesCustom2 {
  margin: 0rem 1rem 0rem 1.5rem;
}

.m-sides {
  margin: 0rem 0.5rem;
}

.m-sides1 {
  margin: 0rem 1rem;
}

.m-sideRight1 {
  margin-right: 1rem;
}

.m-sideRight {
  margin-right: 2rem;
}

.p-titleMap {
  padding: 1rem 2rem 1rem 1rem;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 28px;
}

.p-subMap1 {
  font-family: "Lato";
  font-weight: 900;
  font-size: 20px;
}

.p-subMap2 {
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.row1 {
  justify-content: right;
}

.dataContent {
  width: 100%;
}

/*CUSTOM*/
.rectangleGreatRight {
  margin-top: 1rem;
  height: 17rem;
  width: 100%;
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
  align-items: center;
}

.rectangleLittleRight {
  height: 18%;
  width: 40%;
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
}

.rectangleGreatLeft {
  margin-top: 1rem;
  height: 27rem;
  width: 100%;
  border-top-left-radius: 9rem;
  border-bottom-left-radius: 9rem;
  align-items: center;
  justify-content: right;
}

.rectangleLittleLeft {
  height: 48%;
  width: 60%;
  border-top-left-radius: 2rem;
  border-bottom-left-radius: 2rem;
}

.imgPencil {
  position: absolute;
  z-index: 2;
  right: 0%;
  width: 5rem;
}

.imgBarras {
  z-index: 2;
  width: 3.5rem;
  right: 14%;
  top: 19%;
}

.imgStar {
  z-index: 2;
  width: 3.5rem;
  left: 31%;
  top: 39%;
}

.imgGraphic {
  z-index: 2;
  width: 2.5rem;
  top: 48%;
  left: 15%;
}

.imgArrow4 {
  z-index: 2;
  width: 4rem;
  top: 55%;
  left: 15%;
}

.imgPhone {
  z-index: 2;
  width: 5rem;
  bottom: 41%;
  right: 0%;
}

.imgObjective {
  z-index: 2;
  bottom: 5%;
  width: 17%;
}

.imgMegaphone {
  z-index: 3;
  width: 5%;
  left: 21%;
  bottom: 19%;
}

.imgAssessment {
  z-index: 2;
  width: 15%;
  bottom: 18%;
  left: 40%;
}

.imgOffers {
  z-index: 2;
  width: 7%;
  left: 5%;
}

.imgOffers2 {
  z-index: 2;
  bottom: 5%;
  right: 18%;
  width: 2%;
}

.imgYellowGraph {
  z-index: 2;
  width: 2.5%;
  bottom: 38%;
  right: 15%;
}

.imgNodoYellowLPS {
  z-index: 2;
  bottom: 26%;
  right: 15%;
  width: 2%;
}

.imgGraph2 {
  z-index: 2;
  bottom: 14%;
  left: 17%;
  width: 3rem;
}

@media ((max-width: 2900px) and (min-width: 2400px)) {
  .imgAssessment {
    bottom: 19%;
  }
}

@media ((max-width: 2400px) and (min-width: 2201px)) {
  .imgAssessment {
    bottom: 20%;
  }
}

@media (max-width: 2050px) {
  .imgAssessment {
    bottom: 19%;
  }
}

@media (max-width: 1600px) and (min-width: 1300px) {
  .p-title-lps {
    font-size: 24px;
    line-height: 24px;
    margin-left: 2rem;
    padding-top: 0.5rem;
  }
}

@media (max-width: 1300px) and (min-width: 1150px) {
  .p-title-lps {
    font-size: 22px;
    line-height: 20px;
    margin-left: 2rem;
    padding-bottom: 4rem !important;
  }
}

@media (max-width: 1150px) and (min-width: 1024px) {
  .p-title-lps {
    font-size: 22px !important;
    line-height: 20px !important;
    margin-left: 2rem;
    padding-bottom: 4rem !important;
  }
}

@media (max-width: 1440px) {
  .p-title {
    font-size: 13px !important;
    line-height: 15px !important;
  }
  .p-caption {
    font-size: 10px !important;
    line-height: 13px !important;
  }
  .p-medium {
    font-size: 10px !important;
    line-height: 12px !important;
  }
  .p-subMap1 {
    font-size: 18px !important;
  }
  .p-subMap2 {
    font-size: 12px !important;
  }
  /* .p-titleMap {
    padding: 2rem 1rem 1rem 2rem !important;
    font-size: 30px !important;
  } */
  .imgObjective {
    width: 19% !important;
  }
  .indicativeText2 {
    font-size: 8px !important;
    line-height: 9px !important;
  }
  .indicativeText {
    font-size: 8px !important;
    line-height: 9px !important;
  }
}

@media (min-width: 1400px) {
  .numberStep5 {
    height: 20%;
  }

  .listStep5 {
    height: 80%;
  }

  .p-medium-list {
    font-size: 10px;
  }
}

@media (max-width: 1400px) {
  .imgBarras {
    right: 17% !important;
  }

  .imgGraphic {
    left: 18% !important;
  }

  .imgArrow4 {
    left: 17% !important;
  }

  .imgMegaphone {
    left: 24% !important;
  }

  .imgArrow3-socialP {
    margin-left: 12%;
  }

  .imgYellowGraph {
    right: 19% !important;
  }

  .imgOffers2 {
    bottom: 6% !important;
    right: 22% !important;
  }

  .imgNodoYellowLPS {
    right: 19% !important;
  }
}

@media (max-width: 1380px) {
  .imgAssessment {
    bottom: 20%;
  }
}

@media (max-width: 1360px) {
  .imgCellHands {
    top: 37% !important;
  }
}

@media (max-width: 1300px) {
  .p-titleMap {
    font-size: 20px;
    line-height: 20px;
    margin-left: 0rem;
  }
}

@media (max-width: 1280px) {
  .imgCellHands {
    top: 40% !important;
  }

  .imgReloj {
    height: 15% !important;
    right: 27% !important;
    width: 5% !important;
  }
}

@media (max-width: 1040px) {
  .imgCellHands {
    top: 42% !important;
    left: 20% !important;
  }
}

@media (max-width: 1200px) {
  .imgArrow4 {
    left: 14% !important;
  }
  .imgArrow3-socialP {
    margin-left: 5rem !important;
    padding-top: 0.3rem !important;
    width: 3rem !important;
  }
  .imgObjective {
    width: 21% !important;
  }
}

@media (max-width: 1175px) {
  /* .p-titleMap {
    font-size: 16px !important;
    line-height: 18px;
  } */
  .p-subMap1 {
    font-size: 14px !important;
  }
  .p-subMap2 {
    font-size: 10px !important;
  }
}

@media (max-width: 1215px) {
  .p-titleMap {
    font-size: 18px !important;
  }
  .p-subMap1 {
    font-size: 17px !important;
  }
}

@media (max-width: 1100px) {
  .imgObjective {
    width: 23% !important;
  }
  .imgAssessment {
    bottom: 21%;
    left: 40% !important;
  }
}

@media (max-width: 1096px) {
}
</style>
