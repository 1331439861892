<template>
  <v-card v-if="step != null" class="pt-1">
    <div>
      <v-toolbar class="mx-1" color="#F3F4F8" flat>
        <v-toolbar-title class="step_title">
          {{ tab_title }}
        </v-toolbar-title>
      </v-toolbar>
    </div>
    <div class="step_main_content">
      <!-- Step 0 -->
      <v-card class="px-7 step_container" flat v-if="step == 0">
        <v-card-title class="step_subtitle1">
          0. Antes de Empezar
        </v-card-title>
        <v-card-text class="step_subtitle2" style="font-weight: normal">
          Asegúrate de tener lo mínimo. Esta es la lista:
        </v-card-text>
        <v-card-text class="step_subtitle2">
          a) Crea tus cuentas en las Redes Sociales (RRSS) en función a tus
          objetivos y cárgalas en nuestra plataforma.
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step0_1.webp`"
            @click="openImgDialog(`${urlS3}/step0_1.webp`)"
          />
        </div>
        <v-card-text class="step_subtitle2">
          b) Define tu público objetivo (Audiencia).
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>Información demográfica: Género, Edad y Ubicación.</li>
            <br />
            <li>
              Necesidades: Tus potenciales clientes tienen intereses únicos y
              objetivos personalísimos. Debes alinear tu oferta a estos interes
              y adaptarte a sus necesidades para satisfacerlas eficientemente.
            </li>
            <br />
            <li>
              Motivaciones para el consumo y comportamiento: Entender qué
              motivaciones tienen para consumir productos o servicios como los
              tuyos es valioso para posicionarte
            </li>
          </ul>
        </v-card-text>
        <v-card-text class="step_subtitle2">
          c) Usando MONITOR y LISTEN compárate con tu competencia:
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>Analiza su forma de postear.</li>
            <li>
              Analiza sus contenidos y cuánta audiencia tienen (Fans o
              seguidores)
            </li>
            <li>Analiza qué RRSS utilizan.</li>
            <li>Analiza qué palabras clave usan para llegar a la audiencia.</li>
            <li>
              Analiza qué personas influyentes hay en las RRSS alrededor de tu
              sector o actividad (Usando las palabras claves relacionadas a tu
              competencia y su oferta).
            </li>
          </ul>
        </v-card-text>
        <v-card-text class="step_subtitle2">
          d) Establece el alcance de tu Campaña:
          <span style="font-weight: normal">Hasta dónde quieres llegar</span>
        </v-card-text>
      </v-card>
      <!-- Step 1 -->
      <v-card class="px-7 step_container" flat v-if="step == 1">
        <v-card-title class="step_subtitle1">
          <p>1. Identificar qué tipos de evaluaciones puedes obtener de ISC</p>
        </v-card-title>
        <v-card-text class="step_subtitle2">
          a) Las evaluaciones están insertas en los Tableros de Mando de ISC
        </v-card-text>
        <v-card-text class="step_subtitle2" style="font-weight: normal">
          Cada una tiene asociados múltiples indicadores (KPIs) que extraemos
          por tí de las RRSS. Cada evaluación te proporcionará 'insights' a
          través de los KPIs que arroja.
        </v-card-text>
        <v-card-text class="step_subtitle2">
          b) A continuación, te damos algunos ejemplos de Evaluaciones que
          puedes hacer:
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>La escucha de un Contexto.</li>
            <li>
              Monitorear una Campaña de Marketing evaluando las preferencias de
              tu audiencia por industria.
            </li>
            <li>
              Darle un golpe de timón a la Campaña de Marketing evaluando las
              preferencias de tu audiencia por industria.
            </li>
            <li>
              Analizar las tendencias del mercado por industria de las cuentas
              más referentes de las RRSS.
            </li>
            <li>Compararte con tu competencia en múltiples RRSS.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_1.webp`"
            @click="openImgDialog(`${urlS3}/step1_1.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_2.webp`"
            @click="openImgDialog(`${urlS3}/step1_2.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_3.webp`"
            @click="openImgDialog(`${urlS3}/step1_3.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 2 -->
      <v-card class="px-7 step_container" flat v-if="step == 2">
        <v-card-title class="step_subtitle1">
          2. Documenta y describe en detalle los requerimientos de análisis:
        </v-card-title>
        <v-card-text class="step_text step_list"
          ><ul class="indent-ul-1">
            <li>
              Brief de Cliente (Sub-Modulo 'Get Help' de ADVISE) que llenaste en
              GET HELP de ADVISE es un buen punto de partida.
            </li>
            <li>
              Debes hacer una descripción detallada de lo que quieres lograr con
              tu escucha.
            </li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_1AI.png`"
            @click="openImgDialog(`${urlS3}/step2_1AI.png`)"
          />
        </div>
      </v-card>
      <!-- Step 3 -->
      <v-card class="px-7 step_container" flat v-if="step == 3">
        <v-card-title class="step_subtitle1">
          3. Analiza bien que Drivers escucharás.
        </v-card-title>
        <v-card-text class="step_subtitle2">
          a) Los Drivers son la clave de tu escucha.
          <span style="font-weight: normal"
            >Sin ellos no pofrás obtener datos y en consecuencias
            Insights.</span
          >
        </v-card-text>
        <v-card-text class="step_subtitle2 end">
          b) La construcción de tus Drivers debe estar alrededor de las
          preguntas de negocio que deseas responder en tu escucha:
        </v-card-text>
        <v-card-text class="step_subtitle2 start" style="font-weight: normal">
          Has una lista antes de escogerlos. La idea es que los Drivers
          'respondan' tus preguntas.
        </v-card-text>
        <v-card-text class="step_subtitle2 end">
          c) Para Twitter debes analizar 'que' y/o 'quien' genera comentarios
          relevantes a tus preguntas:
        </v-card-text>
        <v-card-text class="step_subtitle2 start" style="font-weight: normal">
          El resultado de la escucha en Twitter es la data asociada a lo que 'se
          dice' o se 'comenta' alrededor de tus Drivers.
        </v-card-text>
        <v-card-text class="step_subtitle2 end">
          d) Para Facebook es óptimo escuchar páginas enteras de empresas que
          generen contenido relevante a tus preguntas de negocio.
        </v-card-text>
        <v-card-text class="step_subtitle2 start" style="font-weight: normal">
          Mientras más interacciones generen con la audiencia mejor para tu
          escucha.
        </v-card-text>
        <v-card-text class="step_subtitle2 end">
          e) Usa ISC Boards, allí nosotros escuchamos a más de 3,000 Drivers en
          18 Industrias de varios países.
        </v-card-text>
        <v-card-text class="step_subtitle2 start" style="font-weight: normal">
          Puedes escoger toda una industria o solo algunos Drivers. Es óptimo
          combinarlos con tus Drivers 'Stand Alone' para pulir el contexto.
        </v-card-text>
        <v-card-text class="step_subtitle2 end">
          f) Cuando escuches analiza la consecuencia de incluir en tu análisis
          todos o parte de los Drivers que estás escuchando
        </v-card-text>
        <v-card-text class="step_subtitle2 start" style="font-weight: normal">
          (Habilitándolos o deshabilitándolos, si los deshabilitas no dejas de
          escuchar ... simplemente los sacas del contexto. Cuando quieras lo
          puedes volver a habilitar).
          <br />
          Los KPIs variarán y eso puede darte otros Insights.
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step3_1AI.png`"
            @click="openImgDialog(`${urlS3}/step3_1AI.png`)"
          />
        </div>
      </v-card>
      <!-- Step 4 -->
      <v-card class="px-7 step_container" flat v-if="step == 4">
        <v-card-title class="step_subtitle1">
          <p>
            4. Escucha las RRSS: Utiliza LISTEN y MONITOR para generar de
            Insights:
          </p>
        </v-card-title>
        <v-card-text class="step_subtitle2">
          a) El 'insight' ¿Cómo lo conseguimos y para que nos sirve?
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              Un 'Insight' es el descubrimiento de información útil imposible de
              identificar a simple vista sin herramientas complementarias.
            </li>
            <li>
              Los Insights deben evaluarse 'en conjunto', cada uno de ellos es
              una pieza dentro del rompecabezas de analítica que te ofrece ISC.
            </li>
            <li>
              Dependiendo de la 'Evaluación' que estés realizando (Ver punto 2)
              se generarán KPIs que originarán 'Insights'. El conjunto de
              'insights' te proporcionarán el diagnóstico de cada Evaluación que
              realices.
            </li>
            <li>
              Un ejemplo de 'Insight' es el sentimiento que genera todo tu
              Contexto en LISTEN. Puedes elaborar aún más el Insight segmentado
              el género (Femenino o Masculino) del contexto y así obtendrás más
              componentes del rompecabezas de analítica.
            </li>
          </ul>
        </v-card-text>
        <v-card-text class="step_subtitle2">
          b) Configura tu escucha.
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>LISTEN:</li>
            <p>
              - Analiza los 'Boards de ISC' con énfasis los CSA (Cluster Social
              Analytics). Esto te ayuda a escoger Drivers que generen tráfico.
              <br />
              - Define y configura los Drivers a escuchar.
              <br />
              - Define que 'Boards de ISC' son cercanos al objetivo para
              complementar tu escucha.
              <br />
              - Define que industrias de Geo-Listening aportan a la escucha.
            </p>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_4.webp`"
            @click="openImgDialog(`${urlS3}/step1_4.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>MONITOR:</li>
            <p>
              - Configura el sub-módulo 'Benchmark' para obtener inteligencia
              competitiva de la competencia.
              <br />
              - Analiza el submódulo 'Trends' para evaluar las tendencias de
              posteo de la industria.
              <br />
              - Evalúa el sub-módulo 'Overview' para establecer metas tangibles
              basadas en tu situación actual.
              <br />
              - Analiza 'Intereses de Audiencia' en el sub-módulo 'Campaigns'
              para complementar los Insights de la escucha.
            </p>
          </ul>
        </v-card-text>
        <v-card-text class="step_text step_list indent-2 start">
          <ul>
            <li><b>Facebook:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_3.webp`"
            @click="openImgDialog(`${urlS3}/step2_3.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list indent-2">
          <ul>
            <li><b>Instagram:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_1.webp`"
            @click="openImgDialog(`${urlS3}/step2_1.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list indent-2">
          <ul>
            <li><b>Twitter:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_2.webp`"
            @click="openImgDialog(`${urlS3}/step2_2.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 5 -->
      <v-card class="px-7 step_container" flat v-if="step == 5">
        <v-card-title class="step_subtitle1">
          <p>5. ¿Qué preguntas puedes responder con tu escucha?</p>
        </v-card-title>
        <v-card-text class="step_text">
          La clave del éxito en RRSS es ser constante, debes hacer escuchas
          periódicamente para una mejora continua: el escenario cambiar
          permanentemente. Estas son algunas de las cosas que podrías responder
          con tu escucha:
        </v-card-text>
        <v-card-text class="step_text step_list start"
          ><ul>
            <li>
              ¿Qué siente el mercado alrededor de mi industria/ marca/ producto/
              servicio?
            </li>
            <li>
              ¿Cuál es el contenido más popular y cômo puede ayudarme a mejorar
              o generar más ingresos y conversiones?
            </li>
            <li>
              ¿Qué dice el mercado alrededor de mi industria/ marca/ producto/
              servicio?
            </li>
            <li>
              ¿Cuáles son los llamados de atención (CTA) u otras acciones más
              efectivas? ¿Cuáles son las mejores y por qué?
            </li>
            <li>
              ¿Cuáles son las tendencias de contenido que genera mi Contexto?
            </li>
            <li>
              ¿Qué influencers son detractores y/o impulsadores en mi industria/
              marca/ producto/ servicio en el mercado?
            </li>
            <li>
              ¿Qué hablan los influencers en mi mercado y cómo se relacionan
              entre si sus testimonios?
            </li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_ultratiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/advise_bulb.webp`"
            @click="openImgDialog(`${urlS3}/advise_bulb.webp`, true)"
          />
        </div>
      </v-card>
      <!-- Step 6 -->
      <v-card class="px-7 step_container" flat v-if="step == 6">
        <v-card-title class="step_subtitle1">
          <p>6. ¡CRUZA INSIGHTS, GENERA DIAGNÓSTICOS Y AFINA ESCUCHAS!</p>
        </v-card-title>
        <v-card-text class="step_text">
          a) ¡Escucha y genera insights!
        </v-card-text>
        <v-card-text class="step_text">
          b) Desarrolla tu diagnóstico cruzando e interpretando los insights que
          se relacionen a tu Evaluación.
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step6_1AI.png`"
            @click="openImgDialog(`${urlS3}/step6_1AI.png`)"
          />
        </div>
      </v-card>

      <v-dialog v-model="imgDialog" width="1200">
        <v-card class="justify-end modal-card">
          <v-row no-gutters>
            <v-col
              cols="12"
              style="padding: 2px !important"
              class="d-flex justify-end align-end"
            >
              <svg
                class="d-flex justify-center"
                style="
                  width: 3rem;
                  height: 3rem;
                  cursor: pointer;
                  padding: 5px;
                  margin: 3px;
                "
                viewBox="0 0 24 24"
                @click="imgDialog = false"
              >
                <path
                  fill="currentColor"
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                />
              </svg>
            </v-col>
            <v-col
              cols="12"
              style="padding: 0px !important"
              align="center"
              justify="center"
            >
              <v-img
                contain
                class="mb-12 ml-12 mr-12"
                :class="imgUltraTiny ? 'step_img_ultratiny_modal' : ''"
                :src="imgSelected"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
/* ESTE COMPONENTE SERVIRÁ PARA TODOS LOS OBJETIVOS DE LA META 'ANALISIS DE ENTORNO' */
export default {
  name: "AnalisisMercado",
  props: ["step", "tab_title"],
  data() {
    return {
      urlS3: "https://image-store.isocialcube.com/advise",
      urlS3_2: "https://isc-store.s3.amazonaws.com/advise/Roadmap+Information",
      imgDialog: false,
      imgSelected: null,
      imgUltraTiny: false,
    };
  },
  methods: {
    openImgDialog(img, ultratiny = false) {
      if (this.imgDialog == false) {
        this.imgDialog = true;
        this.imgSelected = img;
        ultratiny == true
          ? (this.imgUltraTiny = true)
          : (this.imgUltraTiny = false);
      }
    },
    closeImgDialog() {
      if (this.imgDialog == true) {
        this.imgDialog = false;
        this.imgSelected = null;
        this.imgUltraTiny = false;
      }
    },
  },
};
</script>

<style src="../goalsDefinition.css" scoped />
