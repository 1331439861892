<template>
  <v-card v-if="step != null" class="pt-1">
    <div>
      <v-toolbar class="mx-1" color="#F3F4F8" flat>
        <v-toolbar-title class="step_title">
          {{ tab_title }}
        </v-toolbar-title>
      </v-toolbar>
    </div>
    <div class="step_main_content">
      <!-- Step 0 -->
      <v-card class="px-7 step_container" flat v-if="step == 0">
        <v-card-title class="step_subtitle1">
          0. Antes de Empezar
        </v-card-title>
        <span class="step_subtitle2" style="font-weight: normal">
          Es necesario definir los siguientes puntos antes de realizar un
          análisis y plantear una estrategia.
        </span>
        <v-card-text class="step_text"
          ><span>
            <b
              >1. Mapear las RRSS actuales e ingresarlas a ISC (Si no posee
              ninguna saltar este paso):</b
            >
          </span></v-card-text
        >
        <div class="step_text step_list indent-0">
          <ul>
            <li>Facebook.</li>
            <li>Twitter.</li>
            <li>Instagram.</li>
            <li>LinkedIn.</li>
            <li>Otro.</li>
          </ul>
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step0_1.webp`"
            @click="openImgDialog(`${urlS3}/step0_1.webp`)"
          />
        </div>
        <v-card-text class="step_text"
          ><span><b>2. Definir Características del Público Objetivo:</b></span>
        </v-card-text>
        <span class="step_text step_list"
          ><ul>
            <li>Género.</li>
            <li>Edad.</li>
            <li>Ubicación.</li>
            <li>Necesidades.</li>
            <li>Motivaciones.</li>
            <li>Hábitos y hobbies.</li>
            <li>NSE (Nivel Socio Económico).</li>
            <li>Hábitos en Social Media.</li>
          </ul>
        </span>
        <v-card-text class="step_text"
          ><span><b>3. Definir objetivos específicos:</b></span>
        </v-card-text>
        <v-card-text class="step_text start"
          ><p>
            Se sugiere definir los objetivos bajo estos criterios de objetivos
            <b>SMART</b>
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li><b>Specific (específico)</b></li>
            <span
              >Aspecto, tarea o acción determinada que dice exactamente lo que
              se espera lograr.</span
            >
            <br />
            <br />
            <li><b>Mesurable (medible)</b></li>
            <span
              >Los objetivos tienen que ser medibles, de lo contrario no es
              posible interpretar si los resultados están dentro de lo
              esperado.</span
            >
            <br />
            <br />
            <li><b>Achievable (alcanzable)</b></li>
            <span
              >Los objetivos debes ser realizables. Es importante plantearse
              metas realistas, sobre la base de su cumplimiento se puede - luego
              - apuntar más alto.</span
            >
            <br />
            <br />
            <li><b>Relevant (relevante)</b></li>
            <span
              >Los objetivos deben estar en línea con los objetivos generales
              del negocio.</span
            >
            <br />
            <br />
            <li><b>Timely (temporal)</b></li>
            <span
              >Los objetivos deben estar limitados a un tiempo determinado.
              Todas las características dependen del tiempo en que deben ser
              completados.</span
            >
          </ul>
        </v-card-text>
        <v-card-text class="step_text"
          ><span><b>4. Realizar Benchmarking:</b></span>
        </v-card-text>
        <v-card-text class="step_text start"
          ><p>
            ¿Quiénes son los competidores directos e indirectos? ¿Cuáles son sus
            RRSS?
          </p>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step0_2.webp`"
            @click="openImgDialog(`${urlS3}/step0_2.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 1 -->
      <v-card class="px-7 step_container" flat v-if="step == 1">
        <v-card-title class="step_subtitle1">
          <p>
            1. Identificar el tipo de evaluaciones que se obtienen desde LISTEN
            en Twitter y Facebook
          </p>
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            Es necesario conocer el panorama en el sector o industria desde las
            redes sociales, de esta manera la empresa puede aprovechar
            oportunidades y evitar amenazas identificadas en LISTEN. Además, a
            partir de este análisis se pueden conocer Insights que son usados
            estratégicamente para realizar una campaña de lanzamiento exitosa.
            LISTEN escucha a diversas industrias con 'ISC Boards' y geolocaliza
            aquellos Drivers que tienen datos en 'GEO Listening'. Puedes
            seleccionar Drivers de esas escuchas para completar la tuya y/o usar
            los diagnósticos de cada industria directamente. La idea es que
            respondas:
          </p>
        </v-card-text>
        <span class="step_text step_list"
          ><ul>
            <li>
              ¿Cuáles son los DRIVERS que se usaron en la investigación de la
              industria?
            </li>
            <li>
              ¿Cuáles son los KEYWORDS en tendencia? Se utilizan en los captions
              que acompañan las piezas gráficas.
            </li>
            <li>
              ¿Cuál es género que interactúa más con la industria o drivers
              seleccionados? Ayuda a la segmentación y a dirigirse de mejor
              manera en sus videos o posts.
            </li>
            <li>
              ¿Conocer cuáles son los trending topics o hashtags más usados que
              se pueden usar estratégicamente?
            </li>
            <li>
              ¿Cuáles son los trending topics o hashtags más usados que se
              pueden usar estratégicamente en el diseño de piezas gráficas o
              captions?
            </li>
            <li>¿Cuáles son los posts destacados?</li>
            <li>
              ¿Qué tipo de imágenes comunicar? Según los sentimientos analizados
              en LISTEN.
            </li>
          </ul>
        </span>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_1.webp`"
            @click="openImgDialog(`${urlS3}/step1_1.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_2.webp`"
            @click="openImgDialog(`${urlS3}/step1_2.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_3.webp`"
            @click="openImgDialog(`${urlS3}/step1_3.webp`)"
          />
        </div>
        <v-card-text class="step_text">
          <p>
            Además, se pueden crear contextos personalizados seleccionando
            Drivers 'Stand Alone' que complementen el análisis para obtener
            Insights. Estos últimos se utilizan en la planificación estratégica
            de una campaña de lanzamiento exitosa. Para ello, se recomienda
            tener en cuenta:
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              <b>Analizar los 'ISC Boards'</b> con énfasis en los CSA (Cluster
              Social Analytics). Esto te ayuda a escoger Drivers que generan
              tráfico.
            </li>
            <li><b>Definir y configurar</b> los Drivers para escuchar.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_4.webp`"
            @click="openImgDialog(`${urlS3}/step1_4.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 2 -->
      <v-card class="px-7 step_container" flat v-if="step == 2">
        <v-card-title class="step_subtitle1">
          <p>
            2. Identificar el tipo de evaluaciones que se obtienen desde MONITOR
            a través del mapeo de competidores
          </p>
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            Realizar un benchmarking de los competidores permite a la empresa
            elevar sus estándares de competitividad y liderazgo en el mercado,
            detectando las falencias y debilidades ante los competidores
            directos o indirectos. Además, fomenta la creación de nuevas ideas y
            estrategias que impulsan el crecimiento de una marca o empresa.
          </p>
        </v-card-text>
        <v-card-text class="step_text">
          <p>
            Te recomendamos responder a las siguientes preguntas y analizar
            detalladamente los cuadros de MONITOR en cada Red Social (Con las
            respuestas podras alistar las observaciones que resalten en cada
            competidor):
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>¿Cúales son los posts destacados?</li>
            <li>¿Qué imagenes se muestran con mayor frecuencia?</li>
            <li>¿Cuáles son los colores predominantes?</li>
            <li>¿Todos los posts llevan el logo de la marca?</li>
            <li>¿Cuál es la línea gráfica de los competidores?</li>
            <li>¿Cuáles son sus colores característicos?</li>
            <li>¿Qué tipo de tipografía utilizan?</li>
            <li>¿Qué tipo de tamaño de post utilizan para cada tipo?</li>
            <li>
              ¿Qué tipo de elementos visuales utilizan? ¿Vectores o texturas?
            </li>
            <li>¿Cuál es el tipo de distribución de espacios gráficos?</li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Facebook:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_3.webp`"
            @click="openImgDialog(`${urlS3}/step2_3.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Instagram:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_1.webp`"
            @click="openImgDialog(`${urlS3}/step2_1.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Twitter:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_2.webp`"
            @click="openImgDialog(`${urlS3}/step2_2.webp`)"
          />
        </div>
        <v-card-text class="step_text">
          <p>
            Por otro lado, es esencial saber cual es la posición de nuestra
            empresa antes de realizar cualquier acción, para ello usamos el
            submódulo 'Overview' en MONITOR.
          </p>
        </v-card-text>
        <v-card-text class="step_text">
          <p>
            Es momento de realizar una autoevaluación que permitirá tener un
            conocimiento más preciso acerca de las piezas gráficas de marketing.
            Para ello, se identifican las características que tienen
            respondiendo las siguientes preguntas:
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              ¿Cuál es el formato (imágenes o videos) de post que tienen más
              interacciones?
            </li>
            <li>¿Cuál es la línea gráfica que se utiliza en el perfil?</li>
            <li>¿Cuáles son los temas que obtienen más interacciones?</li>
            <li>¿Cuál es la paleta de colores de la marca?</li>
            <li>¿Qué tipo de tipografía utiliza?</li>
            <li>¿Qué tipo de contenido publica con mayor frecuencia?</li>
            <li>¿Cuál es la hora más frecuente en la que se publica?</li>
            <li>¿Tiene alineación para los textos?</li>
            <li>¿Qué tipo de fondos utiliza? (claros, suaves, oscuros)</li>
            <li>¿Utiliza 'Call to Actions' en las imágenes?</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/monitor_overview_accounts.webp`"
            @click="openImgDialog(`${urlS3}/monitor_overview_accounts.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/monitor_market_trends.webp`"
            @click="openImgDialog(`${urlS3}/monitor_market_trends.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 3 -->
      <v-card class="px-7 step_container" flat v-if="step == 3">
        <v-card-title class="step_subtitle1">
          3. Analizar los diagnósticos recogidos en LISTEN y MONITOR para
          convertirlos en Insights e ítems de acción
        </v-card-title>
        <v-card-text class="step_text">
          <p class="indent-0">
            Los insights surgen luego de una indagación a profundidad de los
            aspectos del consumidor que se ven reflejados en LISTEN y MONITOR.
            <b>¡El insight quiere ser revelado!</b>
            <br />
            <br />
            Existen:
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              Insights que alimentan nuevos conceptos de producto basados en
              necesidades y demandas insatistechas, ocultas, reveladoras.
            </li>
            <li>
              Insights que alimentan estrategias de posicionamiento o
              reposicionamiento de los productos.
            </li>
            <li>
              Insights que orientan la comunicación publicitaria de forma tal de
              obtener una ruta efectiva para atraer, retener y/o fidelizar al
              consumidor.
            </li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text">
          <p class="indent-0">¡Y muchos más!</p>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step3_1.webp`"
            @click="openImgDialog(`${urlS3}/step3_1.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 4 -->
      <v-card class="px-7 step_container" flat v-if="step == 4">
        <v-card-title class="step_subtitle1">
          4. Plantear una estrategia de posicionamiento
        </v-card-title>
        <span class="step_subtitle2"
          >Reconocer y definir el diferencial de la marca o empresa:
        </span>
        <v-card-text class="step_text start"
          ><p class="indent-0">
            ¿Cuál es la fortaleza de la empresa? ¿Cómo se diferencia de la
            competencia?
          </p></v-card-text
        >
        <span class="step_subtitle2"
          >Definir la estrategia de posicionamiento en base al diferencial:
        </span>
        <v-card-text class="step_text start"
          ><p class="indent-0">
            Tomar en cuenta el siguiente cuadro que explica la funcionalidad de
            cada estrategia ligada al objetivo establecido de diseño de piezas
            de marketing:
          </p></v-card-text
        >
        <v-card-text class="step_table" align="center" justify="center">
          <div class="container responsive" style="width: 60%">
            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col cols="6">
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>Estrategias de Posicionamiento</b></v-card-text
                  >
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>Acciones en RR.SS</b></v-card-text
                  >
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Según el estilo de vida</b></v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Se basa en los intereses, la forma de vida, los valores,
                      la actitud y el comportamiento de los consumidores.
                      Ejemplo: Marca de cosméticos veganos</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >Mostrar el producto en la rutina diaria del público
                    objetivo.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Basada en el usuario</b></v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Está enfocada a un perfil, donde se identifican sus
                      gustos, pasiones o preferencias. Ejemplo: Messi es imagen
                      de una marca de zapatillas de fútbol</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >El uso de Influencers como imagen de marca hace más
                    sencillo comunicar el posicionamiento a las personas que se
                    sientan identificadas o que aspiren a ser como el
                    Influencer.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Basada en el uso o aplicación del producto</b>
                    </v-card-text>
                    <v-card-text class="text-center start"
                      >Destaca la finalidad de un producto. Ejemplo: Cremas
                      rejuvenecedoras.</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >Mostrar claramente el objetivo que tiene el producto.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Basada en la calidad o precio</b></v-card-text
                    >
                    <v-card-text class="text-center start end"
                      >Una calidad superior a las demás marcas del mercado.
                      Ejemplo: Apple</v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Asocia la marca a un precio muy competitivo o un precio
                      muy elevado, que habitualmente este vinculado a la
                      exclusividad o lujo. Ejemplo: Supreme.</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >El uso de Influencers como imagen de marca hace más
                    sencillo comunicar el posicionamiento a las personas que se
                    sientan identificadas o que aspiren a ser como el
                    Influencer.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Frente a la competencia</b></v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Explota las ventajas competitivas y los atributos de la
                      marca, comparándolas con las marcas competidoras. Ejemplo:
                      Oral B coloca anuncios donde se compara con otros
                      dentífricos.</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >Las marcas que basan su estrategia en un solo atributo,
                    pueden fortalecer su imagen en la mente del consumidor con
                    mayor facilidad que las que intentan basar su
                    posicionamiento en varios atributos.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Basada en beneficios</b></v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Basarse en el bien que el producto o servicio le causa a
                      los clientes. Ejemplo: Ariel es capaz de eliminar las
                      manchas difíciles y los malos olores.</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >No se trata solo del producto sino de la experiencia de
                    compra y la posventa.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <span class="step_subtitle2">Define las RRSS que utilizarás:</span>
        <v-card-text class="step_text start"
          ><p class="indent-0">
            Es necesario estar presentes en las RRSS donde se encuentra nuestro
            püblico objetivo. No es necesario tener presencia en todas, cada
            plataforma requiere de una estructura de contenidos acorde a sus
            características:
          </p></v-card-text
        >
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              <b>Facebook:</b> Red social con mayor cantidad de usuarios hasta
              el momento y con un rango de edad de consumo bastante amplio. Los
              usuarios se conectan a cualquier hora del dia desde cualquier
              dispositivo, demanda contenidos de alta interacción y con sentido
              de generación de comunidad.
            </li>
            <li>
              <b>Twitter:</b> En esta red todo pasa muy rápido, un tweet tiene
              duración corta. Se utiliza mayormente desde el teléfono móvil.
              Demanda contenidos de alto impacto y atomizados para generar
              interacciones con la comunidad.
            </li>
            <li>
              <b>Instagram:</b> Red muy visual, fácil de consumir y popular. El
              principal contenido son las imágenes, pero los videos cortos
              vienen tomando protagonismo. Priman las audiencias por debajo de
              los 30 años y se premian los contenidos auténticos exponiéndose al
              público potencial de manera orgánica para un mayor alcance.
            </li>
            <li>
              <b>Youtube:</b> Priman los videos como contenido principal para
              fidelizar seguidores y crear una comunidad, trabajando de la mano
              con la cantidad de likes, número de visitas y número de
              suscriptores.
            </li>
            <li>
              <b>Linkedin:</b> Es la Red profesional más grande del mundo,
              orientada a generar conexiones laborales y empresariales. Prima la
              búsqueda de empleo, contratar profesionales, potenciar
              emprendimientos y contactar clientes, además esta plataforma te
              permite estar informado de las últimas novedades de la industria o
              sector.
            </li>
          </ul>
          <div class="step_img_card">
            <img
              class="step_img mx-auto d-flex justify-center align-center mt-5"
              :src="`${urlS3}/step4_2.webp`"
              @click="openImgDialog(`${urlS3}/step4_2.webp`)"
            />
          </div>
        </v-card-text>
      </v-card>
      <!-- Step 5 -->
      <v-card class="px-7 step_container" flat v-if="step == 5">
        <v-card-title class="step_subtitle1">
          5. Definir el contenido de las publicaciones para ejecutar la
          estrategia
        </v-card-title>
        <v-card-text class="step_text step_list"
          ><ul class="indent-1">
            <li>
              <b> Usar conexiones directas</b> (contactos, empleados, clientes y
              familiares) primero para viralizar el contenido orgánicamente.
              Recibir feedback y corregir para luego aplicar publicidad.
            </li>
            <li>
              La estrategia debe aterrizar en un
              <b>Plan de Piezas de Marketing</b>
              con descripciones detalladas con énfasis en los mensajes
              (visuales, escritos y descriptivos) que se desea enviar a la
              audiencia.
            </li>
            <li>
              Las publicaciones deben estar
              <b>compuestas por 2 insumos</b> indispensables:
              <b>Los 'Temas'</b>, que se refieren a los asuntos que se desean
              comunicar y <b>los 'Formatos'</b> que se refieren a la forma en la
              que se comunicará la marca con su comunidad. A continuación, se
              muestran ejemplos:
            </li>
          </ul>
        </v-card-text>
        <v-card-text
          style="width: 100%"
          class="d-flex align-center justify-center"
        >
          <div class="d-flex justify-center align-center" style="width: 80%">
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="justify-center align-center"
            >
              <v-col cols="12" class="customCol"
                ><v-card
                  outlined
                  tile
                  class="colCard colHeader d-flex justify-center align-center"
                  ><b>Formatos</b></v-card
                >
              </v-col>
              <v-col class="customCol" order="1">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Videos</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_1.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="2">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Imágenes</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_2.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="3">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Infografías</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_3.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="4">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Historias</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 40px"
                        :src="`${urlS3}/step5_4.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="5">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Addons</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_5.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text
          style="width: 100%"
          class="d-flex align-center justify-center"
        >
          <div class="d-flex justify-center align-center" style="width: 70%">
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="justify-center align-center"
            >
              <v-col cols="12" class="customCol"
                ><v-card
                  outlined
                  tile
                  class="colCard colHeader d-flex justify-center align-center"
                  ><b>Temas</b></v-card
                >
              </v-col>
              <v-col class="customCol" order="1">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Cuidados del producto</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
              <v-col class="customCol" order="2">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Modos de uso</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
              <v-col class="customCol" order="3">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Ejemplos de uso de un producto</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step5_6.webp`"
            @click="openImgDialog(`${urlS3}/step5_6.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 6 -->
      <v-card class="px-7 step_container" flat v-if="step == 6">
        <v-card-title class="step_subtitle1">
          6. ¿Cómo elaborar el contenido correcto?
        </v-card-title>
        <v-card-title class="step_text">
          <p class="indent-0">
            Para comunicar un mensaje se debe escoger el contenido correcto
            utilizando herramientas que permitan transmitir ese mensaje final al
            cliente. Se recomiendo elaborar el post siguiendo estos pasos:
          </p>
        </v-card-title>
        <v-card-title class="step_text">
          <p class="indent-0">
            <b>a) Imagen:</b> DESIGN permite seleccionar o cargar imágenes que
            se necesitan para la elaboración del post. Ten en cuenta que la
            imagen seleccionada debe transmitir el contenido y tema escogido en
            la pieza gráfica.
          </p>
        </v-card-title>
        <div class="step_img_card">
          <img
            class="step_img_ultratiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step6_1DPM.webp`"
            @click="openImgDialog(`${urlS3}/step6_1DPM.webp`, true)"
          />
        </div>
        <v-card-title class="step_text">
          <p class="indent-0">
            <b>b) Fuente:</b> La tipografía es uno de los pilares más
            importantes del diseño, por ello DESIGN te permite seleccionar el
            tipo de fuente que vaya acorde al tema que se vaya a comunicar.
          </p>
          <br />
          <p class="indent-0">
            Una imagen con un texto acorde puede ser la diferencia para tomar
            una decisión. Considerar que los colores de la imagen contrasten con
            el color de la letra, para evitar que se pierda el mensaje dentro de
            la imagen.
          </p>
        </v-card-title>
        <div class="step_img_card">
          <img
            class="step_img_ultratiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step6_2DPM.webp`"
            @click="openImgDialog(`${urlS3}/step6_2DPM.webp`, true)"
          />
        </div>
        <v-card-title class="step_text">
          <p class="indent-0">
            <b>c) Colores:</b> Toma en cuenta la paleta de colores de la marca
            para la elaboración de contenido, esto te ayudará a tener una
            recordación y distinción de marca con los demás post.
          </p>
        </v-card-title>
        <div class="step_img_card">
          <img
            class="step_img_ultrasmall mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step6_3DPM.png`"
            @click="openImgDialog(`${urlS3}/step6_3DPM.png`, true)"
          />
        </div>
        <v-card-title class="step_text">
          <p class="indent-0">
            <b>d) Estructura de contenido:</b> Después de seleccionar la imagen,
            el tipo de fuente y colores, los posts deben tener una estructura
            (para elaborarlos de manera ordenada). Debes decidir si se colocará
            el logo de la marca dentro del post o solo para publicaciones
            eventuales como promociones o concursos.
          </p>
          <br />
          <p class="indent-0">
            También debes decidir si la imagen estará acompañada con iconos,
            formas, fondos o se va a trabajar de manera minimalista utilizando
            solo una imagen.
          </p>
        </v-card-title>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step6_4DPM.webp`"
            @click="openImgDialog(`${urlS3}/step6_4DPM.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 7 -->
      <v-card class="px-7 step_container" flat v-if="step == 7">
        <v-card-title class="step_subtitle1">
          7. Definir las piezas de Marketing y planificar su despliegue
        </v-card-title>
        <v-card-text class="step_text"
          ><p class="indent-0">
            Se recomienda realizar las acciones indicadas según el cronograma,
            es importante tener constancia dentro de las redes sociales para no
            dejar de tener presencia en ellas y transmitir que la marca continúa
            operando con normalidad. No olvides que el contenido que se
            comunique debe entretener o interesarle a la audiencia más no
            abrumarla, por eso a través de ISC podrás realizar actividades que
            te permitirán escuchar, monitorear y analizar el resultado de cada
            una de las acciones implementadas.
          </p></v-card-text
        >
        <v-card-text class="step_text step_list start"
          ><ul class="indent-1">
            <li>Comunicado de Rebranding "NOS RENOVAMOS".</li>
            <li>Hora.</li>
            <li>
              Tipo de post (Imágenes - videos - tips - reviews - citas o frases
              - infografías - descuentos - ofertas - concursos - live - gif -
              tutoriales).
            </li>
            <li>Calendarización.</li>
          </ul>
        </v-card-text>
        <v-card-text class="step_table7">
          <div class="container mx-3" style="width: 100%">
            <v-row no-gutters>
              <v-col class="colHeader">
                <v-card
                  outlined
                  tile
                  class="header d-flex align-center justify-center"
                  height="70px"
                >
                  <v-card-text class="d-flex align-center justify-center header"
                    ><b>Mayo</b></v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col
                v-for="(header, index) in [
                  'Domingo',
                  'Lunes',
                  'Martes',
                  'Miércoles',
                  'Jueves',
                  'Viernes',
                  'Sábado',
                ]"
                :key="index"
                class="colSubHeader"
              >
                <v-card outlined tile class="subheader">
                  <v-card-text class="text-center">{{ header }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">01</p>
                    <span class="body_title marked1">Inicio de </span>
                    <span class="body_title marked1">publicaciones:</span>
                    <br />
                    <p class="body_text">Post en todas las RR.SS</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">02</p>
                    <span class="body_title">Historia:</span>
                    <br />
                    <p class="body_text">Instagram y Facebook</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">03</p>
                    <span class="body_title">Historia:</span>
                    <br />
                    <p class="body_text">Instagram y Facebook</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">04</p>
                    <span class="body_title">Video:</span>
                    <br />
                    <p class="body_text">Instagram y Facebook</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">05</p>
                    <span class="body_title">Historia:</span>
                    <br />
                    <p class="body_text">Instagram y Facebook</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">06</p>
                    <span class="body_title">Post en todas las RR.SS:</span>
                    <br />
                    <p class="body_text">No mencionar el producto</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">07</p>
                    <span class="body_title">Historia:</span>
                    <br />
                    <p class="body_text">Instagram y Facebook</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">08</p>
                    <span class="body_title marked2">Lanzamiento </span><br />
                    <span class="body_title marked2">18h:</span>
                    <br />
                    <p class="body_text">
                      Post + historias en todas las RR.SS (Mostrar producto)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">09</p>
                    <span class="body_title">Post:</span>
                    <br />
                    <p class="body_text">
                      Instagram y Facebook (Producto + imagen)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">10</p>
                    <span class="body_title">Post:</span>
                    <br />
                    <p class="body_text">
                      Instagram y Facebook (Producto + imagen)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">11</p>
                    <span class="body_title">Post:</span>
                    <br />
                    <p class="body_text">
                      Instagram y Facebook (Producto + imagen)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">12</p>
                    <span class="body_title">Post:</span>
                    <br />
                    <p class="body_text">
                      Instagram y Facebook (Beneficios + infografía)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">13</p>
                    <span class="body_title">Video:</span>
                    <br />
                    <p class="body_text">
                      Instagram y Facebook (Consejos de decoración + video)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody fixed">
                <v-card outlined tile class="body fixed d-flex justify-center">
                  <v-card-text>
                    <p class="body_day">14</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <!-- Step 8 -->
      <v-card class="px-7 step_container" flat v-if="step == 8">
        <v-card-title class="step_subtitle1">
          8. Establecer ofertas y/o promociones diferentes al canal de ventas
          tradicional
        </v-card-title>
        <v-card-text class="step_text">
          <p class="indent-0">
            Realizar promociones u ofertas puede dinamizar las redes sociales de
            las marcas; Sin embargo, es importante que sean diferentes a las que
            existen en las tiendas físicas o canales tradicionales para lograr
            el objetivo. Analiza el siguiente cuadro a continuación:
          </p>
        </v-card-text>
        <v-card-text class="step_table">
          <div class="container responsive" style="width: 60%">
            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col
                cols="4"
                v-for="(title, index) in tables.step_8.headers"
                :key="index"
              >
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>{{ title }}</b></v-card-text
                  >
                </v-card>
              </v-col>

              <v-col
                cols="4"
                v-for="(body, index) in tables.step_8.bodies"
                :key="index"
              >
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>{{ body }}</b></v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <!-- Step 9 -->
      <v-card class="px-7 step_container" flat v-if="step == 9">
        <v-card-title class="step_subtitle1">
          9. Escuchar las Redes Sociales a través de LISTEN y MONITOR para
          generar retroalimentación
        </v-card-title>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p>
            Es recomendable revisar constantemente los indicadores de las
            campañas en curso, de esta manera se puede evaluar si se están
            cumpliendo los objetivos planteados o realizar modificaciones para
            lograr mejores resultados. Además, es esencial conocer las novedades
            de la industria o sector para aprovechar nuevas oportunidades.
          </p>
        </v-card-text>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p>
            Te recomendamos evaluar constantemente la industria y a tus
            competidores para mejorar tus posteos y retroalimentar tus campañas.
            Puedes realizar estas acciones desde los submódulos de
            <b>LISTEN</b>, donde podrás escuchar todos los Drivers previamente
            definidos o cambiarlos según tu conveniencia, y en <b>MONITOR</b>,
            donde podrás mapear constantemente a tus competidores y estar al
            tanto de las novedades en el mercado.
          </p>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step9_1.webp`"
            @click="openImgDialog(`${urlS3}/step9_1.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step9_2.webp`"
            @click="openImgDialog(`${urlS3}/step9_2.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 10 -->
      <v-card class="px-7 step_container" flat v-if="step == 10">
        <v-card-title class="step_subtitle1">
          10. ¡Ejecutar, monitorear y escuchar!
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            <b>¡El ciclo se vuelve a repetir!</b> Para lograr un verdadero éxito
            en las redes sociales la clave es <b>ser constante.</b>
            Ejecuta evaluaciones periódicas a las campañas que realices para
            mejorar continuamente.
          </p>
        </v-card-text>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p>Se pueden responder las siguientes preguntas:</p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>¿Cuál ha sido la mejor campaña?</li>
            <li>
              ¿Cuántos seguidores se han obtenido a partir de la nueva línea
              gráfica?
            </li>
            <li>
              ¿Cuál fue el post que tuvo mejores comentarios y likes? (Según el
              tema escogido a comunicar)
            </li>
            <li>
              ¿El feed de la cuenta de Instagram se ve de manera más ordenada?
            </li>
            <li>
              ¿Mejoró la interacción en comparación a la nueva línea gráfica
              comunicada?
            </li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step10_1DPM.webp`"
            @click="openImgDialog(`${urlS3}/step10_1DPM.webp`)"
          />
        </div>
      </v-card>

      <v-dialog v-model="imgDialog" width="1200">
        <v-card class="justify-end modal-card">
          <v-row no-gutters>
            <v-col
              cols="12"
              style="padding: 2px !important"
              class="d-flex justify-end align-end"
            >
              <svg
                class="d-flex justify-center"
                style="
                  width: 3rem;
                  height: 3rem;
                  cursor: pointer;
                  padding: 5px;
                  margin: 3px;
                "
                viewBox="0 0 24 24"
                @click="imgDialog = false"
              >
                <path
                  fill="currentColor"
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                />
              </svg>
            </v-col>
            <v-col
              cols="12"
              style="padding: 0px !important"
              align="center"
              justify="center"
            >
              <v-img
                contain
                class="mb-12 ml-12 mr-12"
                :class="imgUltraTiny ? 'step_img_ultratiny_modal' : ''"
                :src="imgSelected"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "DisenioPiezasMarketing",
  props: ["step", "tab_title"],
  data() {
    return {
      urlS3: "https://image-store.isocialcube.com/advise",
      urlS3_2: "https://isc-store.s3.amazonaws.com/advise/Roadmap+Information",
      imgDialog: false,
      imgSelected: null,
      imgUltraTiny: false,
      tables: {
        step_8: {
          headers: [
            "¿Qué hace la marca?",
            "¿Qué hace el cliente?",
            "¿Qué gana la marca?",
          ],
          bodies: [
            "Sorteos",
            "Sigue los pasos del sorteo",
            "Aumenta la cantidad de seguidores",
            "Contenido exclusivo",
            "Ingresa a la plataforma e interactúa con el contenido exclusivo",
            "Mayor posicionamiento en el medio",
            "Descuentos",
            "Compra los productos en descuento",
            "Clientes",
            "Colaboraciones con otras marcas",
            "Compra los productos exclusivos",
            "Clientes",
          ],
        },
      },
    };
  },
  methods: {
    openImgDialog(img, ultratiny = false) {
      if (this.imgDialog == false) {
        this.imgDialog = true;
        this.imgSelected = img;
        ultratiny == true
          ? (this.imgUltraTiny = true)
          : (this.imgUltraTiny = false);
      }
    },
    closeImgDialog() {
      if (this.imgDialog == true) {
        this.imgDialog = false;
        this.imgSelected = null;
        this.imgUltraTiny = false;
      }
    },
  },
};
</script>

<style src="../goalsDefinition.css" scoped />
