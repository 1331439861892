<template>
  <v-card v-if="step != null" class="pt-1">
    <div>
      <v-toolbar class="mx-1" color="#F3F4F8" flat>
        <v-toolbar-title class="step_title">
          {{ tab_title }}
        </v-toolbar-title>
      </v-toolbar>
    </div>
    <div class="step_main_content">
      <!-- Step 0 -->
      <v-card class="px-7 step_container" flat v-if="step == 0">
        <v-card-title class="step_subtitle1">
          0. Antes de Empezar
        </v-card-title>
        <span class="step_subtitle2">
          1. Es necesario definir los siguientes puntos antes de realizar un
          análisis y plantear una estrategia.
        </span>
        <v-card-text class="step_text"
          ><p>
            <b
              >a) Mapear las RRSS actuales e ingresarlas a ISC (Si no posee
              ninguna saltar este paso):</b
            >
          </p></v-card-text
        >
        <v-card-text class="step_text step_list"
          ><ul>
            <li>Facebook.</li>
            <li>Twitter.</li>
            <li>Instagram.</li>
            <li>LinkedIn.</li>
            <li>Otro.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step0_1CA.webp`"
            @click="openImgDialog(`${urlS3}/step0_1CA.webp`)"
          />
        </div>
        <v-card-text class="step_text"
          ><p><b>b) Definir Características del Público Objetivo:</b></p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>Género.</li>
            <li>Edad.</li>
            <li>Ubicación.</li>
            <li>Necesidades.</li>
            <li>Motivaciones.</li>
            <li>Hábitos y hobbies.</li>
            <li>NSE (Nivel Socio Económico).</li>
            <li>Hábitos en Social Media.</li>
          </ul>
        </v-card-text>
        <span class="step_text indent-1"
          ><b>c) Definir objetivos específicos:</b>
        </span>
        <v-card-text class="step_text start"
          ><p>
            Se sugiere definir los objetivos bajo estos criterios de objetivos
            <b>SMART</b>
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li><b>Specific (específico)</b></li>
            <span
              >Aspecto, tarea o acción determinada que dice exactamente lo que
              se espera lograr.</span
            >
            <br />
            <br />
            <li><b>Mesurable (medible)</b></li>
            <span
              >Los objetivos tienen que ser medibles, de lo contrario no es
              posible interpretar si los resultados están dentro de lo
              esperado.</span
            >
            <br />
            <br />
            <li><b>Achievable (alcanzable)</b></li>
            <span
              >Los objetivos debes ser realizables. Es importante plantearse
              metas realistas, sobre la base de su cumplimiento se puede - luego
              - apuntar más alto.</span
            >
            <br />
            <br />
            <li><b>Relevant (relevante)</b></li>
            <span
              >Los objetivos deben estar en línea con los objetivos generales
              del negocio.</span
            >
            <br />
            <br />
            <li><b>Timely (temporal)</b></li>
            <span
              >Los objetivos deben estar limitados a un tiempo determinado.
              Todas las características dependen del tiempo en que deben ser
              completados.</span
            >
          </ul>
        </v-card-text>
        <span class="step_subtitle2"> d). Realizar Benchmarking: </span>
        <v-card-text class="step_text start"
          ><p>
            ¿Quiénes son los competidores directos e indirectos? ¿Cuáles son sus
            RRSS?
          </p>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col
                v-for="(header, index) in tables.step_0.headers"
                :key="index"
                style="width: 14%; max-width: 14%; flex-basis: 14%"
              >
                <v-card
                  outlined
                  tile
                  class="table_step header d-flex align-center justify-center"
                  height="70px"
                >
                  <v-card-text
                    class="d-flex align-center justify-center header"
                    >{{ header }}</v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-for="(cols, index1) in tables.step_0.bodies"
              :key="index1"
            >
              <v-col
                v-for="(item, index2) in cols"
                :key="index2"
                style="width: 14%; max-width: 14%; flex-basis: 14%"
              >
                <v-card
                  outlined
                  tile
                  class="table_step d-flex align-center justify-center"
                  height="70px"
                >
                  <v-card-text class="d-flex align-center justify-center">{{
                    item
                  }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- Step 1 -->
      <v-card class="px-7 step_container" flat v-if="step == 1">
        <v-card-title class="step_subtitle1">
          <p>
            1. Identificar el tipo de evaluaciones que se obtienen desde LISTEN
            en Twitter y Facebook
          </p>
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            Es necesario conocer el panorama en el sector o industria desde las
            redes sociales, de esta manera la empresa puede aprovechar
            oportunidades y evitar amenazas identificadas en LISTEN. Además, a
            partir de este análisis se pueden conocer Insights que son usados
            estratégicamente para realizar una campaña de lanzamiento exitosa.
            LISTEN escucha a diversas industrias con 'ISC Boards' y geolocaliza
            aquellos Drivers que tienen datos en 'GEO Listening'. Puedes
            seleccionar Drivers de esas escuchas para completar la tuya y/o usar
            los diagnósticos de cada industria directamente. La idea es que
            respondas:
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              ¿Cuáles son los DRIVERS que se usaron en la investigación de la
              industria?
            </li>
            <li>
              ¿Cuáles son los drivers con más interacciones? De ellos: ¿Cuáles
              puedo usar en la campaña para audiencia, branding o leads?
            </li>
            <li>
              ¿Cuál es el sentimiento predominante y cuáles son las razones de
              este sentimiento?
            </li>
            <li>
              ¿Cuál es género que interactúa más con la industria o drivers
              seleccionados?
            </li>
            <li>¿Cuáles son las emociones más predominantes por día?</li>
            <li>
              ¿Cuáles son los influencers o fans que la empresa puede usar
              estratégicamente a través del Ranking de Influencers?
            </li>
            <li>
              ¿Qué es lo que publican los influencers de acuerdo a los Drivers
              colocados?
            </li>
            <li>
              ¿Cómo reacciona la comunidad de los influencers de acuerdo a su
              contenido?
            </li>
            <li>
              ¿Cuáles son los Trending topics o hashtags más usados para que
              puedas usarlos estratégicamente?
            </li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_1CA.webp`"
            @click="openImgDialog(`${urlS3}/step1_1CA.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_2CA.webp`"
            @click="openImgDialog(`${urlS3}/step1_2CA.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_3CA.webp`"
            @click="openImgDialog(`${urlS3}/step1_3CA.webp`)"
          />
        </div>
        <v-card-text class="step_text">
          <p>
            Además, se pueden crear contextos personalizados seleccionando
            Drivers 'Stand Alone' que complementen el análisis para obtener
            Insights. Estos últimos se utilizan en la planificación estratégica
            de una campaña de lanzamiento exitosa. Para ello, se recomienda
            tener en cuenta:
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              <b>Analizar los 'ISC Boards'</b> con énfasis en los CSA (Cluster
              Social Analytics). Esto te ayuda a escoger Drivers que generan
              tráfico.
            </li>
            <li><b>Definir y configurar</b> los Drivers para escuchar.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_4CA.webp`"
            @click="openImgDialog(`${urlS3}/step1_4CA.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 2 -->
      <v-card class="px-7 step_container" flat v-if="step == 2">
        <v-card-title class="step_subtitle1">
          <p>
            2. Identificar el tipo de evaluaciones que se obtienen desde MONITOR
            a través del mapeo de competidores
          </p>
        </v-card-title>
        <v-card-text class="step_text">
          <p class="indent-0">
            Realizar un benchmarking de los competidores permite a la empresa
            elevar sus estándares de competitividad y liderazgo en el mercado,
            detectando las falencias y debilidades ante los competidores
            directos o indirectos. Además, fomenta la creación de nuevas ideas y
            estrategias que impulsan el crecimiento de una marca o empresa.
          </p>
        </v-card-text>
        <v-card-text class="step_text">
          <p class="indent-0">
            Te recomendamos responder a las siguientes preguntas y analizar
            detalladamente los cuadros de MONITOR en cada Red Social (Con las
            respuestas podras alistar las observaciones que resalten en cada
            competidor):
          </p>
        </v-card-text>
        <v-card-text class="step_text d-flex justify-space-between">
          <v-card flat class="step_subcard">
            <v-card-title class="step_subtitle1">
              <span
                ><b
                  >Revisar sus webs o blogs y responder preguntas como:</b
                ></span
              >
            </v-card-title>
            <v-card-text class="step_text step_list indent-1"
              ><ul>
                <li>¿Cuáles son sus contenidos más populares?</li>
                <li>¿Cómo muestran sus productos y servicios?</li>
                <li>¿Cuál es su mensaje de marca?</li>
                <li>¿Qué tácticas de persuasión utilizan?</li>
              </ul>
            </v-card-text>
          </v-card>
          <v-card flat class="step_subcard">
            <v-card-title class="step_subtitle1">
              <span
                ><b
                  >Revisar sus redes sociales y responder preguntas como:</b
                ></span
              >
            </v-card-title>
            <v-card-text class="step_text step_list indent-1"
              ><ul>
                <li>¿Cómo interactúan con su comunidad?</li>
                <li>¿Cuántos seguidores tienen?</li>
                <li>¿Qué tipo de contenido y formatos utilizan?</li>
                <li>¿Hay seguidores fidelizados con la marca?</li>
                <li>
                  Tiempo de respuesta a comentarios, frecuencia de publicación,
                  horarios.
                </li>
                <li>¿Cómo son sus posts?</li>
                <li>¿Quiénes son los influencers relacionados a ellos?</li>
                <li>¿Cuáles son las keywords que usan?</li>
                <li>¿Cuántas interacciones registra?</li>
                <li>¿Cuánto Engagement tiene con su comunidad?</li>
                <li>¿Cómo reacciona la comunidad a las publicaciones?</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Facebook:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_3.webp`"
            @click="openImgDialog(`${urlS3}/step2_3.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Instagram:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_1.webp`"
            @click="openImgDialog(`${urlS3}/step2_1.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Twitter:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_2.webp`"
            @click="openImgDialog(`${urlS3}/step2_2.webp`)"
          />
        </div>

        <v-card-text class="step_text">
          <p>
            Por otro lado, es esencial saber cual es la posición de nuestra
            empresa antes de realizar cualquier acción, para ello usamos el
            submódulo 'Overview' en MONITOR.
          </p>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/monitor_overview_accounts.webp`"
            @click="openImgDialog(`${urlS3}/monitor_overview_accounts.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/monitor_market_trends.webp`"
            @click="openImgDialog(`${urlS3}/monitor_market_trends.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 3 -->
      <v-card class="px-7 step_container" flat v-if="step == 3">
        <v-card-title class="step_subtitle1">
          3. Analizar los diagnósticos recogidos en LISTEN y MONITOR para
          convertirlos en Insights e ítems de acción
        </v-card-title>
        <v-card-text class="step_text">
          <p class="indent-0">
            Los insights surgen luego de una indagación a profundidad de los
            aspectos del consumidor que se ven reflejados en LISTEN y MONITOR.
          </p>
          <br />
          <p class="indent-0">¡El insight quiere ser revelado!</p>
          <br />
          <p class="indent-0">Algunos ejemplos:</p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              Insights que <b>alimentan nuevos conceptos de producto</b> basados
              en necesidades y demandas insatistechas, ocultas, reveladoras.
            </li>
            <li>
              Insights que <b>alimentan estrategias de posicionamiento</b> o
              reposicionamiento de los productos.
            </li>
            <li>
              Insights que <b>orientan la comunicación publicitaria</b> de forma
              tal de obtener una ruta efectiva para atraer, retener y/o
              fidelizar al consumidor.
            </li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step3_1.webp`"
            @click="openImgDialog(`${urlS3}/step3_1.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 4 -->
      <v-card class="px-7 step_container" flat v-if="step == 4">
        <v-card-title class="step_subtitle1">
          4. Plantear una estrategia de posicionamiento
        </v-card-title>
        <span class="step_subtitle2"
          >Reconocer y definir el diferencial de la marca o empresa:
        </span>
        <v-card-text class="step_text start"
          ><p class="indent-0">
            ¿Cuál es la fortaleza de la empresa? ¿Cómo se diferencia de la
            competencia?
          </p></v-card-text
        >
        <span class="step_subtitle2"
          >Definir la estrategia de posicionamiento en base al diferencial:
        </span>
        <v-card-text class="step_text start"
          ><p class="indent-0">
            Tomar en cuenta el siguiente cuadro que explica la funcionalidad de
            cada estrategia ligada al objetivo establecido de diseño de piezas
            de marketing:
          </p></v-card-text
        >
        <v-card-text class="step_table" align="center" justify="center">
          <div class="container responsive" style="width: 60%">
            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col cols="6">
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>Estrategias de Posicionamiento</b></v-card-text
                  >
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>Acciones en RR.SS</b></v-card-text
                  >
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Según el estilo de vida</b></v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Se basa en los intereses, la forma de vida, los valores,
                      la actitud y el comportamiento de los consumidores.
                      Ejemplo: Marca de cosméticos veganos</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >Mostrar el producto en la rutina diaria del público
                    objetivo.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Basada en el usuario</b></v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Está enfocada a un perfil, donde se identifican sus
                      gustos, pasiones o preferencias. Ejemplo: Messi es imagen
                      de una marca de zapatillas de fútbol</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >El uso de Influencers como imagen de marca hace más
                    sencillo comunicar el posicionamiento a las personas que se
                    sientan identificadas o que aspiren a ser como el
                    Influencer.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Basada en el uso o aplicación del producto</b>
                    </v-card-text>
                    <v-card-text class="text-center start"
                      >Destaca la finalidad de un producto. Ejemplo: Cremas
                      rejuvenecedoras.</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >Mostrar claramente el objetivo que tiene el producto.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Basada en la calidad o precio</b></v-card-text
                    >
                    <v-card-text class="text-center start end"
                      >Una calidad superior a las demás marcas del mercado.
                      Ejemplo: Apple</v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Asocia la marca a un precio muy competitivo o un precio
                      muy elevado, que habitualmente este vinculado a la
                      exclusividad o lujo. Ejemplo: Supreme.</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >El uso de Influencers como imagen de marca hace más
                    sencillo comunicar el posicionamiento a las personas que se
                    sientan identificadas o que aspiren a ser como el
                    Influencer.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Frente a la competencia</b></v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Explota las ventajas competitivas y los atributos de la
                      marca, comparándolas con las marcas competidoras. Ejemplo:
                      Oral B coloca anuncios donde se compara con otros
                      dentífricos.</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >Las marcas que basan su estrategia en un solo atributo,
                    pueden fortalecer su imagen en la mente del consumidor con
                    mayor facilidad que las que intentan basar su
                    posicionamiento en varios atributos.
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col cols="6" class="subcontainer">
                <v-card class="body d-flex align-center" outlined tile>
                  <div>
                    <v-card-text class="text-center end"
                      ><b>Basada en beneficios</b></v-card-text
                    >
                    <v-card-text class="text-center start"
                      >Basarse en el bien que el producto o servicio le causa a
                      los clientes. Ejemplo: Ariel es capaz de eliminar las
                      manchas difíciles y los malos olores.</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>
              <v-col cols="6" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <v-card-text class="text-center"
                    >No se trata solo del producto sino de la experiencia de
                    compra y la posventa.
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>

        <span class="step_subtitle2">Define las RRSS que utilizarás:</span>
        <v-card-text class="step_text start"
          ><p class="indent-0">
            Es necesario estar presentes en las RRSS donde se encuentra nuestro
            püblico objetivo. No es necesario tener presencia en todas, cada
            plataforma requiere de una estructura de contenidos acorde a sus
            características:
          </p></v-card-text
        >
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              <b>Facebook:</b> Red social con mayor cantidad de usuarios hasta
              el momento y con un rango de edad de consumo bastante amplio. Los
              usuarios se conectan a cualquier hora del dia desde cualquier
              dispositivo, demanda contenidos de alta interacción y con sentido
              de generación de comunidad.
            </li>
            <li>
              <b>Twitter:</b> En esta red todo pasa muy rápido, un tweet tiene
              duración corta. Se utiliza mayormente desde el teléfono móvil.
              Demanda contenidos de alto impacto y atomizados para generar
              interacciones con la comunidad.
            </li>
            <li>
              <b>Instagram:</b> Red muy visual, fácil de consumir y popular. El
              principal contenido son las imágenes, pero los videos cortos
              vienen tomando protagonismo. Priman las audiencias por debajo de
              los 30 años y se premian los contenidos auténticos exponiéndose al
              público potencial de manera orgánica para un mayor alcance.
            </li>
            <li>
              <b>Youtube:</b> Priman los videos como contenido principal para
              fidelizar seguidores y crear una comunidad, trabajando de la mano
              con la cantidad de likes, número de visitas y número de
              suscriptores.
            </li>
            <li>
              <b>Linkedin:</b> Es la Red profesional más grande del mundo,
              orientada a generar conexiones laborales y empresariales. Prima la
              búsqueda de empleo, contratar profesionales, potenciar
              emprendimientos y contactar clientes, además esta plataforma te
              permite estar informado de las últimas novedades de la industria o
              sector.
            </li>
          </ul>
          <div class="step_img_card">
            <img
              class="step_img mx-auto d-flex justify-center align-center mt-5"
              :src="`${urlS3}/step4_1CA.webp`"
              @click="openImgDialog(`${urlS3}/step4_1CA.webp`)"
            />
          </div>
        </v-card-text>
      </v-card>
      <!-- Step 5 -->
      <v-card class="px-7 step_container" flat v-if="step == 5">
        <v-card-title class="step_subtitle1">
          <p>
            5. Definir el tipo de campaña que se desarrollará (Audiencia /
            Branding / Leads)
          </p>
        </v-card-title>
        <v-card-text class="step_text">
          <p class="indent-0">
            Antes de continuar es necesario tener claro el objetivo de la
            campaña a desarrollar, pues de ello dependerá el contenido, las
            redes sociales a utilizar, el diseño, los formatos, entre otros
            aspectos que se toman en cuenta al realizar el despliegue de posts.
          </p>
          <br />
          <p class="indent-0">
            Para ello, a continuación se detalla el objetivo de algunos tipos de
            campaña que se pueden realizar:
          </p>
        </v-card-text>

        <v-card-text class="step_table">
          <div class="container" style="width: 100%">
            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col cols="4">
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>Campaña para aumentar Audiencia</b></v-card-text
                  >
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>Campaña de Branding</b></v-card-text
                  >
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>Campaña de Leads</b></v-card-text
                  >
                </v-card>
              </v-col>

              <v-col cols="4" class="subcontainer">
                <v-card class="body d-flex align-center px-5" outlined tile>
                  <div>
                    <v-card-text class="text-center">
                      El objetivo es
                      <b
                        >ganar visibilidad en Redes Sociales y crear una
                        comunidad
                      </b>
                      alrededor de la marca que le sea fiel, que responda a las
                      peticiones de la marca, que la ayude a crecer y a captar
                      nuevos clientes.
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4" class="subcontainer">
                <v-card class="body d-flex align-center px-5" outlined tile>
                  <div>
                    <v-card-text class="text-center">
                      El objetivo es la creación o restauración de un vínculo
                      fuerte y perdurable entre la marca y el público.
                    </v-card-text>
                    <v-card-text class="text-center start">
                      Toda estrategia persigue
                      <b> posicionar a la marca en la mente del consumidor </b>
                      como primera opción de compra de ese producto o servicio.
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4" class="subcontainer">
                <v-card class="body d-flex align-center px-5" outlined tile>
                  <div>
                    <v-card-text class="text-center">
                      Un lead es todo aquel usuario de una página web que, en un
                      momento determinado, nos facilita sus datos en un
                      formulario, perdiendo así su condición de visita anónima y
                      convirtiéndose en un <b>lead</b> sobre el cual poder hacer
                      seguimiento</v-card-text
                    >
                  </div>
                </v-card>
              </v-col>

              <v-col cols="4" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <div
                    class="d-flex align-center justify-center w-100"
                    style="padding: 40px"
                  >
                    <ul class="step_text">
                      <li>Usar hashtags populares.</li>
                      <li>
                        Promocionar las publicaciones orgánicas que tuvieron
                        mayor interacción.
                      </li>
                      <li>Crear concursos y sorteos.</li>
                      <li>Incluir preguntas en los contenidos.</li>
                      <li>Incluir call to actions en los post.</li>
                      <li>Cuidar el contenido en las redes sociales.</li>
                    </ul>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <div
                    class="d-flex align-center justify-center w-100"
                    style="padding: 40px"
                  >
                    <ul class="step_text">
                      <li>Definir el target.</li>
                      <li>Creatividad e innovación.</li>
                      <li>Puesta en Valor.</li>
                      <li>Diferenciación.</li>
                      <li>Divulgación.</li>
                      <li>Análisis y medición de indicadores.</li>
                      <li>Constancia en las publicaciones.</li>
                    </ul>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="4" class="subcontainer">
                <v-card outlined tile class="body stretch d-flex align-center">
                  <div
                    class="d-flex align-center justify-center w-100"
                    style="padding: 40px"
                  >
                    <ul class="step_text">
                      <li>Definir el buyer persona.</li>
                      <li>Organizar el embudo de ventas.</li>
                      <li>
                        Crear contenidos descargables que despierten la atención
                        de la audiencia (ebooks, investigaciones, otros).
                      </li>
                      <li>Automatizar la estrategia de Marketing.</li>
                    </ul>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <!-- Step 6 -->
      <v-card class="px-7 step_container" flat v-if="step == 6">
        <v-card-title class="step_subtitle1">
          6. Definir el contenido de las publicaciones para ejecutar la
          estrategia
        </v-card-title>
        <v-card-text class="step_text step_list"
          ><ul class="indent-1">
            <li>
              Usar conexiones directas (contactos, empleados, clientes y
              familiares) primero para viralizar el contenido orgánicamente.
              Recibir feedback y corregir para luego aplicar publicidad.
            </li>
            <li>
              La estrategia debe aterrizar en un Plan de Piezas de Marketing con
              descripciones detalladas con énfasis en los mensajes (visuales,
              escritos y descriptivos) que se desea enviar a la audiencia.
            </li>
            <li>
              Las publicaciones deben estar compuestas por 2 insumos
              indispensables: Los <b>Temas</b>, que se refieren a los asuntos
              que se desean comunicar y los <b>Formatos</b> que se refieren a la
              forma en la que se comunicará la marca con su comunidad. A
              continuación, se muestran ejemplos:
            </li>
          </ul>
        </v-card-text>
        <v-card-text
          style="width: 100%"
          class="d-flex align-center justify-center"
        >
          <div class="d-flex justify-center align-center" style="width: 80%">
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="justify-center align-center"
            >
              <v-col cols="12" class="customCol"
                ><v-card
                  outlined
                  tile
                  class="colCard colHeader d-flex justify-center align-center"
                  ><b>Formatos</b></v-card
                >
              </v-col>
              <v-col class="customCol" order="1">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Videos</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_1.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="2">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Imágenes</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_2.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="3">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Infografías</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_3.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="4">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Historias</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 40px"
                        :src="`${urlS3}/step5_4.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="5">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Addons</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_5.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text
          style="width: 100%"
          class="d-flex align-center justify-center"
        >
          <div class="d-flex justify-center align-center" style="width: 70%">
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="justify-center align-center"
            >
              <v-col cols="12" class="customCol"
                ><v-card
                  outlined
                  tile
                  class="colCard colHeader d-flex justify-center align-center"
                  ><b>Temas</b></v-card
                >
              </v-col>
              <v-col class="customCol" order="1">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Cuidados del producto</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
              <v-col class="customCol" order="2">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Modos de uso</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
              <v-col class="customCol" order="3">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Ejemplos de uso de un producto</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step5_6.webp`"
            @click="openImgDialog(`${urlS3}/step5_6.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 7 -->
      <v-card class="px-7 step_container" flat v-if="step == 7">
        <v-card-title class="step_subtitle1">
          7. Definir las piezas de Marketing y planificar su despliegue
        </v-card-title>
        <v-card-text class="step_text step_list"
          ><ul class="indent-1">
            <li>Día de inicio de campaña</li>
            <li>Día de fin de campaña.</li>
            <li>Hora.</li>
            <li>Calendarización.</li>
          </ul>
        </v-card-text>
        <v-card-text class="step_table7">
          <div class="container mx-3" style="width: 100%">
            <v-row no-gutters>
              <v-col class="colHeader">
                <v-card
                  outlined
                  tile
                  class="header d-flex align-center justify-center"
                  height="70px"
                >
                  <v-card-text class="d-flex align-center justify-center header"
                    ><b>Octubre</b></v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col
                v-for="(header, index) in [
                  'Domingo',
                  'Lunes',
                  'Martes',
                  'Miércoles',
                  'Jueves',
                  'Viernes',
                  'Sábado',
                ]"
                :key="index"
                class="colSubHeader"
              >
                <v-card outlined tile class="subheader">
                  <v-card-text class="text-center">{{ header }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">01</p>
                    
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">02</p>
                    <span class="body_title marked1">Post + stories:</span>
                    <br>
                    <p class="body_text">Publicar el addon (en el caso de leads)</p>
                    <p class="body_text">
                      - Imagen en IG y FB <br>
                      - Stories en IG y FB
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">03</p>
                    <span class="body_title marked1">Post + stories:</span>
                    <br>
                    <p class="body_text">Tema: Beneficios</p>
                    <p class="body_text">
                      - Video IG, FB y Twitter (Post)<br>
                      - Video IG, FB y Twitter (Stories)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">04</p>
                    <span class="body_title marked1">Post + stories:</span>
                    <br>
                    <p class="body_text">Tema: Responsabilidad social y producto</p>
                    <p class="body_text">
                      - 2 imágenes IG y FB (Post)<br>
                      - 1 imagen IG y FB (Stories)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">05</p>
                    <span class="body_title marked1">Post:</span>
                    <br>
                    <p class="body_text">Tema: Producto</p>
                    <p class="body_text">
                      - 1 imagen IG. FB y Twitter
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">06</p>
                    <span class="body_title marked1">Post + stories:</span>
                    <br>
                    <p class="body_text">Tema: Beneficios</p>
                    <p class="body_text">
                      - 2 imágenes IG y FB (Post)<br>
                      - 1 imagen IG y FB (Stories)
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">07</p>
                    <span class="body_title marked1">Post:</span>
                    <br>
                    <p class="body_text">Tema: Producto</p>
                    <p class="body_text">
                      - 1 imagen o texto Twitter<br>
                      - 2 imágenes IG y FB
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">08</p>
                    <span class="body_title marked1">Post:</span>
                    <br>
                    <p class="body_text">Tema: Producto</p>
                    <p class="body_text">
                      - 1 Reel IG
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">09</p>
                    <span class="body_title marked1">Post + stories:</span>
                    <br>
                    <p class="body_text">Tema: Promociones</p>
                    <p class="body_text">
                      - 1 Imagen en IG y FB <br>
                      - 1 Stories en IG y FB
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">10</p>
                    <p class="body_text">Repetir acciones variando formatos y temas</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">11</p>
                    <p class="body_text">Repetir acciones variando formatos y temas</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">12</p>
                    <p class="body_text">Repetir acciones variando formatos y temas</p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">13</p>
                    <span class="body_title marked1">Post + stories:</span>
                    <br>
                    <p class="body_text">Tema: Recordación de promoción</p>
                    <p class="body_text">
                      - 1 imagen IG y FB<br>
                      - 1 imagen Stories
                    </p>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col class="colBody">
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center"
                >
                  <v-card-text> 
                    <p class="body_day">14</p>
                    <p class="body_text">Repetir acciones variando formatos y temas</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <!-- Step 8 -->
      <v-card class="px-7 step_container" flat v-if="step == 8">
        <v-card-title class="step_subtitle1">
          8. Establecer ofertas y/o promociones diferentes al canal de ventas
          tradicional
        </v-card-title>
        <v-card-text class="step_text">
          <p class="indent-0">
            Realizar promociones u ofertas puede dinamizar las redes sociales de
            las marcas; Sin embargo, es importante que sean diferentes a las que
            existen en las tiendas físicas o canales tradicionales para lograr
            el objetivo. A continuación algunos ejemplos:
          </p>
        </v-card-text>
        <v-card-text class="step_table">
          <div class="container responsive" style="width: 60%">
            <v-row no-gutters align="stretch" align-content="stretch">
              <v-col
                cols="4"
                v-for="(title, index) in tables.step_8.headers"
                :key="index"
              >
                <v-card
                  outlined
                  tile
                  class="header d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>{{ title }}</b></v-card-text
                  >
                </v-card>
              </v-col>

              <v-col
                cols="4"
                v-for="(body, index) in tables.step_8.bodies"
                :key="index"
              >
                <v-card
                  outlined
                  tile
                  class="body fixed d-flex justify-center align-center"
                >
                  <v-card-text class="text-center"
                    ><b>{{ body }}</b></v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <!-- Step 9 -->
      <v-card class="px-7 step_container" flat v-if="step == 9">
        <v-card-title class="step_subtitle1">
          9. Pautear tus publicaciones (publicidad)
        </v-card-title>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p class="indent-0">
            a) Segmentación. <br />
            b) Presupuesto.<br />
            c) Programación (fechas).<br />
            d) Meta.<br />
            e) Objetivo:<br />
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list" style="padding-top: 0px"
          ><ul class="">
            <li>Compromiso.</li>
            <li>Educar.</li>
            <li>Entrenar.</li>
            <li>Vender.</li>
            <li>Comunicar.</li>
            <li>Conectar.</li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text">
          <p class="indent-0">
            f) Contenido/ diseño.<br />
            g) Tono de comunicación:<br />
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list" style="padding-top: 0px"
          ><ul>
            <li>Corporativo.</li>
            <li>Informal.</li>
            <li>Cercano.</li>
            <li>Humorístico.</li>
            <li>Demostrativo.</li>
            <li>Testimonial.</li>
            <li>Informativo.</li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text">
          <p class="indent-0">
            h) Mensajes de CM (Campaña de Marketing):<br />
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list" style="padding-top: 0px"
          ><ul>
            <li>Mensaje de saludo.</li>
            <li>Mensaje de presentación de productos.</li>
            <li>Mensaje de cobro.</li>
            <li>Mensaje de recolección de datos.</li>
            <li>Mensaje de despido.</li>
            <li>Mensaje de seguimiento.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step8_1.webp`"
            @click="openImgDialog(`${urlS3}/step8_1.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 10 -->
      <v-card class="px-7 step_container" flat v-if="step == 10">
        <v-card-title class="step_subtitle1">
          10. Escuchar las Redes Sociales a través de LISTEN y MONITOR para
          generar retroalimentación
        </v-card-title>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p>
            Es recomendable revisar constantemente los indicadores de las
            campañas en curso, de esta manera se puede evaluar si se están
            cumpliendo los objetivos planteados o realizar modificaciones para
            lograr mejores resultados. Además, es esencial conocer las novedades
            de la industria o sector para aprovechar nuevas oportunidades.
          </p>
        </v-card-text>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p>
            Te recomendamos evaluar constantemente la industria y a tus
            competidores para mejorar tus posteos y retroalimentar tus campañas.
            Puedes realizar estas acciones desde los submódulos de
            <b>LISTEN</b>, donde podrás escuchar todos los Drivers previamente
            definidos o cambiarlos según tu conveniencia, y en <b>MONITOR</b>,
            donde podrás mapear constantemente a tus competidores y estar al
            tanto de las novedades en el mercado.
          </p>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step9_1.webp`"
            @click="openImgDialog(`${urlS3}/step9_1.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step9_2.webp`"
            @click="openImgDialog(`${urlS3}/step9_2.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 11 -->
      <v-card class="px-7 step_container" flat v-if="step == 11">
        <v-card-title class="step_subtitle1">
          11. ¡Ejecutar, monitorear y escuchar!
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            ¡El ciclo se vuelve a repetir! Para lograr un verdadero éxito en las
            redes sociales la clave es ser constante. Ejecuta evaluaciones
            periódicas a las campañas que realices para mejorar continuamente.
          </p>
        </v-card-text>
        <v-card-text class="step_text start" style="padding-bottom: 0px">
          <p>Se pueden responder las siguientes preguntas:</p>
        </v-card-text>
        <v-card-text class="step_text step_list" style="padding-top: 0px"
          ><ul>
            <li>¿Cuál ha sido la mejor campaña?</li>
            <li>
              ¿Cuánto tráfico se ha convertido en suscriptores? (Mejor si es en
              porcentaje)
            </li>
            <li>
              ¿Cuántos leads ha generado? De estos leads: ¿Cuántos se han
              convertido en clientes?
            </li>
            <li>¿Qué contenido ha incidido más en la conversión?</li>
            <li>
              ¿Cuál es el contenido más popular y cómo puede ayudarme a mejorar
              o generar más ingresos y conversiones?
            </li>
            <li>
              ¿De qué canales viene el tráfico que más convierte? ¿Google, Redes
              Sociales, colaboraciones?
            </li>
            <li>
              ¿Cuáles son los Llamados a la Acción (CTA: Call to Action) u otras
              acciones más efectivas? ¿Cuáles son las menores y porqué?
            </li>
            <li>¿Cuáles fueron los sentimientos que arrojó la campaña?</li>
            <li>¿Qué tanto aportaron los influencers a la marca?</li>
            <li>¿Cuáles fueron los testimonios?</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step10_1.webp`"
            @click="openImgDialog(`${urlS3}/step10_1.webp`)"
          />
        </div>
      </v-card>
    </div>
    <v-dialog v-model="imgDialog" width="1200">
      <v-card class="justify-end modal-card">
        <v-row no-gutters>
          <v-col
            cols="12"
            style="padding: 2px !important"
            class="d-flex justify-end align-end"
          >
            <svg
              class="d-flex justify-center"
              style="
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                padding: 5px;
                margin: 3px;
              "
              viewBox="0 0 24 24"
              @click="imgDialog = false"
            >
              <path
                fill="currentColor"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
          </v-col>
          <v-col cols="12" style="padding: 0px !important">
            <v-img contain class="mb-12 ml-12 mr-12" :src="imgSelected">
            </v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "CampaniaAudiencia",
  props: ["step", "tab_title"],
  data() {
    return {
      urlS3:
        "https://image-store.isocialcube.com/advise",
      urlS3_2: "https://isc-store.s3.amazonaws.com/advise/Roadmap+Information",
      imgDialog: false,
      imgSelected: null,
      tables: {
        step_0: {
          headers: [
            "Competidores",
            "Web",
            "Facebook",
            "Instagram",
            "Twitter",
            "LinkedIn",
            "Otro",
          ],
          bodies: [
            [
              "Competidor 1",
              "Sí - Link",
              "No",
              "Sí - Link",
              "Sí - Link",
              "Sí - Link",
              "No",
            ],
            [
              "Competidor 2",
              "Sí - Link",
              "Sí - Link",
              "No",
              "Sí - Link",
              "Sí - Link",
              "Sí - Link",
            ],
            [
              "Competidor 3",
              "Sí - Link",
              "Sí - Link",
              "Sí - Link",
              "No",
              "Sí - Link",
              "No",
            ],
            [
              "Competidor 4",
              "No",
              "Sí - Link",
              "Sí - Link",
              "Sí - Link",
              "No",
              "Sí - Link",
            ],
          ],
        },
        step_8: {
          headers: [
            "¿Qué hace la marca?",
            "¿Qué hace el cliente?",
            "¿Qué gana la marca?",
          ],
          bodies: [
            "Sorteos",
            "Sigue los pasos del sorteo",
            "Aumenta la cantidad de seguidores",
            "Contenido exclusivo",
            "Ingresa a la plataforma e interactúa con el contenido exclusivo",
            "Mayor posicionamiento en el medio",
            "Descuento de lanzamiento",
            "Compra los productos en descuento",
            "Clientes",
            "Colaboraciones con otras marcas",
            "Compra los productos exclusivos",
            "Clientes",
          ],
        },
      },
    };
  },
  methods: {
    openImgDialog(img) {
      if (this.imgDialog == false) {
        this.imgDialog = true;
        this.imgSelected = img;
      }
    },
    closeImgDialog() {
      if (this.imgDialog == true) {
        this.imgDialog = false;
        this.imgSelected = null;
      }
    },
  },
};
</script>

<style src="../goalsDefinition.css" scoped />