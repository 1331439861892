<template>
  <div class="w-100 white positionRelative">
    <div class="">
      <img class="positionAbsolute" :src="`${urlS3}/vector.webp`" />
      <img
        class="positionAbsolute imgTPhone"
        :src="`${urlS3}/mano-celular.webp`"
      />
      <a class="w-100 textDecorationNone c-letter" @click="stepsClick(2)">
        <div
          class="positionAbsolute capa1"
          v-on:mouseleave="step2 = true"
          v-on:mouseover="step2 = false"
        ></div>
      </a>
      <a class="w-100 textDecorationNone c-letter" @click="stepsClick(5)">
        <div
          class="positionAbsolute capa2"
          v-on:mouseleave="step5 = true"
          v-on:mouseover="step5 = false"
        ></div>
      </a>
      <a class="w-100 textDecorationNone c-letter" @click="stepsClick(9)">
        <div
          class="positionAbsolute capa3"
          v-on:mouseleave="step9 = true"
          v-on:mouseover="step9 = false"
        ></div>
      </a>
      <img
        class="positionAbsolute zIndex2 imgRrss"
        :src="`${urlS3}/reacciones-rrss.webp`"
      />
      <img
        class="positionAbsolute zIndex2 imgSearch"
        :src="`${urlS3}/Buscar.webp`"
      />
      <img
        class="positionAbsolute zIndex2 imgNodoSYellow"
        :src="`${urlS3}/nodos-amarillo.webp`"
      />
      <img
        class="positionAbsolute zIndex2 imgCellReactions"
        :src="`${urlS3}/celular-reacciones.webp`"
      />
      <img
        class="positionAbsolute zIndex2 imgPieChart-audience"
        :src="`${urlS3}/grafico-pieABL.webp`"
      />
      <div class="positionAbsolute zIndex2 inf">
        <img style="width: 55%" :src="`${urlS3}/Flecha-2.webp`" />
        <div class="indicativeText2 p-05">
          <p class="mb-0">
            <strong>IMPLEMENTACIÓN</strong>
          </p>
          <p class="mb-0">(ESTRATEGIA + MONITOR)</p>
        </div>
      </div>
    </div>
    <div>
      <div class="w-100 h-100"></div>
      <div class="d-flex positionAbsolute w-100" style="height: 23%">
        <img
          class="positionAbsolute zIndex2 imgList"
          :src="`${urlS3}/Lista.webp`"
        />
        <img
          class="positionAbsolute zIndex2 imgUser"
          :src="`${urlS3}/usuario.webp`"
        />
        <div class="w-20">
          <p
            class="p-titleMap mb-0 c-letter p-title-abl"
            style="padding: 3rem !important"
          >
            AUDIENCIA, BRANDING Y LEADS
          </p>
        </div>
        <div class="w-30 positionRelative">
          <img
            class="positionAbsolute w-10"
            style="left: 45%"
            :src="`${urlS3}/Reloj.webp`"
          />
          <div class="w-100 d-flex justifyEnd">
            <div class="indicativeText" style="padding: 1rem 0rem">
              <p class="mb-0"><strong>IMPLEMENTACIÓN</strong></p>
              <p class="mb-05">ESTRATEGIA + MONITOR</p>
            </div>
          </div>
          <a class="w-100 textDecorationNone c-letter" @click="stepsClick(0)">
            <div
              class="d-flex justifyCenter alignItemsCenter fDirectionColumn"
              style="height: 85%"
            >
              <p class="p-subMap1 mb-0">ANTES</p>
              <p class="p-subMap2 mb-0">DE EMPEZAR...</p>
            </div>
          </a>
        </div>
        <div class="w-30 p-05">
          <img style="width: 30%" :src="`${urlS3}/Flecha-1ABL.webp`" />
        </div>
      </div>
      <div class="containerMap" style="padding: 2.5rem 3rem !important">
        <div class="d-flex w-100">
          <!-- COL 1 -->
          <v-col class="colSides p-0">
            <v-row class="row1">
              <div class="rectangle"></div>
              <div class="space"></div>
              <div class="rectangleGreatLeftTotal">
                <div class="h-100">
                  <a
                    class="w-100 textDecorationNone c-letter"
                    @click="stepsClick(5)"
                  >
                    <div
                      :class="step5Color"
                      class="h-50 rectangleGreatLeftTopMedium positionRelative"
                      v-on:mouseleave="step5 = true"
                      v-on:mouseover="step5 = false"
                    >
                      <div
                        class="rectangleLittleLeftTopMedium white positionAbsolute"
                        style="width: 30%"
                      ></div>
                      <div class="h-100 w-70 p-sides1" style="width: 70%">
                        <div
                          class="h-45 w-100 d-flex alignItemsFEnd justifyCenter"
                        >
                          <div class="numberCustom BgBlue c-white">5</div>
                        </div>
                        <div class="h-55 ptp-1 textCenter">
                          <div v-show="step5">
                            <p class="p-title lh-18 mb-0">
                              <strong>DEFINIR</strong>
                            </p>
                            <p class="p-caption lh-14">
                              EL TIPO DE CAMPAÑA QUE SE DESARROLLARÁ
                            </p>
                          </div>
                          <div v-show="!step5">
                            <p class="p-medium lh-14">
                              <strong>Definir</strong> el tipo de
                              <strong>campaña</strong> que se desarrollará
                              (Audiencia / Branding / Leads).
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a class="w-100 textDecorationNone" @click="stepsClick(6)">
                    <div
                      :class="step6Color"
                      class="h-50 rectangleGreatLeftBottomMedium positionRelative"
                      v-on:mouseleave="step6 = true"
                      v-on:mouseover="step6 = false"
                    >
                      <div
                        class="rectangleLittleLeftBottomMedium white positionAbsolute"
                        style="width: 30%"
                      ></div>
                      <div class="h-100 w-70 p-sides1" style="width: 70%">
                        <div
                          class="h-30 w-100 d-flex alignItemsFEnd justifyCenter"
                        >
                          <div class="numberCustom BgLightBlue c-letter">6</div>
                        </div>
                        <div
                          class="h-70 ptp-1 textCenter"
                          :class="colorParagraph"
                        >
                          <div v-show="step6" class="p-sides05">
                            <p class="p-title lh-18 mb-0">
                              <strong>DEFINIR</strong>
                            </p>
                            <p class="p-caption lh-14">
                              EL CONTENIDO DE LAS PUBLICACIONES
                            </p>
                          </div>
                          <div v-show="!step6">
                            <p class="p-medium lh-14">
                              <strong>Definir el contenido</strong> de las
                              publicaciones para ejecutar la estrategia.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </v-row>
          </v-col>
          <!-- COL 2 -->
          <v-col class="w-70 p-0">
            <v-row>
              <img
                class="positionAbsolute zIndex2 imgMegafonoCA-audience"
                :src="`${urlS3}/megafonoCA.webp`"
              />
              <div class="w-100 d-flex fDirectionRReverse">
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">1</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(1)">
                      <div
                        :class="step1Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step1 = true"
                        v-on:mouseover="step1 = false"
                      >
                        <div class="dataContent c-letter">
                          <div class="textCenter lh-18" v-show="step1">
                            <strong class="p-title">IDENTIFICAR</strong>
                            <p class="p-caption c-letter">
                              LOS TIPOS DE EVALUACIONES QUE OBTIENES DESDE
                              LISTEN
                            </p>
                          </div>
                          <div v-show="!step1">
                            <p class="p-medium lh-14">
                              Identificar el tipo de
                              <strong>evaluaciones</strong> que se obtienen
                              desde <strong>LISTEN</strong> en Twitter y
                              Facebook.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="w-100 d-flex">
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">4</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(4)">
                      <div
                        :class="step4Color"
                        class="rectangleMedium b-r b-l"
                        v-on:mouseleave="step4 = true"
                        v-on:mouseover="step4 = false"
                      >
                        <div class="dataContent c-letter textCenter">
                          <div v-show="step4">
                            <p class="lh-14 mb-0 p-title">
                              <strong>DISEÑAR</strong>
                            </p>
                            <p class="p-caption c-letter">
                              UNA ESTRATEGIA DE POSICIONAMIENTO
                            </p>
                          </div>
                          <div v-show="!step4">
                            <p class="p-medium lh-14">
                              Plantear una <strong>estrategia</strong> de
                              <strong>posicionamiento.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">3</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(3)">
                      <div
                        :class="step3Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step3 = true"
                        v-on:mouseover="step3 = false"
                      >
                        <div class="dataContent c-letter textCenter">
                          <div v-show="step3" class="lh-18">
                            <strong class="p-title mb-0">ANALIZAR</strong>
                            <p class="p-caption lh-14">
                              LOS DIAGNÓSTICOS RECOGIDOS EN LISTEN Y MONITOR
                            </p>
                          </div>
                          <div v-show="!step3">
                            <p class="p-medium lh-14">
                              Analizar los diagnósticos recogidos en
                              <strong>LISTEN</strong> y
                              <strong>MONITOR</strong> para convertirlos en
                              Insights e ítems de acción.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div
                class="space d-flex justifyCenter"
                style="padding-left: 6rem"
              >
                <img
                  style="width: 4rem; height: 2.5rem"
                  :src="`${urlS3}/Flecha-3ABL.webp`"
                />
                <div class="indicativeText2 p-05">
                  <p class="mb-0">
                    <strong>SUPERVISIÓN Y RETROALIMENTACIÓN</strong>
                  </p>
                  <p class="mb-0">(LISTEN + MONITOR)</p>
                </div>
              </div>
              <div class="w-100 d-flex">
                <div class="w-20 positionRelative d-flex alignItemsCenter">
                  <img src="" />
                  <img
                    class="positionAbsolute imgArrowBlue4"
                    :src="`${urlS3}/Flecha-4ABL.webp`"
                  />
                </div>
                <div class="w-35">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">11</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(11)">
                      <div
                        :class="step11Color"
                        class="rectangleMedium b-r c-letter"
                        v-on:mouseleave="step11 = true"
                        v-on:mouseover="step11 = false"
                      >
                        <div class="dataContent textCenter lh-18">
                          <div v-show="step11">
                            <p class="lh-18 p-title">
                              <strong>¡EJECUTAR, MONITOREAR Y ESCUCHAR!</strong>
                            </p>
                          </div>
                          <div v-show="!step11">
                            <p class="lh-18 p-title">
                              <strong>¡EJECUTAR, MONITOREAR Y ESCUCHAR!</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-45">
                  <div class="rectangle">
                    <div class="number BgBlue c-white number10">10</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(10)">
                      <div
                        :class="step10Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step10 = true"
                        v-on:mouseover="step10 = false"
                      >
                        <div class="dataContent textCenter c-letter">
                          <div v-show="step10">
                            <p class="p-title mb-0">
                              <strong>ESCUCHAR LAS RRSS</strong>
                            </p>
                            <p class="p-caption lh-14">
                              UTILIZAR LISTEN Y MONITOR PARA GENERAR
                              RETROALIMENTACIÓN
                            </p>
                          </div>
                          <div v-show="!step10">
                            <p class="p-medium lh-14">
                              <strong>Escuchar las Redes Sociales</strong> a
                              través de LISTEN y MONITOR para
                              <strong>generar retroalimentación.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="w-100 d-flex">
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgLightBlue c-letter">7</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(7)">
                      <div
                        :class="step7Color"
                        class="rectangleMedium b-r b-l"
                        v-on:mouseleave="step7 = true"
                        v-on:mouseover="step7 = false"
                      >
                        <div
                          class="dataContent textCenter"
                          :class="colorParagraph2"
                        >
                          <div v-show="step7">
                            <p class="lh-14 mb-0 p-title">
                              <strong>DEFINIR</strong>
                            </p>
                            <p class="p-caption">
                              LAS PIEZAS DE MARKETING Y PLANIFICAR SU DESPLIEGUE
                            </p>
                          </div>
                          <div v-show="!step7">
                            <p class="p-medium lh-14">
                              Definir las <strong>piezas de Marketing</strong> y
                              <strong>planificar su despliegue.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-50">
                  <div class="rectangle">
                    <div class="number BgLightBlue c-letter">8</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(8)">
                      <div
                        :class="step8Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step8 = true"
                        v-on:mouseover="step8 = false"
                      >
                        <img
                          class="positionAbsolute imgCoin"
                          :src="`${urlS3}/monedas.webp`"
                        />
                        <div
                          class="dataContent textCenter"
                          :class="colorParagraph3"
                        >
                          <div v-show="step8" class="lh-18">
                            <strong class="p-title mb-0">ESTABLECER</strong>
                            <p class="p-caption lh-14">
                              OFERTAS Y/O PROMOCIONES
                            </p>
                          </div>
                          <div v-show="!step8">
                            <p class="p-medium lh-14">
                              Establecer <strong>ofertas</strong> y/o
                              <strong>promociones diferentes</strong> al canal
                              de ventas tradicional.
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </v-row>
          </v-col>
          <!-- COL 3 -->
          <v-col class="colSides p-0">
            <v-row>
              <a
                class="w-100 textDecorationNone positionRelative"
                @click="stepsClick(2)"
              >
                <div
                  :class="step2Color"
                  class="d-flex rectangleGreatRight"
                  v-on:mouseleave="step2 = true"
                  v-on:mouseover="step2 = false"
                >
                  <div
                    class="white rectangleLittleRight"
                    style="width: 20%"
                  ></div>
                  <div
                    class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter c-letter m-sideRight class2"
                    style="width: 80%"
                  >
                    <div
                      class="w-100 d-flex alignItemsFEnd justifyCenter"
                      style="height: 40%"
                    >
                      <div class="numberCustom BgBlue c-white">2</div>
                    </div>
                    <div class="ptp-1" style="height: 60%">
                      <div
                        class="textEnd text1-audience"
                        style="padding-right: 1rem"
                        v-show="step2"
                      >
                        <p class="p-title lh-18 mb-0">
                          <strong>IDENTIFICAR</strong>
                        </p>
                        <p class="p-caption lh-14">
                          LOS TIPOS DE EVALUACIONES QUE OBTIENES DESDE MONITOR
                        </p>
                      </div>
                      <div class="textCenter" v-show="!step2">
                        <p class="p-medium lh-14">
                          Identificar el tipo de evaluaciones de
                          <strong>MONITOR</strong> (Mapeo de competidores)
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
              <div class="space"></div>
              <a class="w-100 textDecorationNone" @click="stepsClick(9)">
                <div
                  :class="step9Color"
                  class="d-flex rectangleGreatRight positionRelative"
                  v-on:mouseleave="step9 = true"
                  v-on:mouseover="step9 = false"
                >
                  <img
                    class="positionAbsolute imgNodosCyan"
                    :src="`${urlS3}/nodos-cyan.webp`"
                  />
                  <div
                    class="white rectangleLittleRight"
                    style="width: 20%"
                  ></div>
                  <div
                    class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter"
                    :class="colorParagraph4"
                    style="width: 80%"
                  >
                    <div
                      class="h-30 w-100 d-flex alignItemsFEnd justifyCenter m-sideRight"
                    >
                      <div class="numberCustom BgLightBlue c-letter">9</div>
                    </div>
                    <div class="ptp-1 h-70 w-100">
                      <div
                        class="textEnd"
                        v-show="step9"
                        style="margin-right: 2.5rem; margin-left: 0.5rem"
                      >
                        <p class="p-title lh-18 mb-0">
                          <strong>PAUTEAR</strong>
                          <span class="p-caption">
                            TUS PUBLICACIONES (PUBLICIDAD)</span
                          >
                        </p>
                      </div>
                      <div class="" v-show="!step9">
                        <p
                          class="p-medium lh-14 textCenter m-sideRight p-sides"
                        >
                          <strong>Pauteo</strong> de
                          <strong>Posts</strong> (publicidad).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlS3: "https://image-store.isocialcube.com/advise",
      step1: true,
      step2: true,
      step3: true,
      step4: true,
      step5: true,
      step6: true,
      step7: true,
      step8: true,
      step9: true,
      step10: true,
      step11: true,
      step1Color: "gray",
      step2Color: "gray",
      step3Color: "gray",
      step4Color: "gray",
      step5Color: "gray",
      step6Color: "purpleGray",
      step7Color: "purpleGray",
      step8Color: "purpleGray",
      step9Color: "purpleGray",
      step10Color: "lightBlue",
      step11Color: "lightBlue",
      colorParagraph: "c-white",
      colorParagraph2: "c-white",
      colorParagraph3: "c-white",
      colorParagraph4: "c-white",
    };
  },
  methods: {
    stepsClick(id) {
      switch (id) {
        case 1:
          this.step1Color = "colorYellow";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;
        case 2:
          this.step1Color = "gray";
          this.step2Color = "colorYellow";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;
        case 3:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "colorYellow";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;
        case 4:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "colorYellow";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;
        case 5:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "colorYellow";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;
        case 6:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "colorYellow";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-black";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;
        case 7:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "colorYellow";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-black";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;
        case 8:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "colorYellow";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-black";
          this.colorParagraph4 = "c-white";
          break;
        case 9:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "colorYellow";
          this.step10Color = "lightBlue";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-black";
          break;
        case 10:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "colorYellow";
          this.step11Color = "lightBlue";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;
        case 11:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "gray";
          this.step5Color = "gray";
          this.step6Color = "purpleGray";
          this.step7Color = "purpleGray";
          this.step8Color = "purpleGray";
          this.step9Color = "purpleGray";
          this.step10Color = "lightBlue";
          this.step11Color = "colorYellow";
          this.colorParagraph = "c-white";
          this.colorParagraph2 = "c-white";
          this.colorParagraph3 = "c-white";
          this.colorParagraph4 = "c-white";
          break;

        default:
          break;
      }
      this.$emit("stepsClick", id);
    },
  },
};
</script>

<style>
.h-45 {
  height: 45%;
}

.h-55 {
  height: 55%;
}

.zIndex0 {
  z-index: 0;
}

.textEnd {
  text-align: end;
}

.w-25 {
  width: 25%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.h-10 {
  height: 10%;
}

.p-05 {
  padding: 0.5rem;
}

.p-1 {
  padding: 1rem;
}

.p-sides05 {
  padding: 0rem 0.5rem;
}

.p-sides1 {
  padding: 0rem 1rem;
}

.m-sideRight3 {
  margin-right: 3rem;
}

.rectangleGreatLeftTotal {
  height: 27rem;
  width: 100%;
}

.rectangleGreatLeftTopMedium {
  margin-top: 1rem;
  width: 100%;
  border-top-left-radius: 8rem;
  align-items: center;
}

.rectangleGreatLeftBottomMedium {
  width: 100%;
  border-bottom-left-radius: 8rem;
  align-items: center;
}

.rectangleLittleLeftTopMedium {
  height: 48%;
  width: 30%;
  bottom: 0;
  right: 0;
  border-top-left-radius: 1rem;
}

.rectangleLittleLeftBottomMedium {
  height: 48%;
  width: 30%;
  top: 0;
  right: 0;
  border-bottom-left-radius: 1rem;
}

.imgList {
  top: 48%;
  left: 48%;
  width: 4%;
}

.imgUser {
  width: 11%;
  right: 2%;
  top: 8%;
}

.imgTPhone {
  z-index: 2;
  width: 15%;
  top: 10%;
  left: 0;
}

.imgRrss {
  top: 26%;
  left: 17%;
  width: 4%;
}

.imgSearch {
  width: 4%;
  top: 27%;
  right: 18.5%;
}

.imgArrowBlue4 {
  width: 56%;
  left: 4%;
}

.imgNodosCyan {
  top: 0;
  right: 0;
  width: 12%;
}

.imgNodoSYellow {
  bottom: 48%;
  left: 25%;
  width: 2%;
}

.imgCellReactions {
  bottom: -1px;
  right: 50px;
  width: 20%;
}

.imgPieChart-audience {
  bottom: 21%;
  left: 14.5%;
  width: 4%;
}

.imgCoin {
  width: 9%;
  top: 5%;
  left: 3%;
}

.inf {
  bottom: 1%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.capa1 {
  background: transparent;
  width: 15%;
  height: 37%;
  z-index: 3;
  right: 3%;
  top: 8%;
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
}

.capa2 {
  width: 10%;
  height: 30%;
  background: transparent;
  z-index: 3;
  top: 30%;
  left: 3.5%;
  border-top-left-radius: 8rem;
}

.capa3 {
  width: 15%;
  height: 38%;
  z-index: 3;
  background: transparent;
  bottom: 10%;
  right: 3%;
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
}

.imgMegafonoCA-audience {
  bottom: 30%;
  left: 41%;
  width: 4%;
}

.number10 {
  padding: 0rem 0.3rem;
}

@media (max-width: 1440px) {
  .class2 {
    margin-right: 0rem !important;
  }
  .text1-audience {
    padding-right: 2rem !important;
  }
}

@media (max-width: 1325px) {
  .p-title-abl {
    font-size: 20px !important;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .imgTPhone {
    top: 13%;
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .imgTPhone {
    z-index: 2;
    width: 19%;
    top: 13%;
    left: 0px;
  }

  .imgUser {
    width: 15%;
    right: 2%;
    top: 8%;
  }

  .imgCellReactions {
    bottom: 3%;
    right: 4.4%;
    width: 25%;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .colSides {
    max-width: 20%;
  }

  .imgPieChart-audience {
    bottom: 22%;
    left: 19%;
    width: 4%;
  }

  .imgRrss {
    top: 27%;
    left: 21%;
    width: 5%;
  }

  .imgSearch {
    width: 5%;
    top: 27%;
    right: 22%;
  }

  .imgList {
    top: 57%;
    left: 48%;
    width: 4%;
  }

  .imgArrowBlue4 {
    width: 75%;
    left: -10%;
  }

  .imgNodoSYellow {
    left: 28%;
  }

  .imgCellReactions {
    bottom: 3%;
    right: 7%;
    width: 21%;
  }

  .imgUser {
    width: 13% !important;
  }
}
</style>
