<template>
    <div>
        <div class="tabAnalize-Monitor d-flex">
            <v-card>
                <v-tabs vertical>
                    <div class="containerTitleTab">
                        <p class="titleTab mb-0">Contenido</p>
                    </div>
                    <v-tab class="d-flex justifyFlexStart" >
                        <div class="titleTab titleTabNum titleTabNum2">1</div>
                        <div class="titleTab">Definiciones Generales</div>
                    </v-tab>
                    <v-tab class="d-flex justifyFlexStart" >
                        <div class="titleTab titleTabNum">2</div>
                        <div class="titleTab">Overview</div>
                    </v-tab>
                    <!-- <v-tab class="d-flex justifyFlexStart" >
                        <div class="titleTab titleTabNum">3</div>
                        <div class="titleTab">Trends</div>
                    </v-tab> -->
                    <v-tab class="d-flex justifyFlexStart" >
                        <div class="titleTab titleTabNum">3</div>
                        <div class="titleTab">Benchmark</div>
                    </v-tab>
                    <v-tab class="d-flex justifyFlexStart" >
                        <div class="titleTab titleTabNum">4</div>
                        <div class="titleTab">Campaigns</div>
                    </v-tab>

                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">1. Definiciones Generales:</p>
                                <ul class="paragraph-listen">
                                    <li>
                                        Tiene como objetivo potenciar las marcas de nuestros clientes y alcanzar sus metas de marketing digital en redes sociales a través de:<br>
                                        
                                        <ol>
                                            <li type="a">Análisis del rendimiento de sus redes sociales,</li>
                                            <li type="a">Lanzamiento, seguimiento y monitoreo de campañas publicitarias,</li>
                                            <li type="a">Evaluación de tendencias mundiales, y</li>
                                            <li type="a">Benchmark de su competencia y/o intereses.</li>
                                        </ol>
                                    </li>
                                </ul>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/process_data.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">2. Visión General:</p>
                                <ul class="paragraph-listen">
                                    <li>Muestra en Tableros de Mando los <strong>principales indicadores de las Redes Sociales del cliente.</strong></li>
                                    <li>Tiene capacidad de agrupar las Redes Sociales por unidad de negocio, permitiendo monitorear toda la organización desde una sola herramienta.</li>
                                </ul>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/monitor_overview_details.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                    <!-- <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">3. Tendencias:</p>
                                <ul class="paragraph-listen">
                                    <li>
                                        Muestra las tendencias en las Redes Sociales clasificándolas por <strong>Industria, País y Red Social.</strong>
                                    </li>
                                    <li>
                                        Permite descubrir que tipo de publicaciones generan mayores <strong>interacciones ligadas a nuestro mercado objetivo.</strong>
                                    </li>
                                    <li>
                                        Se analiza más de 4,000 empresas en Peru y Chile, a través de los indicadores de ISC Boards, para ayudar en la formación de <strong>estrategias más eficientes y obtener ganancias.</strong>
                                    </li>
                                </ul>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3}/Trends/graphic10.svg`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item> -->
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">3. Puntos de Referencia (Benchmark):</p>
                                <ul class="paragraph-listen">
                                    <li>
                                    Permite y facilita el análisis de la competencia. Este proceso se dá al comparar los indicadores de nuestros principales competidores en diferentes Redes Sociales. La idea es que la competencia tenga características similares a las nuestras en: tamaño, estructura y composición.
                                    </li>
                                    <li>
                                    Con este análisis se puede descubrir que prácticas de nuestra competencia tienen éxito (o no) para aplicarlas (o no) en nuestra estrategia.
                                    </li>
                                    <li>
                                    Podemos seguir los siguientes pasos para obtener los resultados esperados:
                                        <ul>
                                            <li type="disc"><strong>Definir Objetivos:</strong> Qué queremos conseguir.</li>
                                            <li type="disc"><strong>Ubica a tu competencia:</strong> Quien es y qué redes sociales utiliza.</li>
                                            <li type="disc"><strong>Indicadores:</strong> Que es lo más relevante a comparar. (Fans, Engagement, otros).</li>
                                            <li type="disc"><strong>Análisis:</strong> Comparar y determinar Fortalezas y Debilidades. Replicar lo bueno, aprender de lo malo.</li>
                                        </ul>
                                    </li>
                                </ul>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/monitor_compare_profiles.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat>
                            <v-card-text>
                                <p class="paragraphTitle-listen">4. Campañas:</p>
                                <ol class="paragraph-listen">
                                    <li><strong>Monitoreo:</strong>
                                        <ul>
                                            <li type="disc">
                                                Este sub-módulo permite monitorear las publicaciones realizadas en las diferentes redes sociales. Estas pueden ser: Videos, fotos o piezas de especiales de marketing.
                                            </li>
                                            <li type="disc">
                                                El monitoreo de la campaña genera indicadores de rendimiento que permiten darle 'golpes de timón' a la campaña para optimizar su resultado. Para ello tiene las siguientes partes:
                                                <ol>
                                                    <li><strong>TimeLine de Acciones:</strong> Ubica en el tiempo los hitos de la campaña. Haciendo click sobre sus elementos podemos ver el resumen de ellos.</li>
                                                    <li><strong>Conversión Funnel:</strong>
                                                        <dt>
                                                            <dd>- Apunta a evaluar el resultado de las acciones con orientación al objetivo trazado: Audiencia o Leads.</dd>
                                                            <dd>- Lista las 'Acciones' realizadas en las campañas, monitorea las impresiones que generan, filtra las interacciones derivadas y presenta los resultados del objetivo (Tiene una etapa de evaluación especial para ADDONS. Es vital usarlos en todas las campañas). </dd>
                                                            <dd>- A través del Embudo podemos ir tomando decisiones de continuar o cambiar las estrategias trazadas. Las campañas tienen por Objetivo el captar 'Audiencia' o generar 'Potenciales Ventas (Leads)'.</dd>
                                                        </dt>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/monitor_convension_funnel.webp`"
                                />
                            </div>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/monitor_timeline.webp`"
                                />
                            </div>
                            <v-card-text>
                                <ol class="paragraph-listen" start="2">
                                    <li><strong>Análisis:</strong></li>
                                    <ul>
                                        <li type="disc"><strong>Análisis de las Acciones:</strong>
                                            <ol>
                                                <li>Evaluar el alcance individual y conjunto de las publicaciones realizadas definiendo su impacto en la audiencia. Las clasifica en: Impresiones, alcance e interacciones.</li>
                                                <li>Evalúa el Sentimiento que se ha generado en la Audiencia con las plublicaciones realizadas.</li>
                                                <li>Habilita la evaluación del rendimiento del ADDON en la campaña y permite la descarga de información de potenciales clientes/leads que interactúan con el ADDON.</li>
                                            </ol>
                                        </li>
                                        <li type="disc"><strong>Análisis de la Audiencia y la Conversión:</strong>
                                            <ol>
                                                <li>Compara el avance de la campaña con sus indicadores de Audiencia iniciales por Red Social. En el caso de Facebook puede proporcionar una distinción de género y edad de la audiencia.</li>
                                                <li>Analiza los intereses de la audiencia objetivo (Ver detalle en 'Observatorio de Intereses de la Audiencia').</li>
                                                <li>Analiza los ratios de conversión totales de la campaña y la eficiencia de cada Red Social en la misma.</li>
                                            </ol>
                                        </li>
                                        <li type="disc"><strong>Análisis de la Semántica y Emociones:</strong>
                                            <ol>
                                                <li>
                                                    Presenta una CSA Semántico de los posts de la campaña y de las interacciones generadas por la misma. La lógica con que se organiza es la siguiente:
                                                    <ol>
                                                        <li type="a">Hace un ranking de las palabras utilizadas en los comentarios de los Posts y extrae las 10 más utilizadas. Estas palabras se convertirán en Nodos Centrales.</li>
                                                        <li type="a">A partir de este primer filtro se hace un ranking de las 10 palabras más utilizadas alrededor de cada Nodo Central. Éstas se convierten en Nodos Relacionados y se relacionan entre sí (Y con los Nodos Centrales) conectándose con Enlaces.</li>
                                                        <li type="a">Se puede armar un árbol de máximo 100 nodos (Habrá casos en que serán menos porque no necesariamente se llega al máximo número de palabras citado en la lógica).</li>
                                                        <li type="a">El color del Nodo representa el sentimiento que genera esa palabra dentro del ontexto (Verde=Positivo, Rojo=Negativo, Gris=Neutral).</li>
                                                        <li type="a">En el centro de cada Nodo Central está el icono de la Red Social que genera la data.</li>
                                                        <li type="a">El color del Enlace representa el sentimiento generado por la relación entre los Nodos dentro del Contexto.</li>

                                                    </ol>
                                                </li>
                                                <li>Los Posts y sus interacciones generan una tendencia de emociones generadas que también se muestran en este tab.</li>
                                            </ol>
                                        </li>
                                    </ul>
                                </ol>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/2-action-2.webp`"
                                />
                            </div>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/3-audience.webp`"
                                />
                            </div>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/4-semantic.webp`"
                                />
                            </div>
                            <v-card-text>
                                <ol class="paragraph-listen" start="3">
                                    <li><strong>Resumen General: Campaign Scorecards</strong>
                                        <ul>
                                            <li type="disc">
                                                Este sub-módulo <strong>evalúa holísticamente todas las campañas realizadas</strong> con la finalidad de recoger las buenas prácticas de negocio en la ejecución de las mismas. La realización de campañas de marketing en redes sociales es un ejercicio de 'prueba y error', al reconocer en su ejecución las cosas buenas que se hizo se puede repetir el éxito en las mismas. 
                                            </li>
                                        </ul>
                                    </li>
                                    <li><strong>Observatorio de Intereses de la Audiencia:</strong>
                                        <ul>
                                            <li type="disc">
                                                Se encuentra dentro del sub-módulo de 'Campaigns' en el tab de 'Análisis de la Audiencia y la Conversión', pero tiene una importancia vital y por ello se describe aparte. Está geo-referenciado y segmentado por Industria, esto permite analizar el interés de audiencias geo-referenciadas a nivel de Distrito| Comuna en Chile y Perú. 
                                            </li>
                                            <li type="disc">
                                                <strong>Toma en cuenta las escuchas en Twitter que ISC hace en los Tableros por Industria (En LISTEN),</strong> las geo-referencia y hace un diagnóstico del género y de los sentimientos detectados en base al interés seleccionado. 
                                            </li>
                                            <li type="disc">
                                                Una de sus tantas aplicaciones es identificar posibles falencias en la llegada del mensaje por falta de interés de la audiencia durante una campaña.
                                            </li>
                                        </ul>
                                    </li>
                                </ol>
                            </v-card-text>
                            <div class="container-imgGraphic">
                                <img
                                    class="imgGraphic"
                                    :src="`${urlS3Advice}/1-overview-account-audience.webp`"
                                />
                            </div>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
            </v-card>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            model: 'tab-3',
            urlS3:"https://isc-store.s3.amazonaws.com/advise/Monitor",
            urlS3Advice:"https://image-store.isocialcube.com/advise"
        }
    }
}
</script>
<style>
.justifyFlexStart{
    justify-content: flex-start;
}
.container-imgGraphic{
    display: flex;
    justify-content: center;
    padding: 2rem 0rem;
}
.tabAnalize-Monitor .imgGraphic{
    width: 100%;
}
.paragraphTitle-listen{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    color: #00A5FF;
}
.paragraph-listen{
    font-family: 'Oxygen';
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: justify;
    color: #5D6B88;
}

.tabAnalize-Monitor .titleTab{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #2C3852;
}

.tabAnalize-Monitor .v-sheet{
    box-shadow: none !important;
}

.tabAnalize-Monitor .v-slide-group__content .v-tab--active{
    background: #EBF8FF;
}

.tabAnalize-Monitor .v-tabs-slider-wrapper{
    color: transparent !important;
    width: 0rem;
}

.tabAnalize-Monitor .v-card .v-tabs {
    width: 100% !important;
}

.tabAnalize-Monitor .v-slide-group--has-affixes{
    width: 15% !important;
}

.tabAnalize-Monitor .v-window{
    width: 85% !important;
}

.tabsAnalize .v-card__text{
    padding: 0% !important;
}



.tabAnalize-Monitor .titleTab{
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #2C3852;
    text-transform: none;
    text-align: start;
    letter-spacing: normal;
}

.tabAnalize-Monitor .v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text{
    width: 14rem;
}

.tabAnalize-Monitor .v-tabs--vertical>.v-tabs-bar .v-tabs-bar__content {
    white-space: unset !important;
    flex: auto !important;
    box-shadow: 3px 4px 5px rgb(0 0 0 / 10%);
    margin-right: 1rem;
}

.tabAnalize-Monitor .titleTabNum2{
    height: 65%
}

.tabAnalize-Monitor .titleTabNum {
    padding-right: 1rem;
}

.containerTitleTab{
    padding: 0 16px;
    height: 48px;
    margin-top: 2rem;
}

.tabAnalize-Monitor .v-tab{
    padding: 0rem 3rem 0rem 16px;
}

.tabAnalize-Monitor .titleTabNum2{
    height: 65%
}

.tabAnalize-Monitor .titleTabNum {
    padding-right: 1rem;
}

.tabAnalize-Monitor .v-window-item .v-card {
    padding: 2rem 5rem;
}

@media (max-width: 1180px) {
    .tabAnalize-Monitor .v-window-item .v-card{
        padding: 2rem 3rem;
    }
    /* .tabAnalize-Monitor .v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text{
        width: 25%;
    } */
}

</style> 