<template>
  <div class="w-100 white positionRelative">
    <div>
      <img
        class="positionAbsolute imgArrow2-analysis"
        :src="`${urlS3}/Flecha-2IA.webp`"
      />
      <img
        class="positionAbsolute imgListMg-analysis"
        :src="`${urlS3}/Lista-lupa.webp`"
      />
      <img
        class="positionAbsolute zIndex2 imgMonitor"
        :src="`${urlS3}/monitor.webp`"
      />
      <img
        class="positionAbsolute zIndex3 imgBarGraphic"
        :src="`${urlS3}/graficos-barras.webp`"
      />
      <img
        class="positionAbsolute zIndex2 imgGirlLap"
        :src="`${urlS3}/Chica-laptop.webp`"
      />
      <img
        class="positionAbsolute zIndex2 imgNodoYellow"
        :src="`${urlS3}/nodos-amarillo.webp`"
      />
      <img
        class="positionAbsolute zIndex1 imgCellHands"
        :src="`${urlS3}/manos-celular.webp`"
      />
      <img
        class="positionAbsolute zIndex1 imgPaperPlane"
        :src="`${urlS3}/avion-de-papel.webp`"
      />
      <img
        class="positionAbsolute zIndex3 imgCircleArrow"
        :src="`${urlS3}/flechas-circulo.webp`"
      />
      <img
        class="positionAbsolute zIndex3 imgNodoLightblue"
        :src="`${urlS3}/nodos-celeste.webp`"
      />
      <div
        class="d-flex positionAbsolute zIndex3 inf-analysis3"
        style="justify-content: end"
      >
        <div class="indicativeText2 p-05 d-flex fDirectionCReverse">
          <p class="mb-0">(LISTEN + MONITOR)</p>
          <p class="mb-0">
            <strong>SUPERVISIÓN Y RETROALIMENTACIÓN</strong>
          </p>
        </div>
        <img class="imgArrow3" :src="`${urlS3}/Flecha-3AI.webp`" />
      </div>
      <div class="d-flex positionAbsolute w-100" style="height: 30%">
        <div class="w-20 positionRelative subtitle-analysis">
          <p
            class="p-titleMap mb-0 c-letter"
            style="padding: 3rem 0rem 0rem 3rem !important"
            v-show="title == 'ANÁLISIS DE MERCADO'"
          >
            <span class="p-title-aps">ANÁLISIS</span><br />
            <span class="p-title-aps">DE MERCADO</span>
          </p>
          <p
            class="p-titleMap mb-0 c-letter"
            style="padding: 3rem 0rem 0rem 3rem !important"
            v-show="title == 'ANÁLISIS DE MARCA'"
          >
            <span class="p-title-aps">ANÁLISIS</span><br />
            <span class="p-title-aps">DE MARCA</span>
          </p>
          <p
            class="p-titleMap mb-0 c-letter"
            style="padding: 3rem 0rem 0rem 3rem !important"
            v-show="title == 'ANÁLISIS DE PRODUCTO/SERVICIO'"
          >
            <span class="p-title-aps">ANÁLISIS</span><br />
            <span class="p-title-aps">DE PRODUCTO/</span><br />
            <span class="p-title-aps">SERVICIO</span>
          </p>

          <div class="indicativeText2 p-05 positionAbsolute inf-analysis1">
            <p class="mb-0">
              <strong>IMPLEMENTACIÓN</strong>
            </p>
            <p class="mb-0">(ESTRATEGIA + ACCIONES CLAVE)</p>
          </div>
        </div>
        <div class="w-20 positionRelative">
          <img
            class="positionAbsolute w-10"
            style="left: 45%; width: 7%"
            :src="`${urlS3}/Reloj.webp`"
          />
          <a class="w-100 textDecorationNone c-letter" @click="stepsClick(0)">
            <div
              class="d-flex justifyCenter alignItemsCenter fDirectionColumn h-100"
            >
              <p class="p-subMap1 mb-0">ANTES</p>
              <p class="p-subMap2 mb-0">DE EMPEZAR...</p>
            </div>
          </a>
        </div>
        <div class="w-55 p-05 d-flex justifyEnd">
          <div class="d-flex justifyEnd" style="padding-top: 0.7rem">
            <img style="height: 8%" :src="`${urlS3}/nodos-cyan.webp`" />
          </div>
          <div class="indicativeText2 p-05 inf-analysis2">
            <p class="mb-0">
              <strong>ACCIONES PREVIAS</strong>
            </p>
            <p class="mb-0">(LISTEN + MONITOR)</p>
          </div>
          <div class="w-10" style="padding-top: 0.5rem">
            <img style="width: 90%" :src="`${urlS3}/Flecha-1.webp`" />
          </div>
        </div>
      </div>

      <div
        class="containerMap d-flex"
        style="
          padding: 2.5rem 3rem !important;
          justify-content: center !important;
        "
      >
        <div class="d-flex w-100">
          <!-- COL 1 -->
          <v-col class="colSides p-0">
            <v-row class="row1">
              <div class="rectangle"></div>
              <div class="space"></div>
              <div class="rectangleGreatLeftTotal">
                <div class="h-100">
                  <a
                    class="w-100 textDecorationNone positionRelative"
                    @click="stepsClick(4)"
                  >
                    <div
                      :class="step4Color"
                      class="d-flex rectangleGreatLeft2"
                      v-on:mouseleave="step4 = true"
                      v-on:mouseover="step4 = false"
                    >
                      <div
                        class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter m-sidesCustom2"
                        :class="colorParagraph"
                      >
                        <div
                          class="h-30 w-100 d-flex alignItemsFEnd justifyCenter"
                        >
                          <div class="numberCustom BgLightBlue c-letter">4</div>
                        </div>
                        <div class="h-70 ptp-1">
                          <div v-show="step4" class="textCenter">
                            <p class="p-title lh-18 mb-5px">
                              <strong>ESCUCHAR LAS RR.SS.</strong>
                            </p>
                            <p class="p-caption lh-14">
                              UTILIZA LISTEN Y MONITOR PARA GENERAR DE INSIGHTS
                            </p>
                          </div>
                          <div v-show="!step4">
                            <p class="p-medium lh-14">
                              <strong>a) Descubre el ‘Insight’:</strong> ¿Cómo
                              lo conseguimos y para q nos sirve
                            </p>
                            <p class="p-medium lh-14">
                              <strong>b) Configura tu escucha</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="white rectangleLittleLeft2"></div>
                    </div>
                  </a>
                  <div class="space"></div>
                  <div class="w-100 d-flex">
                    <div class="w-70"></div>
                    <div class="w-30">
                      <div class="rectangle">
                        <a
                          class="w-100 textDecorationNone"
                          @click="stepsClick(6)"
                        >
                          <div
                            :class="step6Color"
                            class="rectangleMedium b-r"
                            v-on:mouseleave="step6 = true"
                            v-on:mouseover="step6 = false"
                          ></div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-row>
          </v-col>
          <!-- COL 2 -->
          <v-col class="w-70 p-0">
            <v-row>
              <div class="w-100 d-flex fDirectionRReverse">
                <div class="w-55">
                  <div class="rectangle positionRelative">
                    <div class="number BgBlue c-white">1</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(1)">
                      <div
                        :class="step1Color"
                        class="rectangleMedium b-r"
                        v-on:mouseleave="step1 = true"
                        v-on:mouseover="step1 = false"
                      >
                        <div class="dataContent c-letter textCenter">
                          <div v-show="step1">
                            <p class="p-title mb-03">
                              <strong>IDENTIFICAR</strong>
                            </p>
                            <p class="p-caption c-letter">
                              QUÉ TIPO DE EVALUACIONES SE PUEDE OBTENER DE ISC
                              LISTEN
                            </p>
                          </div>
                          <div v-show="!step1" class="p-sides3">
                            <p class="p-medium lh-14">
                              Las evaluaciones tiene asociados
                              <strong>múltiples indicadores (KPIs)</strong> que
                              extraemos por ti de las RRSS. Cada una te
                              proporcionará <strong>‘insights’</strong> a través
                              de los <strong>KPIs que arroja.</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space"></div>
              <div class="w-100 d-flex">
                <div class="w-20">
                  <div class="rectangle">
                    <a class="w-100 textDecorationNone" @click="stepsClick(4)">
                      <div
                        :class="step4Color"
                        class="rectangleMedium"
                        v-on:mouseleave="step4 = true"
                        v-on:mouseover="step4 = false"
                      ></div>
                    </a>
                  </div>
                </div>
                <div class="w-60">
                  <div class="rectangle">
                    <div class="number BgBlue c-white">3</div>
                    <a class="w-100 textDecorationNone" @click="stepsClick(3)">
                      <div
                        :class="step3Color"
                        class="rectangleMedium b-r b-l d-flex justifyCenter"
                        v-on:mouseleave="step3 = true"
                        v-on:mouseover="step3 = false"
                      >
                        <div class="dataContent c-letter">
                          <div v-show="step3">
                            <p class="lh-14 mb-03 p-title textCenter">
                              <strong>ANALIZAR</strong>
                            </p>
                            <p class="p-caption c-letter textCenter">
                              QUE DRIVERS ESCUCHARÁS
                            </p>
                          </div>
                          <div v-show="!step3">
                            <p class="p-medium lh-14">
                              Los Drivers son
                              <strong>la clave de tu escucha.</strong> Sin ellos
                              no podrás obtener datos y en consecuencias:
                              <strong>Insights.</strong>
                            </p>
                          </div>
                        </div>
                        <img
                          class="positionAbsolute zIndex2 imgIcons"
                          :src="`${urlS3}/Iconos-rrss.webp`"
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-20">
                  <div class="rectangle">
                    <a class="w-100 textDecorationNone" @click="stepsClick(2)">
                      <div
                        :class="step2Color"
                        class="rectangleMedium"
                        v-on:mouseleave="step2 = true"
                        v-on:mouseover="step2 = false"
                      ></div>
                    </a>
                  </div>
                </div>
              </div>
              <div class="space d-flex justifyCenter"></div>
              <div class="w-100 d-flex">
                <img
                  class="positionAbsolute zIndex1 imgAnalysis"
                  :src="`${urlS3}/analista.webp`"
                />
                <div class="w-50" style="padding: 0px !important">
                  <div class="w-100 d-flex" style="box-sizing: unset">
                    <div class="w-50 positionRelative">
                      <div class="rectangle positionAbsolute zIndex3">
                        <a
                          class="w-100 textDecorationNone"
                          @click="stepsClick(4)"
                        >
                          <div
                            :class="step4Color"
                            class="rectangleMedium b-r"
                            v-on:mouseleave="step4 = true"
                            v-on:mouseover="step4 = false"
                          >
                            <div class="w-100">
                              <div class="w-50 d-flex justifyCenter">
                                <img
                                  class="imgImageViews"
                                  :src="`${urlS3}/imagen-vistas.webp`"
                                />
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="w-50">
                      <div class="rectangle">
                        <a
                          class="w-100 textDecorationNone"
                          @click="stepsClick(5)"
                        >
                          <div
                            :class="step5Color"
                            class="rectangleMedium b-l class5-analysis"
                            v-on:mouseleave="step5 = true"
                            v-on:mouseover="step5 = false"
                          ></div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="space d-flex justifyCenter"></div>
                  <div class="rectangle">
                    <div class="number BgBlue c-white positionAbsolute zIndex3">
                      6
                    </div>
                    <a
                      class="w-100 textDecorationNone positionRelative"
                      @click="stepsClick(6)"
                    >
                      <div
                        :class="step6Color"
                        class="rectangleMedium b-r positionAbsolute zIndex2"
                        v-on:mouseleave="step6 = true"
                        v-on:mouseover="step6 = false"
                      >
                        <div class="dataContent c-letter textCenter">
                          <div v-show="step6">
                            <p class="lh-14 mb-0 p-title">
                              <strong
                                >¡CRUZAR INSIGHTS, GENERAR DIAGNÓSTICOS Y AFINAR
                                ESCUCHAS!</strong
                              >
                            </p>
                          </div>
                          <div v-show="!step6">
                            <p class="p-medium lh-14">
                              ¡Escucha y genera <strong>Insights!</strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="w-50" style="padding: 0px !important">
                  <div class="w-50 widthRectangleGreatRight">
                    <a
                      class="w-100 textDecorationNone positionRelative"
                      @click="stepsClick(5)"
                    >
                      <div
                        :class="step5Color"
                        class="d-flex rectangleGreatRight"
                        v-on:mouseleave="step5 = true"
                        v-on:mouseover="step5 = false"
                      >
                        <div
                          class="white rectangleLittleRight d-flex alignItemsCenter justifyCenter"
                        >
                          <img
                            class="w-70 imgFoco"
                            :src="`${urlS3}/foco.webp`"
                          />
                        </div>
                        <div
                          class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter c-letter m-sidesCustom1"
                        >
                          <div
                            class="h-30 w-100 d-flex alignItemsFEnd justifyCenter"
                          >
                            <div class="numberCustom BgBlue c-white">5</div>
                          </div>
                          <div class="h-70 ptp-1">
                            <div v-show="step5" class="textCenter">
                              <p class="p-title lh-18 mb-0">
                                <strong>¿QUÉ PREGUNTAS</strong>
                              </p>
                              <p class="p-caption lh-14">
                                PUEDES RESPONDER CON TU ESCUCHA?
                              </p>
                            </div>
                            <div v-show="!step5" class="textEnd">
                              <p class="p-medium lh-14">
                                Debes hacer
                                <strong>escuchas periódicamente</strong> para
                                una mejora continua: el escenario debe
                                <strong>cambiar permanentemente.</strong>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </v-row>
          </v-col>
          <!-- COL 3 -->
          <v-col class="colSides p-0">
            <v-row>
              <a
                class="w-100 textDecorationNone positionRelative"
                @click="stepsClick(2)"
              >
                <div
                  :class="step2Color"
                  class="d-flex rectangleGreatRight"
                  v-on:mouseleave="step2 = true"
                  v-on:mouseover="step2 = false"
                >
                  <div
                    class="white rectangleLittleRight"
                    style="width: 20%"
                  ></div>
                  <div
                    class="h-100 dataContent d-flex fDirectionColumn alignItemsCenter c-letter textCenter m-sidesCustom1 class2-analysis"
                    style="width: 80%"
                  >
                    <div class="h-30 w-100 d-flex alignItemsFEnd justifyCenter">
                      <div class="numberCustom BgBlue c-white">2</div>
                    </div>
                    <div class="h-70 ptp-1">
                      <div v-show="step2">
                        <p class="p-title lh-18 mb-5px">
                          <strong>DOCUMENTA Y DESCRIBE</strong>
                        </p>
                        <p class="p-caption lh-14">
                          EN DETALLE LOS REQUERIMIENTOS DE ANÁLISIS
                        </p>
                      </div>
                      <div v-show="!step2">
                        <p class="p-medium lh-14">
                          Debes hacer una descripción detallada de lo que
                          <strong>quieres lograr con tu escucha.</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </v-row>
          </v-col>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlS3: "https://image-store.isocialcube.com/advise",
      step1: true,
      step2: true,
      step3: true,
      step4: true,
      step5: true,
      step6: true,
      step1Color: "gray",
      step2Color: "gray",
      step3Color: "gray",
      step4Color: "purpleGray",
      step5Color: "lightBlue",
      step6Color: "lightBlue",
      colorParagraph: "c-white",
    };
  },
  props: {
    title: null,
  },
  methods: {
    stepsClick(id) {
      switch (id) {
        case 1:
          this.step1Color = "colorYellow";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "purpleGray";
          this.step5Color = "lightBlue";
          this.step6Color = "lightBlue";
          this.colorParagraph = "c-white";
          break;
        case 2:
          this.step1Color = "gray";
          this.step2Color = "colorYellow";
          this.step3Color = "gray";
          this.step4Color = "purpleGray";
          this.step5Color = "lightBlue";
          this.step6Color = "lightBlue";
          this.colorParagraph = "c-white";
          break;
        case 3:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "colorYellow";
          this.step4Color = "purpleGray";
          this.step5Color = "lightBlue";
          this.step6Color = "lightBlue";
          this.colorParagraph = "c-white";
          break;
        case 4:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "colorYellow";
          this.step5Color = "lightBlue";
          this.step6Color = "lightBlue";
          this.colorParagraph = "c-letter";
          break;
        case 5:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "purpleGray";
          this.step5Color = "colorYellow";
          this.step6Color = "lightBlue";
          this.colorParagraph = "c-white";
          break;
        case 6:
          this.step1Color = "gray";
          this.step2Color = "gray";
          this.step3Color = "gray";
          this.step4Color = "purpleGray";
          this.step5Color = "lightBlue";
          this.step6Color = "colorYellow";
          this.colorParagraph = "c-white";
          break;
        default:
          break;
      }
      this.$emit("stepsClick", id);
    },
  },
};
</script>
<style>
.rectangleGreatLeft2 {
  margin-top: 1rem;
  height: 17rem;
  width: 100%;
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
  align-items: center;
}
.rectangleLittleLeft2 {
  height: 18%;
  width: 40%;
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
}

.inf-analysis1 {
  bottom: 5%;
  right: 18%;
  width: 50%;
}

.inf-analysis3 {
  bottom: 3%;
  right: 34%;
}

.imgArrow2-analysis {
  width: 5%;
  top: 23%;
  left: 2%;
}

.imgListMg-analysis {
  width: 14%;
  top: 6%;
  left: 36%;
  z-index: 2;
}

.imgMonitor {
  width: 25%;
  bottom: 2%;
  right: 5%;
}

.imgBarGraphic {
  bottom: 0%;
  width: 25%;
}

.imgGirlLap {
  width: 6%;
  right: 14.5%;
  top: 17%;
}

.imgNodoYellow {
  width: 2%;
  right: 25%;
  top: 25%;
}

.imgIcons {
  width: 30%;
  bottom: 15%;
}

.imgCellHands {
  width: 13%;
  top: 36%;
  left: 19%;
}

.imgPaperPlane {
  width: 6%;
  top: 48%;
}

.imgImageViews {
  width: 80%;
}

.imgAnalysis {
  width: 6%;
  top: 56%;
  left: 42%;
}

.imgCircleArrow {
  bottom: 13%;
  right: 47%;
  width: 5%;
}
.imgNodoLightblue {
  width: 2%;
  top: 47.5%;
  right: 47.5%;
}

.imgArrow3 {
  width: 20%;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mb-03 {
  margin-bottom: 0.3rem !important;
}

.w-15 {
  width: 15%;
}

.fDirectionCReverse {
  flex-direction: column-reverse;
}

@media (max-width: 2700px) {
  .imgAnalysis {
    width: 6% !important;
    top: 55% !important;
  }
}

@media (max-width: 2600px) {
  .p-title-aps {
    font-size: 22px !important;
    line-height: 26px !important;
  }
}

@media (max-width: 1500px) {
  .p-title-aps {
    font-size: 20px !important;
    line-height: 18px !important;
  }

  .imgCellHands{
    top: 37%;
  }
}

@media (max-width: 1300px) {
  .p-title-aps {
    font-size: 16px !important;
  }
}

@media (max-width: 1150px) {
  .p-title-aps {
    font-size: 15px !important;
  }
}

@media ((max-width: 2400px) and (min-width: 2200px)) {
  .class5-analysis {
    border-top-right-radius: 3rem;
  }
}

@media (max-width: 2400px) {
  .imgAnalysis {
    width: 7% !important;
  }
}

@media (max-width: 2200px) {
  .imgAnalysis {
    width: 6% !important;
    top: 52% !important;
  }

  .imgBarGraphic {
    width: 23% !important;
  }

  .imgMonitor {
    width: 24%;
    right: 7%;
  }
}

@media (max-width: 2000px) {
  .imgMonitor {
    width: 27%;
    right: 7%;
  }
}

@media ((max-width: 2000px) and (min-width: 1850px)) {
  .imgAnalysis {
    width: 6% !important;
    top: 54% !important;
  }
}

@media (max-width: 1900px) {
  .imgGirlLap {
    right: 16.5%;
  }
}

@media (max-width: 1850px) {
  .imgAnalysis {
    top: 56% !important;
  }

  .imgBarGraphic {
    width: 23%;
  }
}

@media ((max-width: 1580px) and (min-width: 1400px)) {
  .imgAnalysis {
    width: 6% !important;
    top: 57% !important
  }
}

@media (max-width: 1450px) {
  .imgGirlLap {
    right: 19% !important;
  }
}

@media (max-width: 1440px) {
  .class2-analysis {
    margin: 0rem;
  }

  .imgMonitor {
    width: 30% !important;
  }
}

@media (max-width: 1400px) {
  .imgCellHands {
    left: 23% !important;
    top: 38% !important;
  }

  .class5-analysis {
    border-top-right-radius: 3rem !important;
  }

  .imgAnalysis {
    left: 43% !important;
    top: 58% !important;
  }

  .widthRectangleGreatRight {
    width: 80%;
  }

  .rectangleLittleRight {
    width: 50%;
    justify-content: end;
  }

  .imgFoco {
    width: 60%;
    padding-right: 1rem;
  }

  .imgMonitor {
    width: 22% !important;
    bottom: 7%;
  }

  .inf-analysis3 {
    right: 29%;
  }

  .imgFoco {
    width: 70%;
  }

  .imgBarGraphic {
    width: 27% !important;
  }
}

@media (max-width: 1360px) {
  .imgAnalysis {
    top: 59% !important;
    left: 44% !important;
  }
}

@media (max-width: 1340px) {
  .class5-analysis {
    border-top-right-radius: 4rem;
  }

  .imgCellHands {
    top: 39% !important;
  }
}

@media (max-width: 1240px) {
  .imgCellHands {
    top: 40% !important;
  }
}

@media (max-width: 1250px) {
  .imgAnalysis {
    top: 60% !important;
  }

  .imgMonitor {
    width: 21% !important;
  }
}

@media (max-width: 1200px) {
  .imgGirlLap {
    right: 20% !important;
    top: 18%;
  }
}

@media (max-width: 1190px) {
  .imgAnalysis {
    width: 7% !important;
    top: 58% !important;
    left: 45% !important;
  }

  .imgListMg-analysis {
    top: 9%;
  }

  .imgCellHands {
    top: 40% !important;
  }
}

@media ((max-width: 1130px) and (min-width: 1096px)) {
  .imgCellHands {
    top: 41% !important;
  }
}

@media (max-width: 1096px) {
  .subtitle-analysis {
    width: 20% !important;
  }

  .imgListMg-analysis {
    width: 12%;
    left: 39%;
    top: 11%;
  }

  .imgMonitor {
    width: 20% !important;
    bottom: 7%;
  }

  .imgBarGraphic {
    width: 31% !important;
  }

  .imgCellHands {
    top: 42% !important;
  }

  .imgGirlLap {
    top: 19% !important;
  }

  .imgAnalysis {
    top: 60% !important;
  }
}

@media ((max-width: 2350px) and (min-width: 2200px)) {
  .imgCellHands {
    top: 37% !important;
  }
}

@media ((max-width: 2200px) and (min-width: 2000px)) {
  .imgCellHands {
    width: 11% !important;
    top: 36% !important;
  }
}
</style>
