import Vue from "vue";
import VueRouter from "vue-router";
/* import Addon from "../components/addon"; */
import DoItYourself from '@/views/DoItYourself';

Vue.use(VueRouter);

const routes = [
  {
    path: "/do-it-yourself",
    name: "do-it-yourself",
    component: DoItYourself
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
