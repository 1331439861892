<template>
  <v-card v-if="step != null" class="pt-1">
    <div>
      <v-toolbar class="mx-1" color="#F3F4F8" flat>
        <v-toolbar-title class="step_title">
          {{ tab_title }}
        </v-toolbar-title>
      </v-toolbar>
    </div>
    <div class="step_main_content">
      <!-- Step 0 -->
      <v-card class="px-7 step_container" flat v-if="step == 0">
        <v-card-title class="step_subtitle1">
          0. Antes de Empezar
        </v-card-title>
        <span class="step_subtitle2"> 1. Acciones previas: </span>
        <v-card-text class="step_text start"
          ><p>
            Es necesario definir los siguientes puntos antes de realizar un
            análisis y plantear una estrategia.
          </p></v-card-text
        >
        <v-card-text class="step_text"
          ><p>
            a) Mapear las RRSS actuales e ingresarlas a ISC (Si no posee ninguna
            saltar este paso):
          </p></v-card-text
        >
        <v-card-text class="step_text step_list"
          ><ul>
            <li>Facebook.</li>
            <li>Twitter.</li>
            <li>Instagram.</li>
            <li>LinkedIn.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step0_1.webp`"
            @click="openImgDialog(`${urlS3}/step0_1.webp`)"
          />
        </div>
        <v-card-text class="step_text"
          ><p>b) Definir Características del Público Objetivo:</p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>Género.</li>
            <li>Edad.</li>
            <li>Ubicación.</li>
            <li>Necesidades.</li>
            <li>Motivaciones.</li>
            <li>Hábitos y hobbies.</li>
            <li>NSE (Nivel Socio Económico).</li>
            <li>Hábitos en Social Media.</li>
          </ul>
        </v-card-text>
        <span class="step_subtitle2"> 2. Definir objetivos específicos: </span>
        <v-card-text class="step_text start"
          ><p>Establecer objetivos realistas y alcanzables</p>
        </v-card-text>
        <span class="step_subtitle2"> 3. Realizar Benchmarking: </span>
        <v-card-text class="step_text start"
          ><p>
            ¿Quiénes son los competidores directos e indirectos? ¿Cuáles son sus
            RRSS?
          </p>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row no-gutters>
              <v-col
                v-for="(header, index) in tables.step_0.headers"
                :key="index"
                style="width: 14%; max-width: 14%; flex-basis: 14%"
              >
                <v-card
                  outlined
                  tile
                  class="table_step header d-flex align-center justify-center"
                  height="70px"
                >
                  <v-card-text
                    class="d-flex align-center justify-center header"
                    >{{ header }}</v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-for="(cols, index1) in tables.step_0.bodies"
              :key="index1"
            >
              <v-col
                v-for="(item, index2) in cols"
                :key="index2"
                style="width: 14%; max-width: 14%; flex-basis: 14%"
              >
                <v-card
                  outlined
                  tile
                  class="table_step d-flex align-center justify-center"
                  height="70px"
                >
                  <v-card-text class="d-flex align-center justify-center">{{
                    item
                  }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- Step 1 -->
      <v-card class="px-7 step_container" flat v-if="step == 1">
        <v-card-title class="step_subtitle1">
          <p>
            1. Identificar el tipo de evaluaciones que se obtienen desde LISTEN
            en Twitter y Facebook
          </p>
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            Es necesario conocer el panorama en el sector o industria desde las
            redes sociales, de esta manera la empresa puede aprovechar
            oportunidades y evitar amenazas identificadas en LISTEN. Además, a
            partir de este análisis se pueden conocer Insights que son usados
            estratégicamente para realizar una campaña de lanzamiento exitosa.
            LISTEN escucha a diversas industrias con 'ISC Boards' y geolocaliza
            aquellos Drivers que tienen datos en 'GEO Listening'. Puedes
            seleccionar Drivers de esas escuchas para complementar la tuya y/o
            usar los diagnósticos de cada industria directamente. La idea es que
            respondas:
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              ¿Cuáles son los Drivers que se usaron en la investigación de la
              industria?
            </li>
            <li>
              ¿Cuáles son los Drivers con más interacciones? De ellos: ¿Cuáles
              puedo usar en el lanzamiento de mi perfil social?
            </li>
            <li>
              ¿Cuál es el sentimiento predominante y cuáles son las razones de
              este sentimiento?
            </li>
            <li>
              ¿Cuál es género que interactúa más con la industria o Drivers
              seleccionados?
            </li>
            <li>¿Cuáles son las emociones más predominantes por día?</li>
            <li>
              ¿Cuáles son los influencers o fans que la empresa puede usar
              estratégicamente en el lanzamiento (A través del Ranking de
              Influencers)?
            </li>
            <li>
              ¿Qué es lo que publican los influencers de acuerdo a los Drivers
              colocados?
            </li>
            <li>
              ¿Cómo reacciona la comunidad de los influencers de acuerdo a su
              contenido?
            </li>
            <li>
              ¿Cuáles son los Trending topics o hashtags más usados que puedes
              usar estratégicamente en el lanzamiento?
            </li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_1.webp`"
            @click="openImgDialog(`${urlS3}/step1_1.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_2.webp`"
            @click="openImgDialog(`${urlS3}/step1_2.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_3.webp`"
            @click="openImgDialog(`${urlS3}/step1_3.webp`)"
          />
        </div>
        <v-card-text class="step_text">
          <p>
            Además, se pueden crear contextos personalizados seleccionando
            Drivers 'Stand Alone' que complementen el análisis para obtener
            Insights. Estos últimos se utilizan en la planificación estratégica
            de una campaña de lanzamiento exitosa. Para ello, se recomienda
            tener en cuenta:
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              <b>Analizar los 'ISC Boards'</b> con énfasis en los CSA (Cluster
              Social Analytics). Esto te ayuda a escoger Drivers que generan
              tráfico.
            </li>
            <li><b>Definir y configurar</b> los Drivers para escuchar.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step1_4.webp`"
            @click="openImgDialog(`${urlS3}/step1_4.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 2 -->
      <v-card class="px-7 step_container" flat v-if="step == 2">
        <v-card-title class="step_subtitle1">
          <p>
            2. Identificar el tipo de evaluaciones que se obtienen desde MONITOR
            a través del mapeo de competidores
          </p>
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            Realizar un benchmarking de los competidores permite a la empresa
            elevar sus estándares de competitividad y liderazgo en el mercado,
            detectando las falencias y debilidades ante los competidores
            directos o indirectos. Además, fomenta la creación de nuevas ideas y
            estrategias que impulsan el crecimiento de una marca o empresa.
          </p>
        </v-card-text>
        <v-card-text class="step_text">
          <p>
            Te recomendamos responder a las siguientes preguntas y analizar
            detalladamente los cuadros de MONITOR en cada Red Social (Con las
            respuestas podras alistar las observaciones que resalten en cada
            competidor):
          </p>
        </v-card-text>
        <span class="step_subtitle2 indent-1">
          a) Revisar su web o blog y responder preguntas como:
        </span>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>¿Cúales son sus contenidos más populares?</li>
            <li>¿Cómo muestran sus productos y servicios?</li>
            <li>¿Cuál es su mensaje de marca?</li>
            <li>¿Qué tácticas de persuasión utilizan?</li>
          </ul>
        </v-card-text>
        <span class="step_subtitle2 indent-1">
          b) Revisar sus redes sociales y responder preguntas como:
        </span>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>¿Cómo interactúan con su comunidad?</li>
            <li>¿Cuántos seguidores tienen?</li>
            <li>¿Qué tipo de contenido y formatos utilizan?</li>
            <li>¿Hay seguidores fidelizados con la marca?</li>
            <li>
              Tiempo de respuesta a comentarios, frecuencia de publicación,
              horarios.
            </li>
            <li>¿Cómo son sus post?</li>
            <li>¿Quiénes son los influencers?</li>
            <li>¿Cuáles son las keywords que usan?</li>
            <li>¿Cuántas interacciones registra?</li>
            <li>¿Cuánto Engagement tiene con su comunidad?</li>
            <li>¿Cómo reacciona la comunidad a las publicaciones?</li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Instagram:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_1.webp`"
            @click="openImgDialog(`${urlS3}/step2_1.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Twitter:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_2.webp`"
            @click="openImgDialog(`${urlS3}/step2_2.webp`)"
          />
        </div>
        <v-card-text class="step_text step_list indent-1">
          <ul>
            <li><b>Facebook:</b></li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step2_3.webp`"
            @click="openImgDialog(`${urlS3}/step2_3.webp`)"
          />
        </div>
        <v-card-text class="step_text">
          <p>
            Por otro lado, es esencial saber cual es la posición de nuestra
            empresa antes de realizar cualquier acción, para ello usamos el
            submódulo 'Overview' en MONITOR.
          </p>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/monitor_overview_accounts.webp`"
            @click="openImgDialog(`${urlS3}/monitor_overview_accounts.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/monitor_market_trends.webp`"
            @click="openImgDialog(`${urlS3}/monitor_market_trends.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 3 -->
      <v-card class="px-7 step_container" flat v-if="step == 3">
        <v-card-title class="step_subtitle1">
          3. Analizar los diagnósticos recogidos en LISTEN y MONITOR para
          convertirlos en Insights e ítems de acción
        </v-card-title>
        <v-card-text class="step_text">
          <p class="indent-0">
            Los insights surgen luego de una indagación a profundidad de los
            aspectos del consumidor que se ven reflejados en LISTEN y MONITOR.
            ¡El insight quiere ser revelado! <br />
            Existen:
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              Insights que alimentan nuevos conceptos de producto basados en
              necesidades y demandas insatistechas, ocultas, reveladoras.
            </li>
            <li>
              Insights que alimentan estrategias de posicionamiento o
              reposicionamiento de los productos.
            </li>
            <li>
              Insights que orientan la comunicación publicitaria de forma tal de
              obtener una ruta efectiva para atraer, retener y/o fidelizar al
              consumidor.
            </li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text">
          <p class="indent-0">¡Y muchos más!</p>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step3_1.webp`"
            @click="openImgDialog(`${urlS3}/step3_1.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 4 -->
      <v-card class="px-7 step_container" flat v-if="step == 4">
        <v-card-title class="step_subtitle1">
          4. Plantear una estrategia de posicionamiento
        </v-card-title>
        <span class="step_subtitle2"
          >a) Reconocer y definir el diferencial de la marca o empresa:
        </span>
        <v-card-text class="step_text start"
          ><p>
            ¿Cuál es la fortaleza de tu empresa? ¿Cómo se diferencia de la
            competencia?
          </p></v-card-text
        >
        <span class="step_subtitle2"
          >b) Definir la estrategia de posicionamiento en base al diferencial:
        </span>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>Según estilo de vida.</li>
            <li>Basada en el consumidor.</li>
            <li>Basada en su uso (servicio o producto).</li>
            <li>Basada en la calidad o precio.</li>
            <li>En comparación con la competencia.</li>
            <li>Basada en los atributos.</li>
            <li>En función de sus características.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step4_1.webp`"
            @click="openImgDialog(`${urlS3}/step4_1.webp`)"
          />
        </div>
        <span class="step_subtitle2">c) Define las RRSS que utilizarás: </span>
        <v-card-text class="step_text step_list"
          ><ul class="indent-1">
            <li>
              Es necesario estar presentes en las RRSS donde se encuentra
              nuestro püblico objetivo. No es necesario tener presencia en
              todas, cada plataforma requiere de una estructura de contenidos
              acorde a sus características:
            </li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text step_list"
          ><ul>
            <li>
              <b>Facebook:</b> Red social con mayor cantidad de usuarios hasta
              el momento y con un rango de edad de consumo bastante amplio. Los
              usuarios se conectan a cualquier hora del dia desde cualquier
              dispositivo, demanda contenidos de alta interacción y con sentido
              de generación de comunidad.
            </li>
            <li>
              <b>Twitter:</b> En esta red todo pasa muy rápido, un tweet tiene
              duración corta. Se utiliza mayormente desde el teléfono móvil.
              Demanda contenidos de alto impacto y atomizados para generar
              interacciones con la comunidad.
            </li>
            <li>
              <b>Instagram:</b> Red muy visual, fácil de consumir y popular. El
              principal contenido son las imágenes, pero los videos cortos
              vienen tomando protagonismo. Priman las audiencias por debajo de
              los 30 años y se premian los contenidos auténticos exponiéndose al
              público potencial de manera orgánica para un mayor alcance.
            </li>
            <li>
              <b>Youtube:</b> Priman los videos como contenido principal para
              fidelizar seguidores y crear una comunidad, trabajando de la mano
              con la cantidad de likes, número de visitas y número de
              suscriptores.
            </li>
            <li>
              <b>Linkedin:</b> Es la Red profesional más grande del mundo,
              orientada a generar conexiones laborales y empresariales. Prima la
              búsqueda de empleo, contratar profesionales, potenciar
              emprendimientos y contactar clientes, además esta plataforma te
              permite estar informado de las últimas novedades de la industria o
              sector.
            </li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step4_2.webp`"
            @click="openImgDialog(`${urlS3}/step4_2.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 5 -->
      <v-card class="px-7 step_container" flat v-if="step == 5">
        <v-card-title class="step_subtitle1">
          5. Definir el contenido de las publicaciones para ejecutar la
          estrategia
        </v-card-title>
        <v-card-text class="step_text step_list"
          ><ul class="indent-1">
            <li>
              Usar conexiones directas (contactos, empleados, clientes y
              familiares) primero para viralizar el contenido orgánicamente.
              Recibir feedback y corregir para luego aplicar publicidad.
            </li>
            <li>
              La estrategia debe aterrizar en un Plan de Piezas de Marketing con
              descripciones detalladas con énfasis en los mensajes (visuales,
              escritos y descriptivos) que se desea enviar a la audiencia.
            </li>
            <li>
              Las publicaciones deben estar compuestas por 2 insumos
              indispensables: Los 'Temas', que se refieren a los asuntos que se
              desean comunicar y los 'Formatos' que se refieren a la forma en la
              que se comunicará la marca con su comunidad. A continuación, se
              muestran ejemplos:
            </li>
          </ul>
        </v-card-text>
        <v-card-text
          style="width: 100%"
          class="d-flex align-center justify-center"
        >
          <div class="d-flex justify-center align-center" style="width: 80%">
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="justify-center align-center"
            >
              <v-col cols="12" class="customCol"
                ><v-card
                  outlined
                  tile
                  class="colCard colHeader d-flex justify-center align-center"
                  ><b>Formatos</b></v-card
                >
              </v-col>
              <v-col class="customCol" order="1">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Videos</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_1.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="2">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Imágenes</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_2.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="3">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Infografías</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_3.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="4">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Historias</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 40px"
                        :src="`${urlS3}/step5_4.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
              <v-col class="customCol" order="5">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><b>Addons</b></v-col
                    ><v-col
                      cols="12"
                      class="d-flex justify-center align-center"
                      style="height: 70px"
                      ><img
                        style="width: 50px"
                        :src="`${urlS3}/step5_5.webp`"
                      /> </v-col
                  ></v-row>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-text
          style="width: 100%"
          class="d-flex align-center justify-center mt-10"
        >
          <div class="d-flex justify-center align-center" style="width: 70%">
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="justify-center align-center"
            >
              <v-col cols="12" class="customCol"
                ><v-card
                  outlined
                  tile
                  class="colCard colHeader d-flex justify-center align-center"
                  ><b>Temas</b></v-card
                >
              </v-col>
              <v-col class="customCol" order="1">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Cuidados del producto</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
              <v-col class="customCol" order="2">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Modos de uso</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
              <v-col class="customCol" order="3">
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text style="font-size: 16px; text-align: center"
                        ><b>Ejemplos de uso de un producto</b></v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <div class="step_img_card mt-10">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step5_6.webp`"
            @click="openImgDialog(`${urlS3}/step5_6.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 6 -->
      <v-card class="px-7 step_container" flat v-if="step == 6">
        <v-card-title class="step_subtitle1">
          6. Definir las piezas de Marketing y planificar su despliegue
        </v-card-title>
        <v-card-text class="step_text">
          <p class="indent-0">
            a) Semana de intriga.<br />
            b) Día de Lanzamiento.<br />
            c) Hora.<br />
            d) Calendarización.<br />
          </p>
        </v-card-text>
        <v-card-text>
          <v-container class="mx-3">
            <v-row no-gutters>
              <v-col style="width: 98%; max-width: 98%; flex-basis: 98%">
                <v-card
                  outlined
                  tile
                  class="table_step header d-flex align-center justify-center"
                  height="70px"
                >
                  <v-card-text class="d-flex align-center justify-center header"
                    ><b>Mayo</b></v-card-text
                  >
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                v-for="(header, index) in [
                  'Lunes',
                  'Martes',
                  'Miércoles',
                  'Jueves',
                  'Viernes',
                  'Sábado',
                  'Domingo',
                ]"
                :key="index"
                style="width: 14%; max-width: 14%; flex-basis: 14%"
              >
                <v-card
                  outlined
                  tile
                  class="table_step subheader d-flex align-center justify-center"
                  height="50px"
                >
                  <v-card-text class="d-flex align-center justify-center">{{
                    header
                  }}</v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 01 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b
                      ><span
                        class="mark_title"
                        style="background-color: #a7daf9"
                        >Inicio de<br /> </span
                      ><span
                        class="mark_title"
                        style="background-color: #a7daf9"
                        >publicaciones:</span
                      ></b
                    >
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Post en todas las RR.SS
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 02 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Historia:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 03 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Historia:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 04 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Video:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 05 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Historia:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 06 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Post en todas las RR.SS:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    No mencionar el producto
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 07 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Historia:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 08 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b
                      ><span
                        class="mark_title"
                        style="background-color: #ffbf00"
                        >Lanzamiento <br /></span
                      ><span
                        class="mark_title"
                        style="background-color: #ffbf00"
                        >18h:</span
                      ></b
                    >
                  </v-card-text>
                  <v-card-text
                    class="subCard subcard_subtitle"
                    style="padding-right: 0px"
                  >
                    Post + historias en todas las RR.SS (Mostrar producto)
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 09 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Post:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook (Producto + Imagen)
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 10 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Post:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook (Producto + Imagen)
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 11 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Post:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook (Producto + Imagen)
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 12 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Post:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook (Beneficios + Infografia)
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 13 </v-card-text>
                  <v-card-text class="subCard subcard_title">
                    <b><span>Video:</span></b>
                  </v-card-text>
                  <v-card-text class="subCard subcard_subtitle">
                    Instagram y Facebook (Consejos de decoración + video)
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col
                style="width: 14%; max-width: 14%; flex-basis: 14%"
                class="colSubCard"
              >
                <v-card
                  outlined
                  tile
                  class="table_step"
                  height="100%"
                  style="overflow-y: auto"
                >
                  <v-card-text class="subCard subcard_title"> 14 </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <!-- Step 7 -->
      <v-card class="px-7 step_container" flat v-if="step == 7">
        <v-card-title class="step_subtitle1">
          7. Establecer ofertas y/o promociones al canal de ventas tradicional
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            <b>Realizar promociones u ofertas</b> puede dinamizar las redes
            sociales de las marcas; Sin embargo, es importante que sean
            diferentes a las que existen en las
            <b>tiendas físicas o canales tradicionales</b> para lograr el
            objetivo.
          </p>
        </v-card-text>
        <v-card-text class="step_text">
          <p>
            <b>A continuación, se muestran ejemplos:</b>
          </p>
        </v-card-text>
        <v-card-text
          style="width: 100%"
          class="d-flex align-center justify-center"
        >
          <div
            class="d-flex justify-center align-center"
            style="width: 70%; font-size: 16px"
          >
            <v-row
              no-gutters
              align="center"
              justify="center"
              class="justify-center align-center"
            >
              <v-col
                v-for="(title, index) in tables.step_7.headers"
                :key="index"
                cols="4"
                class="customCol"
                ><v-card
                  outlined
                  tile
                  class="colCard colHeader d-flex justify-center align-center"
                  ><b>{{ title }}</b></v-card
                >
              </v-col>
              <v-col
                v-for="(body, index) in tables.step_7.bodies"
                :key="index"
                cols="4"
                class="customCol"
              >
                <v-card
                  outlined
                  tile
                  class="colCard colBody d-flex justify-center align-center"
                  style="height: 70px"
                >
                  <v-row
                    ><v-col cols="12" class="d-flex justify-center align-center"
                      ><v-card-text
                        style="font-size: 16px; text-align: center"
                        >{{ body }}</v-card-text
                      ></v-col
                    ></v-row
                  >
                </v-card>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
      <!-- Step 8 -->
      <v-card class="px-7 step_container" flat v-if="step == 8">
        <v-card-title class="step_subtitle1">
          8. Pautear Posts (publicidad)
        </v-card-title>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p class="indent-0">
            a) Segmentación. <br />
            b) Presupuesto.<br />
            c) Programación (fechas).<br />
            d) Meta.<br />
            e) Objetivo:<br />
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list" style="padding-top: 0px"
          ><ul class="">
            <li>Compromiso.</li>
            <li>Educar.</li>
            <li>Entrenar.</li>
            <li>Vender.</li>
            <li>Comunicar.</li>
            <li>Conectar.</li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text">
          <p class="indent-0">
            f) Contenido/ diseño.<br />
            g) Tono de comunicación:<br />
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list" style="padding-top: 0px"
          ><ul>
            <li>Corporativo.</li>
            <li>Informal.</li>
            <li>Cercano.</li>
            <li>Humorístico.</li>
            <li>Demostrativo.</li>
            <li>Testimonial.</li>
            <li>Informativo.</li>
          </ul>
        </v-card-text>
        <v-card-text class="step_text">
          <p class="indent-0">
            h) Mensajes de CM (Campaña de Marketing):<br />
          </p>
        </v-card-text>
        <v-card-text class="step_text step_list" style="padding-top: 0px"
          ><ul>
            <li>Mensaje de saludo.</li>
            <li>Mensaje de presentación de productos.</li>
            <li>Mensaje de cobro.</li>
            <li>Mensaje de recolección de datos.</li>
            <li>Mensaje de despido.</li>
            <li>Mensaje de seguimiento.</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step8_1.webp`"
            @click="openImgDialog(`${urlS3}/step8_1.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 9 -->
      <v-card class="px-7 step_container" flat v-if="step == 9">
        <v-card-title class="step_subtitle1">
          9. Escuchar las Redes Sociales a través de LISTEN y MONITOR para
          generar retroalimentación
        </v-card-title>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p>
            Es recomendable revisar constantemente los indicadores de las
            campañas en curso, de esta manera se puede evaluar si se están
            cumpliendo los objetivos planteados o realizar modificaciones para
            lograr mejores resultados. Además, es esencial conocer las novedades
            de la industria o sector para aprovechar nuevas oportunidades.
          </p>
        </v-card-text>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p>
            Te recomendamos evaluar constantemente la industria y a tus
            competidores para mejorar tus posteos y retroalimentar tus campañas.
            Puedes realizar estas acciones desde los submódulos de
            <b>LISTEN</b>, donde podrás escuchar todos los Drivers previamente
            definidos o cambiarlos según tu conveniencia, y en <b>MONITOR</b>,
            donde podrás mapear constantemente a tus competidores y estar al
            tanto de las novedades en el mercado.
          </p>
        </v-card-text>
        <div class="step_img_card mt-8">
          <img
            class="step_img mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step9_1.webp`"
            @click="openImgDialog(`${urlS3}/step9_1.webp`)"
          />
        </div>
        <div class="step_img_card">
          <img
            class="step_img_small mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step9_2.webp`"
            @click="openImgDialog(`${urlS3}/step9_2.webp`)"
          />
        </div>
      </v-card>
      <!-- Step 10 -->
      <v-card class="px-7 step_container" flat v-if="step == 10">
        <v-card-title class="step_subtitle1">
          10. ¡Ejecutar, monitorear y escuchar!
        </v-card-title>
        <v-card-text class="step_text">
          <p>
            ¡El ciclo se vuelve a repetir! Para lograr un verdadero éxito en las
            redes sociales la clave es <b>ser constante.</b>
            Ejecuta evaluaciones periódicas a las campañas que realices para
            mejorar continuamente.
          </p>
        </v-card-text>
        <v-card-text class="step_text" style="padding-bottom: 0px">
          <p>Se pueden responder las siguientes preguntas:</p>
        </v-card-text>
        <v-card-text class="step_text step_list" style="padding-top: 0px"
          ><ul>
            <li>¿Cuál ha sido la mejor campaña?</li>
            <li>
              ¿Cuánto tráfico se ha convertido en suscriptores? (Mejor si es en
              porcentaje)
            </li>
            <li>
              ¿Cuántos leads ha generado? De estos leads: ¿Cuántos se han
              convertido en clientes?
            </li>
            <li>¿Qué contenido ha incidido más en la conversión?</li>
            <li>
              ¿Cuál es el contenido más popular y cómo puede ayudarme a mejorar
              o generar más ingresos y conversiones?
            </li>
            <li>
              ¿De qué canales viene el tráfico que más convierte? ¿Google, Redes
              Sociales, colaboraciones?
            </li>
            <li>
              ¿Cuáles son los Llamados a la Acción (CTA: Call to Action) u otras
              acciones más efectivas? ¿Cuáles son las menores y porqué?
            </li>
            <li>
              ¿Cuáles fueron los sentimientos que tuvieron durante la campaña?
            </li>
            <li>¿Qué tanto aportaron los influencers a la marca?</li>
            <li>¿Cuáles fueron los testimonios?</li>
          </ul>
        </v-card-text>
        <div class="step_img_card">
          <img
            class="step_img_tiny mx-auto d-flex justify-center align-center"
            :src="`${urlS3}/step10_1.webp`"
            @click="openImgDialog(`${urlS3}/step10_1.webp`)"
          />
        </div>
      </v-card>
    </div>
    <v-dialog v-model="imgDialog" width="1200">
      <v-card class="justify-end modal-card">
        <v-row no-gutters>
          <v-col
            cols="12"
            style="padding: 2px !important"
            class="d-flex justify-end align-end"
          >
            <svg
              class="d-flex justify-center"
              style="
                width: 3rem;
                height: 3rem;
                cursor: pointer;
                padding: 5px;
                margin: 3px;
              "
              viewBox="0 0 24 24"
              @click="imgDialog = false"
            >
              <path
                fill="currentColor"
                d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
              />
            </svg>
          </v-col>
          <v-col cols="12" style="padding: 0px !important">
            <v-img contain class="mb-12 ml-12 mr-12" :src="imgSelected">
            </v-img>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "LanzamientoPerfilSocial",
  props: ["step", "tab_title"],
  data() {
    return {
      urlS3: "https://image-store.isocialcube.com/advise",
      urlS3_2: "https://isc-store.s3.amazonaws.com/advise/Roadmap+Information",
      imgDialog: false,
      imgSelected: null,
      tables: {
        step_0: {
          headers: [
            "Competidores",
            "Web",
            "Facebook",
            "Instagram",
            "Twitter",
            "LinkedIn",
            "Otro",
          ],
          bodies: [
            [
              "Competidor 1",
              "Sí - Link",
              "No",
              "Sí - Link",
              "Sí - Link",
              "Sí - Link",
              "No",
            ],
            [
              "Competidor 2",
              "Sí - Link",
              "Sí - Link",
              "No",
              "Sí - Link",
              "Sí - Link",
              "Sí - Link",
            ],
            [
              "Competidor 3",
              "Sí - Link",
              "Sí - Link",
              "Sí - Link",
              "No",
              "Sí - Link",
              "No",
            ],
            [
              "Competidor 4",
              "No",
              "Sí - Link",
              "Sí - Link",
              "Sí - Link",
              "No",
              "Sí - Link",
            ],
          ],
        },
        step_7: {
          headers: [
            "¿Qué hace la marca?",
            "¿Qué hace el cliente?",
            "¿Qué gana la marca?",
          ],
          bodies: [
            "Sorteos",
            "Sigue los pasos del sorteo",
            "Aumenta la cantidad de seguidores",
            "Contenido exclusivo",
            "Ingresa a la plataforma e interactúa con el contenido exclusivo",
            "Mayor posicionamiento en el medio",
            "Descuento de lanzamiento",
            "Compra los productos en descuento",
            "Clientes",
            "Colaboraciones con otras marcas",
            "Compra los productos exclusivos",
            "Clientes",
          ],
        },
      },
    };
  },
  methods: {
    openImgDialog(img) {
      if (this.imgDialog == false) {
        this.imgDialog = true;
        this.imgSelected = img;
      }
    },
    closeImgDialog() {
      if (this.imgDialog == true) {
        this.imgDialog = false;
        this.imgSelected = null;
      }
    },
  },
};
</script>

<style src="../goalsDefinition.css" scoped />
